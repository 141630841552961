import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { emptyPaginatedData } from 'technical/api/constants';
import { selectRecords } from 'technical/selects/selectRecords';
import { useSearchParams } from 'technical/hooks/useSearchParams';
import { Pagination, usePagination } from 'technical/hooks/usePagination';
import { parseDateOrFail } from 'technical/utils/parseDate';
import { benchKeys } from 'business/bench/hooks/queries';
import sortBy from 'lodash.sortby';
import {
  Filters,
  FILTERS,
  FilterDefinitions,
  OrderBy,
} from '../components/search/searchFilter/types';
import {
  getEvaluationsRequests,
  fetchEvaluationsRequestById,
  fetchEvaluationRequestPositions,
  insertEvaluationRequestPositions,
} from '../api';
import { CreatePositionsArgs, PaginatedEvaluationRequests } from '../types';
import { augmentUser } from '../../user/services/queries';

export const evaluationRequestKeys = {
  all: [{ scope: 'evaluationRequest' }] as const,
  lists: () => [{ ...evaluationRequestKeys.all[0], entity: 'list' }] as const,
  list: (search: Partial<FilterDefinitions>, page: Pagination) =>
    [{ ...evaluationRequestKeys.lists()[0], search, page }] as const,
  details: () =>
    [{ ...evaluationRequestKeys.all[0], entity: 'detail' }] as const,
  detail: (id?: number | string) =>
    [{ ...evaluationRequestKeys.details()[0], id: id ? +id : id }] as const,
};

export const useFetchEvaluationRequest = (id?: number | string) => {
  return useQuery(
    evaluationRequestKeys.detail(id),
    () => fetchEvaluationsRequestById({ id }),
    {
      select: (evalRequest) => ({
        ...evalRequest,
        requestDate: evalRequest.requestDate
          ? parseDateOrFail(evalRequest.requestDate)
          : null,
        startDate: evalRequest.startDate
          ? parseDateOrFail(evalRequest.startDate)
          : null,
        maxStartDate: evalRequest.maxStartDate
          ? parseDateOrFail(evalRequest.maxStartDate)
          : null,
        endDate: evalRequest.endDate
          ? parseDateOrFail(evalRequest.endDate)
          : null,
        estimatedEndDate: evalRequest.estimatedEndDate
          ? parseDateOrFail(evalRequest.estimatedEndDate)
          : null,
        intermediateExitDate: evalRequest.intermediateExitDate
          ? parseDateOrFail(evalRequest.intermediateExitDate)
          : null,
        applicant: evalRequest.applicant
          ? augmentUser(evalRequest.applicant)
          : undefined,
        series: sortBy(evalRequest.series, ['serieNumber']) || [],
        steps: sortBy(evalRequest.steps, ['order']) || [],
      }),
      enabled: !!id,
    },
  );
};

export function useEvaluationRequests(
  search: Partial<FilterDefinitions & { order: OrderBy }> = {},
  paginationConfig = { pageSize: 10, current: 1 },
  usePaginationHook = usePagination,
) {
  const [page, { onPageChange }] = usePaginationHook(paginationConfig);

  const selector = useCallback(
    (data: PaginatedEvaluationRequests) => ({
      evaluationRequests: data.records,
      total: data.count,
      ...page,
    }),
    [page],
  );

  const query = useQuery(
    evaluationRequestKeys.list(search, page),
    () => getEvaluationsRequests(search, page),
    {
      select: selector,
      keepPreviousData: true,
    },
  );

  return useMemo(
    () => [query, { onPageChange, page }] as const,
    [query, onPageChange, page],
  );
}

interface EvaluationRequestsSearch {
  q?: Partial<FilterDefinitions>;
  [k: string]: unknown;
}

function guard(
  params: Record<any, unknown>,
): params is EvaluationRequestsSearch {
  if ('q' in params) {
    const query = (params as EvaluationRequestsSearch).q!;
    if (Object.keys(query).some((key) => !FILTERS.includes(key as Filters))) {
      return false;
    }
  }
  return true;
}
export function useEvaluationRequestsGlobalSearch() {
  const [params, setParams] = useSearchParams<EvaluationRequestsSearch>(guard);

  const handlers = useMemo(() => {
    return {
      onSearchChange(query: Partial<FilterDefinitions>) {
        setParams((old) => {
          if (
            !old ||
            Object.keys(query).length !== Object.keys(old.q ?? {}).length ||
            Object.entries(query).some(([key, list]) => {
              const a = new Set(
                old?.q?.[key as Filters]?.map((v) => JSON.stringify(v)) ?? [],
              );
              const b = new Set(list.map((v) => JSON.stringify(v)));
              if (a.size !== b.size) {
                return true;
              }
              return Array.from(a).some((element) => !b.has(element));
            })
          ) {
            return {
              ...old,
              q: query,
              p: undefined,
            };
          }
          return old;
        });
      },
    };
  }, [setParams]);

  return [params.q ?? {}, handlers] as const;
}

export const evaluationRequestPositionsKeys = {
  all: [{ scope: 'evaluation-request-positions' }] as const,
  lists: () =>
    [{ ...evaluationRequestPositionsKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: number) =>
    [{ ...evaluationRequestPositionsKeys.lists()[0], search, page }] as const,
  details: () =>
    [{ ...evaluationRequestPositionsKeys.all[0], entity: 'detail' }] as const,
  detail: (evaluationRequestId: number, stepId: number, version?: number) =>
    [
      {
        ...evaluationRequestKeys.details()[0],
        evaluationRequestId,
        stepId,
        version,
      },
    ] as const,
};

export const useEvaluationRequestPositions = (
  evaluationRequestId: number,
  stepId: number,
  version?: number,
) => {
  const query = useQuery(
    evaluationRequestPositionsKeys.detail(evaluationRequestId, stepId, version),
    () =>
      fetchEvaluationRequestPositions({ evaluationRequestId, stepId, version }),
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
    },
  );

  return query;
};

export const usePostEvaluationRequestPositions = (
  evaluationRequestId: number,
  stepId: number,
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    (values: CreatePositionsArgs) =>
      insertEvaluationRequestPositions({
        evaluationRequestId,
        stepId,
        values,
      }),
    {
      onSuccess() {
        notification.success({ message: t('toast.creation.success') });
        queryClient.invalidateQueries(benchKeys.usedLanes());
      },
      onError() {
        notification.error({ message: t('toast.creation.error') });
      },
    },
  );
};
