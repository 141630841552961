import { Perms } from 'business/admin/types';
import { useManagerContext } from '../dataManager/manager-context';

interface ManagerContextReadOnlyProps<Entity> {
  children: (isReadOnly: boolean) => React.ReactNode;
  permissions: Partial<Perms<Entity>>;
  entity: Entity;
}

const ManagerContextReadOnly = <Entity,>({
  children,
  permissions,
  entity,
}: ManagerContextReadOnlyProps<Entity>) => {
  const context = useManagerContext();
  const isReadOnly =
    context === 'update' ? !permissions.update?.(entity) : false;

  return <>{children(isReadOnly)}</>;
};

export default ManagerContextReadOnly;
