import BenchProgramsList from 'business/benchProgram/components/benchProgramsList';
import AuthenticatedHeader from 'business/user/components/authenticatedHeader';
import {
  AuthorizedAccessOnly,
  technicianOnly,
} from 'business/user/services/policy';
import React from 'react';
import NotAuthorized from 'ui/notAuthrorized';
import PageLayout from 'ui/PageLayout';

const BenchProgramsListPage = () => {
  return (
    <PageLayout header={<AuthenticatedHeader />}>
      <AuthorizedAccessOnly
        policy={technicianOnly}
        fallback={<NotAuthorized />}
      >
        <BenchProgramsList />
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default BenchProgramsListPage;
