import React from 'react';
import { ConfigProvider, Empty, Table as AntTable, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

function Table<Type extends object = any>({
  dataSource,
  columns,
  ...otherProps
}: TableProps<Type>) {
  const { t } = useTranslation();

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description={t('common.noData')} />}
    >
      <AntTable columns={columns} dataSource={dataSource} {...otherProps} />
    </ConfigProvider>
  );
}

export default Table;
