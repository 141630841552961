import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Routes from './routes';

function FourOhFour() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={t('errors.notAuthorized')}
        extra={
          <Button
            type="primary"
            onClick={() => navigate(Routes.EvaluationRequests)}
          >
            {t('common.backToHomepage')}
          </Button>
        }
      />
    </div>
  );
}

export default FourOhFour;
