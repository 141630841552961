import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Table,
  Typography,
  notification,
} from 'antd';
import { Formik, FormikValues, useFormikContext } from 'formik';
import Flex from 'ui/flex';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'technical/hooks/useSearchParams';
import { DataManagementConfig, SelectedData } from '../../types';
import styles from './index.module.scss';
import { ManagerContextProvider } from './manager-context';
import { UseMutateFunction, UseQueryResult } from 'react-query';
import ActionColumns from '../actionColumns';

const defaultPermission = () => true;

function useColumns<Entity, CreateParams>(
  config: DataManagementConfig<Entity, CreateParams>,
) {
  const { t } = useTranslation();
  return useMemo(() => {
    const permissions = {
      add: config.permissions.add ?? defaultPermission,
      update: config.permissions.update ?? defaultPermission,
      delete: config.permissions.delete ?? defaultPermission,
      disable: config.permissions.disable ?? (() => false),
    };

    const columns = config.columns(permissions, t).map((column) => ({
      ...column,
      title: t(
        `pages.dataManagement.entity.${config.entity}.columns.${column.key}`,
      ),
    }));

    return {
      permissions,
      columns,
    };
  }, [config]);
}

export function selector<Entity extends { id: number }>(data: {
  pageSize: number;
  current: number;
  entities: Entity[];
  total: number;
}) {
  const source = data.entities.map((entity) => ({ ...entity, key: entity.id }));
  return {
    entities: Object.fromEntries(source.map((entity) => [entity.id, entity])),
    source,
    pagination: {
      current: data.current,
      pageSize: data.pageSize,
      total: data.total,
    },
  };
}

const FormRow =
  <Entity extends FormikValues>(
    query: UseQueryResult<SelectedData<Entity>, unknown>,
    config: any,
    updateEntity: UseMutateFunction<void, unknown, Entity, unknown>,
  ) =>
  (props: any) => {
    const id = props['data-row-key'];
    const entity = query.data?.entities[id];

    if (!entity) {
      return null;
    }
    return (
      <ManagerContextProvider context="update">
        <Formik<Entity>
          initialValues={entity}
          validationSchema={config.schema}
          onSubmit={(val) => {
            updateEntity(val);
          }}
          enableReinitialize
        >
          <tr {...props} />
        </Formik>
      </ManagerContextProvider>
    );
  };

interface CreateFormRowProps<CreateParams extends FormikValues> {
  setCreation: (val: CreateParams | null) => void;
  isCreationLoading: boolean;
}

const CreateFormRow = <
  Entity extends { id: number },
  CreateParams extends FormikValues,
>({
  setCreation,
  isCreationLoading,
}: CreateFormRowProps<CreateParams>) => {
  const { t } = useTranslation();
  const { isValid, submitForm } = useFormikContext<Entity>();

  return (
    <Flex>
      <Button type="default" size="middle" onClick={() => setCreation(null)}>
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        size="middle"
        loading={isCreationLoading}
        disabled={!isValid}
        htmlType="submit"
        onClick={() => {
          submitForm();
        }}
      >
        {t('common.add')}
      </Button>
    </Flex>
  );
};

function DataManager<
  Entity extends { id: number },
  CreateParams extends FormikValues,
  Search extends Record<any, unknown> = any,
>({ config }: { config: DataManagementConfig<Entity, CreateParams, Search> }) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams<Search>(
    config.search
      ? config.search.guard
      : (_p: Record<any, unknown>): _p is any => true,
  );
  const [searchValue, setSearchValue] = useState(searchParams);

  const [query, { onPageChange }] = config.hooks.useData(
    selector,
    searchParams,
  );
  const [creation, setCreation] = useState<CreateParams | null>(null);

  const { permissions, columns } = useColumns(config);

  const { mutate: createEntity, isLoading: isCreationLoading } =
    config.hooks.useCreation({
      onSuccess() {
        setCreation(null);
      },
      onError(err) {
        // If no corresponding context is found, the translation falls back
        // to the default one for the provided key
        notification.error({
          message: t('toast.creation.error', {
            context: `${config.entity}_${err.response?.data.error.type}`,
          }),
        });
      },
    });

  const { mutate: updateEntity, isLoading: isUpdateLoading } =
    config.hooks.useUpdate({
      onSuccess() {
        setCreation(null);
      },
    });

  // here we need the useMemo to prevent re-creation of the FormRow component
  // that triggers a unmount/remount for all the table rows
  const components = useMemo(
    () => ({
      body: {
        row: FormRow(query, config, updateEntity),
      },
    }),
    [query, updateEntity],
  );

  const entitiesListColumns = useMemo(
    () => [
      ...columns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 240,
        render: (_value: any, entity: Entity) => (
          <ActionColumns
            config={config}
            entity={entity}
            setCreation={setCreation}
            isUpdateLoading={isUpdateLoading}
            updateEntity={updateEntity}
          />
        ),
      },
    ],
    [columns, setCreation],
  );

  return (
    <div className={styles.dataManager}>
      <Typography.Title level={4}>
        {t(`pages.dataManagement.tab-title.${config.entity}`)}
      </Typography.Title>

      {!!config.search && (
        <div className={styles.searchForm}>
          <h3>{t('admin.search.header')}</h3>
          <Form layout="vertical" onFinish={() => setSearchParams(searchValue)}>
            <Row gutter={32}>
              {config.search.fields.map((field) => (
                <Col span={6} key={field}>
                  <Form.Item
                    label={t(
                      `pages.dataManagement.entity.${config.entity}.columns.${field}`,
                    )}
                  >
                    <Input
                      placeholder={t(
                        `pages.dataManagement.entity.${config.entity}.searchPlaceholder.${field}`,
                      )}
                      value={searchValue[field] as string}
                      onChange={(event) =>
                        setSearchValue((prev) => ({
                          ...prev,
                          [field]: event.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="middle"
                htmlType="submit"
              >
                {t('admin.search.submit')}
              </Button>
            </Row>
          </Form>
        </div>
      )}

      {/* Add a new entity */}
      {permissions.add() ? (
        <div className={styles.addNewEntity}>
          {creation ? (
            <Table<Entity>
              pagination={false}
              tableLayout="fixed"
              columns={[
                ...columns,
                {
                  dataIndex: 'actions',
                  key: 'actions',
                  width: 240,
                  render: () => (
                    <CreateFormRow
                      isCreationLoading={isCreationLoading}
                      setCreation={setCreation}
                    />
                  ),
                },
              ]}
              dataSource={[{ key: '-1' } as any]}
              components={{
                body: {
                  row(props: any) {
                    return (
                      <ManagerContextProvider context="creation">
                        <Formik<CreateParams>
                          initialValues={creation}
                          validationSchema={config.schema}
                          validateOnMount
                          onSubmit={(vals) => {
                            createEntity(vals);
                          }}
                        >
                          <tr {...props} />
                        </Formik>
                      </ManagerContextProvider>
                    );
                  },
                },
              }}
            />
          ) : (
            <Button block onClick={() => setCreation(config.defaultValues)}>
              {t(`pages.dataManagement.add-button.${config.entity}`)}
            </Button>
          )}
        </div>
      ) : null}

      {/* Entities list */}
      <div className={styles.table}>
        <Table<Entity>
          tableLayout="fixed"
          columns={entitiesListColumns}
          dataSource={query.data?.source}
          pagination={query.data?.pagination}
          onChange={onPageChange}
          loading={query.isLoading}
          components={components}
        />
      </div>
    </div>
  );
}

export default DataManager;
