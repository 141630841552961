import Routes from '../../router/routes';
import {
  adminOnly,
  allExemptAdmin,
  applicantOnly,
  managerOnly,
  technicianOnly,
} from '../../user/services/policy';

export const MenuKeyEnum = {
  evaluationRequests: 'evaluationRequests',
  searchEvaluationRequest: 'searchEvaluationRequest',
  dataManagement: 'dataManagement',
  toDoTechnician: 'to-do-technician',
  benchProgramsList: 'benchProgramsList',
  anomalyList: 'anomalyList',
  waitingValidation: 'waiting-validation',
  weeklyBurden: 'weekly-burden',
} as const;

export const authenticatedMenuItems = [
  {
    key: MenuKeyEnum.evaluationRequests,
    path: Routes.EvaluationRequests,
    policy: applicantOnly,
  },
  {
    key: MenuKeyEnum.waitingValidation,
    path: Routes.WaitingValidation,
    policy: managerOnly,
  },
  {
    key: MenuKeyEnum.weeklyBurden,
    path: Routes.WeeklyBurden,
    policy: managerOnly,
  },
  {
    key: MenuKeyEnum.toDoTechnician,
    path: Routes.ToDo,
    policy: technicianOnly,
  },
  {
    key: MenuKeyEnum.benchProgramsList,
    path: Routes.BenchPrograms,
    policy: technicianOnly,
  },
  {
    key: MenuKeyEnum.anomalyList,
    path: Routes.AnomalyList,
    policy: technicianOnly,
  },
  {
    key: MenuKeyEnum.searchEvaluationRequest,
    path: Routes.SearchEvaluationRequest,
    policy: allExemptAdmin,
  },
  {
    key: MenuKeyEnum.dataManagement,
    path: Routes.DataManagement,
    policy: adminOnly,
  },
];
