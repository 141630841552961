import { EvaluationRequest, FormValues } from '../types';

/**
 * Transform existing evaluation request to a form version of it
 * @param evaluationRequest
 */
export const evaluationRequestToFormValue = (
  evaluationRequest: EvaluationRequest,
): FormValues => {
  return {
    ...evaluationRequest,
    requestDate: new Date(),
    startDate: null,
    endDate: null,
    maxStartDate: null,
    estimatedEndDate: null,
    intermediateExitDate: null,
    series: evaluationRequest?.series ?? [],
    steps: evaluationRequest?.steps ?? [],
  };
};
