import Routes from 'business/router/routes';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import * as auth from '../api';
import { userKeys } from './queries';

export function useAuthentication() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const signIn = useMutation(auth.signIn, {
    onSuccess() {
      queryClient.resetQueries(userKeys.me());
    },
  });

  const signOut = useMutation(auth.signOut, {
    onSuccess() {
      navigate(Routes.SignIn);
      queryClient.resetQueries();
    },
  });

  return { signIn: signIn.mutate, signOut: signOut.mutate };
}
