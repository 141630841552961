import React, { useState, useEffect } from 'react';
import { PaginationProps } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import Flex from 'ui/flex';
import { generatePath, Link } from 'react-router-dom';
import Routes from 'business/router/routes';
import EvaluationStatusTag from 'business/evaluationRequest/components/evaluationRequestStatus';
import { augmentUser } from 'business/user/services/queries';
import { format } from 'date-fns';
import Table, { ColumnsType } from 'antd/lib/table';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import {
  allExemptAdmin,
  applicantOnly,
  AuthorizedAccessOnly,
} from 'business/user/services/policy';
import {
  isStatusDone,
  StatusEnum,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import { OrderBy } from 'business/evaluationRequest/components/search/searchFilter/types';
import EllipsisTooltip from 'ui/ellipsisTooltip';
import { distinct } from 'technical/utils/array';
import EvaluationRequestCount from 'business/evaluationRequest/components/evaluationRequestCount';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import PageLayout from '../../../../ui/PageLayout';
import { useEvaluationRequestsGlobalSearch } from '../../hooks/queries';
import SearchForm from '../../components/search/form';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'searchEvaluationRequest';
const pagesBreadcrumb = ['home', currentPage];

const actionColumn = (t: TFunction): ColumnsType<EvaluationRequest> => [
  {
    title: t('evaluationRequest.table.action'),
    key: 'action',
    render: (_value, evaluationRequest) => {
      return (
        <Flex alignItems="center" justify="space-around">
          <Link
            to={generatePath(Routes.EvaluationRequestDetail, {
              id: evaluationRequest.id.toString(),
            })}
          >
            {t('evaluation.request.go')}
          </Link>
          {process.env.REACT_APP_REPORT_ACTIVATED === 'true' &&
          isStatusDone(evaluationRequest.status.id) ? (
            <Link
              to={generatePath(Routes.EvaluationRequestReport, {
                id: evaluationRequest.id.toString(),
              })}
            >
              {t('evaluation.request.goToReport')}
            </Link>
          ) : null}
          <AuthorizedAccessOnly policy={applicantOnly}>
            <Link
              to={generatePath(Routes.CopyEvaluationRequest, {
                id: evaluationRequest.id.toString(),
              })}
            >
              {t('evaluation.request.copy')}
            </Link>
          </AuthorizedAccessOnly>
        </Flex>
      );
    },
  },
];

const EvaluationSearchPage: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);
  const [search] = useEvaluationRequestsGlobalSearch();
  const [totalCount, setTotalCount] = useState<number>();
  const [filterCount, setFilterCount] = useState<number>();
  const [order, setOrder] = useState<OrderBy>({
    requestDate: 'DESC',
  });
  const [query, { onPageChange }] = useEvaluationRequests({
    ...search,
    status: search.status ?? [
      StatusEnum.IN_PROGRESS,
      StatusEnum.PENDING,
      StatusEnum.WAITING_VALIDATION,
      StatusEnum.DONE,
    ],
    order,
  });

  const everyOneColumns: ColumnsType<EvaluationRequest> = [
    {
      title: t('evaluationRequest.table.reference'),
      dataIndex: 'reference',
      key: 'reference',
      sorter: true,
      render: (reference) => <>{reference}</>,
    },
    {
      title: t('evaluationRequest.table.applicant'),
      dataIndex: 'applicant',
      key: 'applicant',
      // if status entity does not exists, backend returns null, so we handle this case here
      render: (applicant) => (
        <>{applicant ? augmentUser(applicant).fullname : null}</>
      ),
    },
    {
      title: t('evaluationRequest.table.requestDate'),
      dataIndex: 'requestDate',
      key: 'requestDate',
      sorter: true,
      render: (date) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: t('evaluationRequest.table.estimatedEndDate'),
      dataIndex: 'estimatedEndDate',
      key: 'estimatedEndDate',
      sorter: true,
      render: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : ''),
    },
    {
      title: t('evaluationRequest.table.priority.shortHeader'),
      dataIndex: 'priority',
      key: 'priority',
      sorter: true,
      // if priority entity does not exists, backend returns null, so we handle this case here
      render: (priority) => <>{priority ? `${priority.name}` : null}</>,
    },
    {
      title: t('evaluationRequest.table.status'),

      dataIndex: 'status',
      key: 'status',
      // if status entity does not exists, backend returns null, so we handle this case here
      render: (status) =>
        status ? <EvaluationStatusTag status={status} /> : null,
    },
    {
      title: t('evaluationRequest.table.subject'),
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true,
      render: (subject) => (
        <EllipsisTooltip title={subject}>{subject}</EllipsisTooltip>
      ),
    },
    {
      title: t('evaluationRequest.table.productDesignations'),
      dataIndex: 'series',
      key: 'series',
      ellipsis: true,
      render: (_, { series }) => {
        const productNames = distinct(
          (series || []).map((serie) => serie.productDesignation?.name),
        );
        return (
          <EllipsisTooltip
            title={
              series
                ? productNames.map((product) => (
                    <div key={product}>{product}</div>
                  ))
                : ''
            }
          >
            {series ? productNames.map((product) => product).join(', ') : ''}
          </EllipsisTooltip>
        );
      },
    },
    {
      title: t('evaluationRequest.table.description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (description) => (
        <EllipsisTooltip title={description}>{description}</EllipsisTooltip>
      ),
    },
  ];

  const data = query.data ?? {
    total: 0,
    pageSize: 10,
    evaluationRequests: [],
    current: 1,
  };
  const pagination: PaginationProps = {
    current: data.current,
    pageSize: data.pageSize,
    total: data.total,
  };

  useEffect(() => {
    if (!totalCount || data.total > totalCount) {
      setTotalCount(data.total);
    }
    setFilterCount(data.total);
  }, [data.total]);

  return (
    <PageLayout
      header={
        <AuthenticatedHeader
          selectedKeys={[MenuKeyEnum.searchEvaluationRequest]}
        />
      }
      breadcrumbSections={breadcrumbSections}
    >
      <AuthorizedAccessOnly
        policy={allExemptAdmin}
        fallback={<NotAuthorized />}
      >
        <Spacer direction="vertical" size="small">
          <SearchForm />
          {totalCount ? (
            <EvaluationRequestCount
              totalCount={totalCount}
              filterCount={filterCount}
            />
          ) : null}
          <Table<EvaluationRequest>
            columns={[...everyOneColumns, ...actionColumn(t)]}
            dataSource={data.evaluationRequests}
            loading={query.isLoading}
            pagination={pagination}
            rowKey={(record) => record.id}
            onChange={(page, _filters, orderBy) => {
              onPageChange(page);
              if (!Array.isArray(orderBy)) {
                setOrder({
                  [orderBy.columnKey as any]:
                    orderBy.order === 'ascend' ? 'ASC' : 'DESC',
                });
              }
            }}
            size="middle"
          />
        </Spacer>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default EvaluationSearchPage;
