import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { emptyPaginatedData } from 'technical/api/constants';
import { selectRecords } from 'technical/selects/selectRecords';
import { Pagination } from 'technical/hooks/usePagination';
import { MeasurePostValue } from '../types';
import { fetchMeasures, insertMeasures } from '../api';

export const measureKeys = {
  all: [{ scope: 'measures' }] as const,
  lists: () => [{ ...measureKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination) => [{ ...measureKeys.lists()[0], page }] as const,
  details: () => [{ ...measureKeys.all[0], entity: 'detail' }] as const,
  detail: (
    stepId: number,
    version: number,
    cycle?: number,
    measureTypeId?: number,
  ) =>
    [
      { ...measureKeys.details()[0], stepId, cycle, version, measureTypeId },
    ] as const,
};

export const useFetchMeasures = (
  stepId: number,
  cycle: number | undefined,
  version: number,
  measureTypeId: number | undefined,
) => {
  const cycleAsNumber =
    cycle != null && !Number.isNaN(+cycle) ? +cycle : undefined;
  const measureTypeIdAsNumber =
    measureTypeId && !Number.isNaN(+measureTypeId) ? +measureTypeId : undefined;
  return useQuery(
    measureKeys.detail(stepId, version, cycleAsNumber, measureTypeIdAsNumber),
    () => {
      return fetchMeasures({
        stepId,
        cycle: cycleAsNumber,
        version,
        measureTypeId: measureTypeIdAsNumber,
      });
      //
    },
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
      enabled: measureTypeIdAsNumber != null && cycleAsNumber != null,
    },
  );
};

export const usePostMeasures = () => {
  const { t } = useTranslation();
  return useMutation(
    (values: MeasurePostValue[]) => insertMeasures({ values }),
    {
      onSuccess() {
        notification.success({ message: t('toast.creation.success') });
      },
      onError() {
        notification.error({ message: t('toast.creation.error') });
      },
    },
  );
};
