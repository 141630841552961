import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchProductDesignationList } from '../api/fetchProductDesignationList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';
import {
  PaginatedProductDesignations,
  ProductDesignation,
  SearchArgs,
} from '../types';

export const productDesignationKeys = {
  all: [{ scope: 'productDesignation' }] as const,
  lists: () => [{ ...productDesignationKeys.all[0], entity: 'list' }] as const,
  list: (page?: Pagination, search?: SearchArgs) =>
    [{ ...productDesignationKeys.lists()[0], search, page }] as const,
};

export function useProductDesignationsList<Selected = ProductDesignation[]>({
  select = (data) => data as any,
  page,
  search,
  skip = false,
}: {
  select?: (data: ProductDesignation[]) => Selected;
  page?: Pagination;
  search?: SearchArgs;
  skip?: boolean;
}) {
  const productDesignationsQuery = useQuery(
    productDesignationKeys.list(page, search),
    () => fetchProductDesignationList(page, search),
    {
      select: useCallback(
        (data: PaginatedProductDesignations) => {
          return select(selectRecords(data));
        },
        [select],
      ),
      placeholderData: emptyPaginatedData,
      keepPreviousData: true,
      enabled: !skip,
    },
  );

  return productDesignationsQuery;
}

function toArtsReferenceOptions(list: ProductDesignation[]) {
  return list.map(({ id, referenceArts }) => ({ id, name: referenceArts }));
}

function toProductDesignationOptions(list: ProductDesignation[]) {
  return list.map(({ id, name }) => ({ id, name }));
}

export function useArtsReferenceOptions(search?: SearchArgs) {
  return useProductDesignationsList({
    select: toArtsReferenceOptions,
    page: { current: 1, pageSize: 100 },
    search,
  });
}

export function useProductDesignationOptions(search?: SearchArgs) {
  return useProductDesignationsList({
    select: toProductDesignationOptions,
    page: { current: 1, pageSize: 100 },
    search,
  });
}
