import { notification } from 'antd';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import { useMemo } from 'react';
import {
  Pagination,
  usePaginationState,
} from '../../../technical/hooks/usePagination';
import { Report, reportSchema } from '../types';

export const reportKeys = {
  all: [{ scope: 'report' }] as const,
  lists: () => [{ ...reportKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...reportKeys.lists()[0], search, page }] as const,
};

// Get report

export const getEvaluationRequestsById = async (ids: number) => {
  return (
    await axios.get(`/v1/evaluation-request`, {
      params: {
        ids,
      },
    })
  ).data;
};

export const getReport = async () => {
  return reportSchema.validate((await axios.get(`/v1/report`)).data);
};

export const useGetReport = () => {
  const { t } = useTranslation();

  const { data, ...rest } = useQuery(reportKeys.all, getReport, {
    onError: () => {
      notification.error({ message: t('common.form.genericFormError') });
    },
  });

  // Get evaluation requests for corresponding ids
  const [query] = useEvaluationRequests(
    { id: data?.report?.evaluationIds ?? [] },
    { current: 1, pageSize: 1000 },
    usePaginationState,
  );

  return useMemo(
    () => ({
      data: data?.report,
      selectedEvals: data?.report?.evaluationIds?.length
        ? query.data?.evaluationRequests
        : undefined,
      ...rest,
      isLoading: query.isLoading || rest.isLoading,
    }),
    [data, rest, query],
  );
};

// Update report

export const updateReport = async (report: Report) => {
  return (
    await axios.post(`/v1/report`, {
      date: report.date,
      evaluationIds: report.evaluationIds,
    })
  ).data;
};

export const useUpdateReport = () => {
  const { t } = useTranslation();

  return useMutation(updateReport, {
    onSuccess: () => {
      notification.success({
        message: t('pages.dataManagement.entity.report.notifications.success'),
      });
    },
    onError: () => {
      notification.error({
        message: t('pages.dataManagement.entity.report.notifications.error'),
      });
    },
  });
};
