import React from 'react';
import { Modal, Typography } from 'antd';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MailForm, { MailFormValues } from '../../../mail/components/mailForm';
import { StatusEnum } from '../../utils/evaluationRequestStatus';
import { EvaluationRequestStatus } from '../../../evaluationRequestStatus/types';
import { Mail } from '../../../mail/types';
import styles from './index.module.scss';
import Button from '../../../../ui/button';
import Spacer from '../../../../ui/spacer';
import {
  useScheduleEvaluationRequest,
  useUpdateEvaluationRequestStatus,
} from '../../hooks/actions';
import { usePostMail } from '../../../mail/hooks/actions';

interface ValidationFormProps {
  status: EvaluationRequestStatus;
  id: number;
  applicantId: number;
  reference: string;
}

const ValidationSection: React.FC<ValidationFormProps> = ({
  id,
  status,
  reference,
  applicantId,
}) => {
  const { t } = useTranslation();
  const userFullName = 'todo name'; // todo use authenticated hook
  const updateEvaluationRequestStatusQuery = useUpdateEvaluationRequestStatus({
    id,
  });
  const scheduleEvaluationRequestQuery = useScheduleEvaluationRequest({
    id,
  });
  const postMailQuery = usePostMail();

  const handleScheduling = () => {
    scheduleEvaluationRequestQuery.mutate({
      evaluationRequestId: id,
    });
  };

  const handleStatusUpdate = (newStatusId: number) => {
    const params: {
      statusId?: number | undefined;
      technicianId?: number | null | undefined;
      evaluationRequestId: number;
    } = {
      evaluationRequestId: id,
      statusId: newStatusId,
    };
    if (
      newStatusId === StatusEnum.WAITING_VALIDATION ||
      newStatusId === StatusEnum.PENDING
    ) {
      params.technicianId = null;
    }

    updateEvaluationRequestStatusQuery.mutate(params);
  };

  const isWaitingValidation = status.id === StatusEnum.WAITING_VALIDATION;

  const isCanceled = status.id === StatusEnum.CANCELED;

  const handleSendMail = async (
    { textContent }: MailFormValues,
    { resetForm, setValues }: FormikHelpers<MailFormValues>,
  ) => {
    // create a mail using evaluation request subject
    const mail: Mail = {
      subject: t('sendComment.subject', {
        senderName: userFullName,
        reference,
      }),
      employeeId: applicantId,
      textContent,
      htmlContent: `<div>${textContent}</div>`,
    };
    const res = await postMailQuery.mutateAsync(mail);

    // re init form if post success
    if (res.status === 204) {
      resetForm({});
      setValues({ textContent: '' });
    }
  };

  return (
    <Spacer direction="vertical" className={styles.noPrint}>
      <Typography.Title level={4}>
        {isWaitingValidation
          ? t('evaluationRequest.review.title.CommentAndUpdateStatus')
          : t('evaluationRequest.review.title.UpdateStatus')}
      </Typography.Title>
      {isWaitingValidation ? (
        <MailForm
          isLoading={postMailQuery.isLoading}
          onSubmit={handleSendMail}
          className={styles.mailForm}
        />
      ) : null}

      <Spacer size="small" justify="end">
        {isWaitingValidation ? (
          <Button
            onClick={() => handleStatusUpdate(StatusEnum.PENDING)}
            type="primary"
          >
            {t('evaluationRequest.review.validate')}
          </Button>
        ) : null}
        {status.id > StatusEnum.WAITING_VALIDATION ? (
          <Button
            onClick={() => {
              Modal.confirm({
                title: t('evaluationRequest.review.reset.description', {
                  status: t('evaluationRequest.tabs.in-progress'),
                }),
                icon: <ExclamationCircleOutlined />,
                cancelText: t('evaluationRequest.review.back'),
                okText: t('evaluationRequest.review.reset.confirm'),
                onOk() {
                  handleStatusUpdate(StatusEnum.WAITING_VALIDATION);
                },
              });
            }}
            type="default"
          >
            {t('evaluationRequest.review.reset.waiting-validation')}
          </Button>
        ) : null}
        {status.id > StatusEnum.PENDING ? (
          <Button
            onClick={() => {
              Modal.confirm({
                title: t('evaluationRequest.review.reset.description', {
                  status: t('evaluationRequest.tabs.pending'),
                }),
                icon: <ExclamationCircleOutlined />,
                cancelText: t('evaluationRequest.review.back'),
                okText: t('evaluationRequest.review.reset.confirm'),
                onOk() {
                  handleStatusUpdate(StatusEnum.PENDING);
                },
              });
            }}
            type="default"
          >
            {t('evaluationRequest.review.reset.pending')}
          </Button>
        ) : null}
        {status.id > StatusEnum.IN_PROGRESS ? (
          <Button
            onClick={() => {
              Modal.confirm({
                title: t('evaluationRequest.review.reset.description', {
                  status: t('evaluationRequest.tabs.in-progress'),
                }),
                icon: <ExclamationCircleOutlined />,
                cancelText: t('evaluationRequest.review.back'),
                okText: t('evaluationRequest.review.reset.confirm'),
                onOk() {
                  handleScheduling();
                },
              });
            }}
            type="default"
          >
            {t('evaluationRequest.review.reset.in-progress')}
          </Button>
        ) : null}
        {!isCanceled ? (
          <Button
            onClick={() => {
              Modal.confirm({
                title: t('evaluationRequest.review.cancel.description'),
                icon: <ExclamationCircleOutlined />,
                cancelText: t('evaluationRequest.review.back'),
                okText: t('evaluationRequest.review.cancel.confirm'),
                okButtonProps: { danger: true },
                onOk() {
                  handleStatusUpdate(StatusEnum.CANCELED);
                },
              });
            }}
            type="primary"
            danger
          >
            {t('evaluationRequest.review.cancel.cta')}
          </Button>
        ) : null}
      </Spacer>
    </Spacer>
  );
};

export default ValidationSection;
