import React, { useMemo, useState } from 'react';
import { Button, Modal, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
  isStatusDone,
  isStatusDraft,
  isStatusInProgress,
  isStatusNotValidated,
  isStatusWaitingValidation,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import { useAuthenticatedUser } from 'business/user/services/queries';
import { managerOnly } from 'business/user/services/policy';
import sortBy from 'lodash.sortby';
import {
  advancedSchema,
  simpleSchema,
} from '../../schema/evaluationRequestSchema';
import {
  EvaluationRequest,
  FormValues,
  postValidationEditableFields,
  preValidationEditableFields,
} from '../../types';
import styles from '../CreationFormsTabs/index.module.scss';
import EvaluationStatusTag from '../evaluationRequestStatus';
import SimpleCreateEvaluationRequestForm from '../simpleCreationForm';
import AdvancedCreationForm from '../advancedCreationForm';
import { parseDateOrFail } from '../../../../technical/utils/parseDate';
import { advancedEvaluationRequestFromFrontToBack } from '../../utils/advancedEveluationRequestFromFrontToBack';
import UpdateFormActions from '../updateFormActions';
import {
  useUpdateEvaluationRequest,
  useRemoveEvaluationRequest,
} from '../../hooks/actions';

const { TabPane } = Tabs;

enum FormTabs {
  simple = 'simple-form',
  advanced = 'advanced-form',
}

const validationSchemas = {
  [FormTabs.simple]: simpleSchema,
  [FormTabs.advanced]: advancedSchema,
};

interface UpdateFormsProps {
  evaluationRequest: EvaluationRequest;
  isDisabled?: boolean;
}

const UpdateForms: React.FC<UpdateFormsProps> = ({ evaluationRequest }) => {
  const { t } = useTranslation();
  const user = useAuthenticatedUser();
  const currentTab = FormTabs[evaluationRequest.type];
  const userName = evaluationRequest?.applicant?.fullname || '';
  const [requestError, setRequestError] = useState<string>('');
  const { updateSimple, updateAdvanced } = useUpdateEvaluationRequest({
    setRequestError,
  });
  const { removeEvaluationRequest } = useRemoveEvaluationRequest();

  const simpleTabLabel = t('evaluationRequest.creation.simpleTab.title');
  const advancedTabLabel = t('evaluationRequest.creation.advancedTab.title');

  const isLoading = updateSimple.isLoading || updateAdvanced.isLoading;

  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const actions = (
    <UpdateFormActions
      isLoading={isLoading}
      onDelete={() => {
        setShowDeletionModal(true);
      }}
    />
  );

  // todo move to react-query fetch
  const initialValues = {
    ...evaluationRequest,
    series: evaluationRequest.series || [],
    steps: sortBy(evaluationRequest.steps, ['order']) || [],
    requestDate: parseDateOrFail(evaluationRequest.requestDate),
    estimatedEndDate: parseDateOrFail(evaluationRequest.estimatedEndDate),
    intermediateExitDate: parseDateOrFail(
      evaluationRequest.intermediateExitDate,
    ),
  };

  const editableFields = useMemo(() => {
    if (isStatusWaitingValidation(initialValues.statusId)) {
      return managerOnly(user) || initialValues.applicantId === user.id
        ? preValidationEditableFields
        : [];
    }
    if (isStatusNotValidated(initialValues.statusId)) {
      return initialValues.applicantId === user.id
        ? preValidationEditableFields
        : [];
    }
    if (isStatusInProgress(initialValues.statusId)) {
      return initialValues.technicianId === user.id
        ? postValidationEditableFields
        : [];
    }
    if (isStatusDone(initialValues.statusId)) {
      if (
        managerOnly(user) &&
        initialValues.technicianId === user.id &&
        initialValues.applicantId === user.id
      ) {
        return [
          'conclusionApplicant',
          'conclusionManager',
          'conclusionTechnician',
        ];
      }
      if (managerOnly(user) && initialValues.applicantId === user.id) {
        return ['conclusionApplicant', 'conclusionManager'];
      }
      if (managerOnly(user) && initialValues.technicianId === user.id) {
        return ['conclusionManager', 'conclusionTechnician'];
      }
      if (managerOnly(user)) {
        return ['conclusionManager'];
      }
      if (initialValues.technicianId === user.id) {
        return ['conclusionTechnician'];
      }
      if (initialValues.applicantId === user.id) {
        return ['conclusionApplicant'];
      }
      return [];
    }
    return [];
  }, [initialValues.statusId, initialValues.applicantId, user]);

  const tabs = [
    {
      key: FormTabs.simple,
      label: simpleTabLabel,
      component: (
        <SimpleCreateEvaluationRequestForm
          actions={actions}
          error={{ key: 'common.form.genericFormError', context: requestError }}
          userName={userName}
          editableFields={editableFields}
        />
      ),
    },
    {
      key: FormTabs.advanced,
      label: advancedTabLabel,
      component: (
        <AdvancedCreationForm
          actions={actions}
          error={{
            key: 'common.form.genericFormError',
            context: requestError,
          }}
          userName={userName}
          editableFields={editableFields}
        />
      ),
    },
  ];

  const onSubmit = (values: FormValues) => {
    if (currentTab === FormTabs.simple) {
      updateSimple.mutate(advancedEvaluationRequestFromFrontToBack(values));
    } else if (currentTab === FormTabs.advanced) {
      updateAdvanced.mutate(advancedEvaluationRequestFromFrontToBack(values));
    }
  };
  return (
    <>
      <Modal
        destroyOnClose
        title={t('evaluationRequest.deletion.title')}
        open={showDeletionModal}
        onCancel={() => {
          setShowDeletionModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowDeletionModal(false);
            }}
          >
            {t('common.cancel')}
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            htmlType="submit"
            onClick={() => {
              removeEvaluationRequest.mutate(evaluationRequest.id);
            }}
          >
            {t('common.delete')}
          </Button>,
        ]}
      >
        <p>{t('evaluationRequest.deletion.message')}</p>
      </Modal>

      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchemas[currentTab]}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <>
          <div className={styles.titleContainer}>
            <Typography.Title className={styles.title} level={4}>
              {t('evaluationRequest.creation.title', {
                context: isStatusDraft(initialValues.status.id)
                  ? 'draft'
                  : 'validated',
                reference: initialValues.reference,
              })}
            </Typography.Title>
            <EvaluationStatusTag status={evaluationRequest.status} />
          </div>

          <Tabs activeKey={currentTab} destroyInactiveTabPane>
            {tabs.map(({ key, label, component }) => (
              <TabPane disabled tab={label} key={key}>
                <fieldset>{component}</fieldset>
              </TabPane>
            ))}
          </Tabs>
        </>
      </Formik>
    </>
  );
};

export default UpdateForms;
