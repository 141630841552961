import * as yup from 'yup';
import { NA_REGEX } from '../utils/services';

export const measuresSchema = yup.object({
  cycle: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  measureTypeId: yup.number().required('common.form.validation.required'),
  measures: yup.array().of(
    yup.object({
      value: yup.lazy((value) => {
        const valueAsNumber = Number(value);
        if (!Number.isNaN(valueAsNumber)) {
          return yup.number().nullable();
        } else if (typeof value === 'string') {
          return yup.string().matches(NA_REGEX, {
            message: 'common.form.validation.shouldBeNumberOrNa',
            excludeEmptyString: true,
          });
        }
        return yup
          .number()
          .nullable()
          .typeError('common.form.validation.shouldBeNumber');
      }),
      id: yup.number().nullable(),
    }),
  ),
});
