export const getDuplicates = <T>(list: T[]) => {
  const seen = new Set<T>();
  return list.filter((element) => {
    if (seen.has(element)) {
      return true;
    }
    seen.add(element);
    return false;
  });
};
