import axios from 'axios';
import { Pagination } from 'technical/hooks/usePagination';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import { PaginatedClientSuppliers } from '../types';

export const fetchClientSuppliersList = async (
  page?: Pagination,
  search?: any,
): Promise<PaginatedClientSuppliers> => {
  return (
    await axios.get(`/v1/client-supplier`, {
      params: {
        ...(page ? getAPITablePosition(page) : {}),
        ...search,
      },
    })
  ).data;
};
