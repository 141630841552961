import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchTechnologiesList } from '../api/fetchTechnologiesList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';

export const technologyKeys = {
  all: [{ scope: 'technology' }] as const,
  lists: () => [{ ...technologyKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...technologyKeys.lists()[0], search, page }] as const,
};

export const useTechnologiesList = ({ skip = false }: { skip?: boolean }) => {
  const technologiesQuery = useQuery(
    technologyKeys.lists(),
    fetchTechnologiesList,
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
      enabled: !skip,
    },
  );

  return technologiesQuery;
};
