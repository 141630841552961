import { useSearchParams } from '../../../../technical/hooks/useSearchParams';

function guard(params: Record<any, unknown>): params is { token: string } {
  if ('token' in params) {
    return typeof params.token === 'string';
  }
  return false;
}
export function useResetPasswordToken() {
  const [search] = useSearchParams<{ token?: string }>(guard);
  return search?.token;
}
