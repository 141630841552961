import React, { useState } from 'react';
import { Button, Spin, TablePaginationConfig, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { FetchedAnomaly } from 'business/anomaly/types';
import { Pagination } from 'technical/hooks/usePagination';
import Spacer from 'ui/spacer';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { useFetchEvaluationRequest } from 'business/evaluationRequest/hooks/queries';
import CreateAnomaly from '../createAnomaly';
import QueryWrapper from 'business/admin/components/queryWrapper';

interface AnomalyListProps {
  anomalyList: FetchedAnomaly[];
  isLoading: boolean;
  pagination: Pagination & { total: number };
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

const AnomalyList: React.FC<AnomalyListProps> = ({
  anomalyList,
  isLoading,
  pagination,
  onPaginationChange,
}) => {
  const [visible, setVisible] = useState<number | undefined>();
  const { t } = useTranslation();

  const columns: ColumnsType<FetchedAnomaly> = [
    {
      title: t('pages.anomaly.creation.evaluationRequestNumber'),
      dataIndex: 'evaluationRequestId',
      key: 'evaluationRequestId',
      render(_value, anomaly) {
        return anomaly.evaluationRequest.reference;
      },
    },
    {
      title: t('pages.anomaly.creation.programNumber'),
      dataIndex: 'programNumber',
      key: 'programNumber',
      render(_value, anomaly) {
        return anomaly.benchProgram.program;
      },
    },
    {
      title: t('pages.anomaly.creation.step'),
      dataIndex: 'stepId',
      key: 'stepId',
      render(_value, anomaly) {
        return `Étape ${anomaly.step.order} (${anomaly.step.evaluation?.identifier} ${anomaly.step.evaluation?.name})`;
      },
    },
    {
      title: t('pages.anomaly.creation.cycle'),
      dataIndex: 'cycle',
      key: 'cycle',
    },
    {
      title: t('pages.anomaly.creation.date'),
      dataIndex: 'date',
      key: 'date',
      render(_value, anomaly) {
        return format(new Date(anomaly.date), 'dd/MM/yyyy HH:mm');
      },
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      key: 'id',
      render: (id: number, anomaly) => (
        <QueryWrapper
          hook={() => useFetchEvaluationRequest(anomaly.evaluationRequestId)}
        >
          {({ data: evaluationRequest }) => (
            <>
              <Button type="link" onClick={() => setVisible(id)}>
                {t('common.update')}
              </Button>
              {evaluationRequest ? (
                <CreateAnomaly
                  evaluationRequest={evaluationRequest}
                  anomaly={anomaly}
                  visible={visible === id}
                  cancel={() => setVisible(undefined)}
                />
              ) : null}
            </>
          )}
        </QueryWrapper>
      ),
    },
  ];

  const dataSource = anomalyList?.map((anomaly) => ({
    key: anomaly.id,
    ...anomaly,
  }));

  return (
    <Spacer direction="vertical">
      <Typography.Title level={4}>
        {t('pages.anomalyList.title')}
      </Typography.Title>
      {isLoading && <Spin />}
      {!isLoading && (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          onChange={onPaginationChange}
        />
      )}
    </Spacer>
  );
};

export default AnomalyList;
