import axios from 'axios';
import { Pagination } from 'technical/hooks/usePagination';
import {
  AnomalyFormValues,
  FetchedAnomaly,
  PaginatedFetchedAnomaly,
} from './types';

export const upsertAnomaly = async (
  anomaly: AnomalyFormValues,
  id?: number,
) => {
  if (id) {
    return (await axios.patch(`/v1/anomaly/${id}`, anomaly)).data;
  }
  return (await axios.post('/v1/anomaly', anomaly)).data;
};

export const fetchAnomaly = async (
  page: Pagination,
): Promise<PaginatedFetchedAnomaly> => {
  return (
    await axios.get(
      `/v1/anomaly?offset=${(page.current - 1) * page.pageSize}&limit=${
        page.pageSize
      }`,
    )
  ).data;
};

export const fetchOneAnomaly = async (id?: string): Promise<FetchedAnomaly> => {
  return (await axios.get(`/v1/anomaly/${id}`)).data;
};
