import { usePagination } from 'technical/hooks/usePagination';
import { Paginated } from 'technical/api/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { AdminHooks, CreateAdminHooksConfig } from './types';
import axios from 'axios';

const UNKNOWN_ERROR = 'unknown_error';

// eslint-disable-next-line import/prefer-default-export
export function createAdminHooks<Entity, CreateParams, Search>({
  keys,
  fetch,
  create,
  update,
  del,
}: CreateAdminHooksConfig<Entity, CreateParams, Search>): AdminHooks<
  Entity,
  CreateParams,
  Search
> {
  return {
    useData(selector, search) {
      const [page, { onPageChange }] = usePagination({
        pageSize: 10,
        current: 1,
      });

      const query = useQuery(
        keys.list(page, search),
        () => fetch(page, search),
        {
          select: useCallback(
            (data: Paginated<Entity>) =>
              selector({
                entities: data.records,
                total: data.count,
                ...page,
              }),
            [selector, page],
          ),
          keepPreviousData: true,
          onSuccess(data) {
            if (data.source.length === 0 && data.pagination.current > 1) {
              onPageChange({
                ...data.pagination,
                current: Math.ceil(
                  data.pagination.total / data.pagination.pageSize,
                ),
              });
            }
          },
        },
      );

      return [query, { onPageChange }] as const;
    },
    useCreation({ onSuccess, onError }) {
      const queryClient = useQueryClient();
      const { t } = useTranslation();

      return useMutation(create, {
        onSuccess() {
          queryClient.invalidateQueries(keys.all);
          onSuccess?.();
          notification.success({
            message: t('toast.creation.success'),
          });
        },
        onError(err?: unknown) {
          if (
            onError &&
            axios.isAxiosError(err) &&
            err.response?.data.error.type !== undefined &&
            err.response?.data.error.type !== UNKNOWN_ERROR
          ) {
            onError(err);
            return;
          }
          notification.error({
            message: t('toast.creation.error'),
          });
        },
      });
    },
    useUpdate({ onSuccess }) {
      const queryClient = useQueryClient();
      const { t } = useTranslation();

      return useMutation(update, {
        onSuccess() {
          queryClient.invalidateQueries(keys.all);
          onSuccess?.();
          notification.success({
            message: t('toast.update.success'),
          });
        },
        onError() {
          notification.error({
            message: t('toast.update.error'),
          });
        },
      });
    },
    useDeletion() {
      const queryClient = useQueryClient();
      const { t } = useTranslation();

      return useMutation(del, {
        onSuccess() {
          queryClient.invalidateQueries(keys.all);
          notification.success({
            message: t('toast.deletion.success'),
          });
        },
        onError() {
          notification.error({
            message: t('toast.deletion.error', {
              context: keys.all?.[0]?.scope,
            }),
          });
        },
      });
    },
  };
}
