import React, { Suspense } from 'react';
import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.min.css';
import './App.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import Flex from 'ui/flex';
import '../technical/api/axiosConfig';
import { AuthenticatedOnly } from './user/services/policy';
import { AuthenticatedApp, UnauthenticatedApp } from './router';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      retry: false,
      staleTime: 60 * 1000, // keep stuff in memory 1 min by default
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <ConfigProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Suspense
          fallback={
            <Flex
              alignItems="center"
              justify="center"
              style={{ flex: 1, height: '100vh' }}
            >
              <Spin size="large" />
            </Flex>
          }
        >
          <AuthenticatedOnly fallback={<UnauthenticatedApp />}>
            <AuthenticatedApp />
          </AuthenticatedOnly>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>
);

export default App;
