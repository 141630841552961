import React, { ReactElement, ReactNode } from 'react';
import { Layout, Spin } from 'antd';
import classnames from 'classnames';
import styles from './index.module.scss';
import Breadcrumb from '../breadcrumb';
import Flex from '../flex';

const { Content } = Layout;

interface PageLayoutProps {
  header: ReactElement;
  breadcrumbSections?: { key: string; label: string }[];
  breadcrumbLevelActions?: ReactNode;
  isLoading?: boolean;
  classNames?: string;
  children?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  breadcrumbSections = [],
  breadcrumbLevelActions,
  isLoading,
  header,
  classNames,
}) => {
  return (
    <Layout className={classnames(styles.layout, classNames)}>
      {header}

      <Content className={styles.content}>
        <div className={styles.breadcrumbContainer}>
          {breadcrumbSections.length > 0 && (
            <Breadcrumb breadCrumbSections={breadcrumbSections} />
          )}
          {!!breadcrumbLevelActions && <div>{breadcrumbLevelActions}</div>}
        </div>
        {isLoading ? (
          <Flex justify="center">
            <Spin />
          </Flex>
        ) : (
          children
        )}
      </Content>
    </Layout>
  );
};

export default PageLayout;
