import colors from 'ui/colors';
import React from 'react';

interface Props {
  message?: string;
}

const InputWarning = ({ message }: Props) => {
  if (!message) {
    return null;
  }

  return (
    <div data-test-id="warning-message" style={{ color: colors.warningColor }}>
      {message}
    </div>
  );
};

export default InputWarning;
