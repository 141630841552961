import React from 'react';
import axios from 'axios';
import * as yup from 'yup';

import { DataManagementConfig } from 'business/admin/types';
import { createAdminHooks } from 'business/admin/services';
import { Bench, BenchFormValues } from 'business/bench/types';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import InputField from 'technical/form/formik/InputField';
import {
  benchKeys,
  useBenchStatusList,
  useBenchTypeList,
} from 'business/bench/hooks/queries';
import SelectField from 'technical/form/formik/selectField';
import QueryWrapper from 'business/admin/components/queryWrapper';

const config: DataManagementConfig<Bench, Omit<Bench, 'id'>> = {
  entity: 'bench',
  hooks: createAdminHooks({
    keys: benchKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/bench', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post('/v1/bench', {
        name: entity.name,
        description: entity.description,
        statusId: entity.statusId,
        benchTypeId: entity.benchTypeId,
        numberOfLanes: entity.numberOfLanes,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/bench/${entity.id}`, {
        name: entity.name,
        description: entity.description,
        statusId: entity.statusId,
        benchTypeId: entity.benchTypeId,
        numberOfLanes: entity.numberOfLanes,
      });
    },
    async del(id) {
      return axios.delete(`/v1/bench/${id}`);
    },
  }),
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<BenchFormValues>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={20}
            />
          );
        },
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => {
          return (
            <InputField<BenchFormValues>
              valueKey="description"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={200}
            />
          );
        },
      },
      {
        dataIndex: 'statusId',
        key: 'statusId',
        render: (_value, entity) => {
          return (
            <QueryWrapper hook={useBenchStatusList}>
              {(query) => (
                <SelectField<BenchFormValues>
                  valueKey="statusId"
                  readOnly={!permissions.update(entity)}
                  loading={query.isLoading}
                  disabled={query.isLoading}
                  optionsList={query.data}
                />
              )}
            </QueryWrapper>
          );
        },
      },
      {
        dataIndex: 'benchTypeId',
        key: 'benchTypeId',
        render: (_value, entity) => {
          return (
            <QueryWrapper hook={useBenchTypeList}>
              {(query) => (
                <SelectField<BenchFormValues>
                  valueKey="benchTypeId"
                  readOnly={!permissions.update(entity)}
                  loading={query.isLoading}
                  disabled={query.isLoading}
                  optionsList={query.data}
                />
              )}
            </QueryWrapper>
          );
        },
      },
      {
        dataIndex: 'numberOfLanes',
        key: 'numberOfLanes',
        width: 120,
        render: (_value, entity) => {
          return (
            <InputField<BenchFormValues>
              valueKey="numberOfLanes"
              readOnly={!permissions.update(entity)}
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(20).required(),
    description: yup.string().trim().min(1).max(200).required(),
    statusId: yup.number().required(),
    benchTypeId: yup.number().required(),
    numberOfLanes: yup.number().required(),
  }),
  defaultValues: {
    name: '',
    description: '',
    statusId: undefined as any,
    benchTypeId: undefined as any,
    numberOfLanes: 1,
  },
  permissions: {},
};

export default config;
