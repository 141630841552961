import React from 'react';
import { useTranslation } from 'react-i18next';

interface EvaluationRequestCountProps {
  totalCount: number;
  filterCount?: number;
}

function EvaluationRequestCount({
  totalCount,
  filterCount,
}: EvaluationRequestCountProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t('evaluationRequest.counts.total', { value: totalCount })}</div>
      {filterCount ? (
        <div>
          {t('evaluationRequest.counts.current', { value: filterCount })}
        </div>
      ) : null}
    </div>
  );
}

export default EvaluationRequestCount;
