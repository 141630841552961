import React from 'react';
import BackGroundImage from '../../../../ui/backgroundImage';
import loginBG from '../../../../assets/loginBG.png';
import PageAuth from '../pageAuth';
import ResetPasswordCard from '../../components/resetPasswordCard';
import { useResetPasswordToken } from './useResetPasswordToken';

const ResetPasswordPage: React.FC = () => {
  const token = useResetPasswordToken();

  return (
    <PageAuth>
      <BackGroundImage image={loginBG} />
      <ResetPasswordCard token={`${token}`} />
    </PageAuth>
  );
};

export default ResetPasswordPage;
