import axios from 'axios';
import type { User } from './types';

// {{baseUrl}}/api/auth/v1/login
export const signIn = async (values: { email: string; password: string }) => {
  return axios.post(`/auth/v1/login`, values);
};

// {{baseUrl}}/api/auth/v1/logout
export const signOut = async () => {
  return axios.post(`/auth/v1/logout`);
};

// {{baseUrl}}/api/v1/user/me
export const getAuthenticatedUser = async (): Promise<User> => {
  return (await axios.get(`/v1/user/me`)).data;
};

export async function getUsers() {
  const response = await axios.get<{
    records: Array<User>;
    count: number;
  }>('/v1/user');
  return response.data;
}

// {{baseUrl}}/api/auth/v1/reset-password/request
export const requestPasswordReset = async (values: { email: string }) => {
  return axios.post(`/auth/v1/reset-password/request`, values);
};

// {{baseUrl}}/api/auth/v1/reset-password
export const resetPassword = async (values: {
  newPassword: string;
  confirmationPassword: string;
  token: string;
}) => {
  return axios.post(`/auth/v1/reset-password`, values);
};
