import React, { ReactNode, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Input, Form as AntForm, Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCategoriesList } from 'business/category/hooks/queries';
import ReadonlyField from 'technical/form/formik/ReadonlyField';
import { format } from 'date-fns';
import {
  SearchableSelectArtsReference,
  SearchableSelectProductionDesignation,
} from 'business/productDesignation/component/select';
import { SearchableSelectClientSupplier } from 'business/clientSupplier/component/select';
import { StatusEnum } from 'business/evaluationRequest/utils/evaluationRequestStatus';
import DatePickerField from 'technical/form/formik/datePickerField';
import classNames from 'classnames';
import InputError from '../../../../ui/form/inputError';
import { AdvancedFormValues } from '../../types';
import Spacer from '../../../../ui/spacer';
import styles from './index.module.scss';
import { usePrioritiesList } from '../../../priority/hooks/queries';
import { computeDateFromPriority } from '../../../priority/utils/computeDateFromPriority';
import InputField from '../../../../technical/form/formik/InputField';
import SelectField from '../../../../technical/form/formik/selectField';
import { useTechnologiesList } from '../../../technology/hooks/queries';
import { useProductsList } from '../../../product/hooks/queries';
import SeriesFieldArray from './seriesFieldArray';
import Flex from '../../../../ui/flex';
import EvaluationStepsFieldArray from '../evaluationStepsCreationForm/EvaluationStepsFieldArray';
import CommentsAndConclusion from '../commentsAndConclusion';

const { TextArea } = Input;

interface AdvancedCreationFormProps {
  error: { key: string; context: string };
  userName: string | null;
  actions: ReactNode;
  editableFields: string[];
}

export default function AdvancedCreationForm({
  actions,
  error,
  userName,
  editableFields,
}: AdvancedCreationFormProps) {
  const { t } = useTranslation();
  const {
    // values includes advanced and simple form values cf creationFormsTab
    values,
    setFieldValue,
  } = useFormikContext<AdvancedFormValues>(); // we use a partial type from the upper form
  const { data: priorities } = usePrioritiesList({});
  const { data: categories } = useCategoriesList({ formType: 'advanced' });
  const { data: technologies } = useTechnologiesList({});
  const { data: products } = useProductsList({});
  const selectedPriority = priorities?.find(
    (priority) => priority.id === values.priorityId,
  );

  // requestDate and maxStartDate are only used for display purposes, they are readonly and are not send to the back
  const requestDate = values.requestDate || new Date();

  const maxStartDate = selectedPriority
    ? computeDateFromPriority(selectedPriority, requestDate)
    : null;

  // After switching between simple and advanced creation form
  // we must check if the selected category is still allowed
  // if not, we remove the selected category
  useEffect(() => {
    if (!categories || categories.length === 0 || !values.categoryId) {
      return;
    }
    if (!categories.map((c) => c.id).includes(values.categoryId)) {
      setFieldValue('categoryId', null);
    }
  }, [categories]);

  return (
    <>
      <Typography.Title level={4}>
        {t('evaluationRequest.creation.advanced.title')}
      </Typography.Title>
      <AntForm className={styles.form} layout="vertical">
        <Row gutter={32}>
          <Col span={10}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.advanced.subject')}
            >
              <InputField<AdvancedFormValues>
                valueKey="subject"
                component={TextArea}
                showCount
                maxLength={500}
                readOnly={!editableFields.includes('subject')}
              />
            </AntForm.Item>
          </Col>
          <Col span={4}>
            <AntForm.Item
              label={t('evaluationRequest.creation.advanced.applicant')}
            >
              <ReadonlyField value={userName ?? ''} />
            </AntForm.Item>
          </Col>
          {values.statusId !== StatusEnum.DRAFT &&
          values.statusId !== StatusEnum.WAITING_VALIDATION ? (
            <Col span={4}>
              <AntForm.Item
                label={t(
                  'evaluationRequest.creation.advanced.technicianInCharge',
                )}
              >
                <ReadonlyField
                  value={
                    values.technician
                      ? `${values.technician.firstname} ${values.technician.lastname}`
                      : ''
                  }
                />
              </AntForm.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.advanced.requestDate')}
            >
              <ReadonlyField value={format(requestDate, 'dd/MM/yyyy')} />
            </AntForm.Item>
          </Col>
          <Col span={4}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.advanced.priority')}
            >
              <SelectField<AdvancedFormValues>
                valueKey="priorityId"
                optionsList={priorities}
                readOnly={!editableFields.includes('priorityId')}
              />
            </AntForm.Item>
          </Col>
          <Col span={4}>
            <AntForm.Item
              label={t('evaluationRequest.creation.advanced.maxStartDate')}
            >
              <ReadonlyField
                value={
                  maxStartDate
                    ? format(maxStartDate, 'dd/MM/yyyy')
                    : t('evaluationRequest.creation.no-priority-selected')
                }
              />
            </AntForm.Item>
          </Col>
          <Col span={4}>
            <AntForm.Item
              label={t('evaluationRequest.creation.advanced.estimatedEndDate')}
            >
              <DatePickerField<AdvancedFormValues>
                valueKey="estimatedEndDate"
                readOnly={!editableFields.includes('estimatedEndDate')}
              />
            </AntForm.Item>
          </Col>
          <Col span={4}>
            <AntForm.Item
              label={t(
                'evaluationRequest.creation.advanced.intermediateExitDate',
              )}
            >
              <DatePickerField<AdvancedFormValues>
                valueKey="intermediateExitDate"
                readOnly={!editableFields.includes('intermediateExitDate')}
              />
            </AntForm.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.advanced.technology')}
            >
              <SelectField<AdvancedFormValues>
                valueKey="technologyId"
                optionsList={technologies}
                readOnly={!editableFields.includes('technologyId')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.advanced.product')}
            >
              <SelectField<AdvancedFormValues>
                valueKey="productId"
                optionsList={products}
                readOnly={!editableFields.includes('productId')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.simple.category')}
            >
              <SelectField<AdvancedFormValues>
                valueKey="categoryId"
                optionsList={categories}
                readOnly={!editableFields.includes('categoryId')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.advanced.clientSupplier')}
            >
              <SearchableSelectClientSupplier<AdvancedFormValues>
                valueKey="clientSupplierId"
                readOnly={!editableFields.includes('clientSupplierId')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.projectNumber')}
            >
              <InputField<AdvancedFormValues>
                valueKey="project"
                readOnly={!editableFields.includes('project')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.lotNumber')}
            >
              <InputField<AdvancedFormValues>
                valueKey="lotNumber"
                readOnly={!editableFields.includes('lotNumber')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.commandNumber')}
            >
              <InputField<AdvancedFormValues>
                valueKey="commandNumber"
                readOnly={!editableFields.includes('commandNumber')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.orderedQuantity')}
            >
              <InputField<AdvancedFormValues>
                valueKey="orderedQuantity"
                type="number"
                min={0}
                readOnly={!editableFields.includes('orderedQuantity')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.BRNumber')}
            >
              <InputField<AdvancedFormValues>
                valueKey="BRNumber"
                readOnly={!editableFields.includes('BRNumber')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.purchaseOrder')}
            >
              <InputField<AdvancedFormValues>
                valueKey="purchaseOrder"
                readOnly={!editableFields.includes('purchaseOrder')}
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.qualityPlanNumber')}
            >
              <InputField<AdvancedFormValues>
                valueKey="qualityPlanNumber"
                readOnly={!editableFields.includes('qualityPlanNumber')}
              />
            </AntForm.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.simple.productDesignation')}
            >
              <SearchableSelectProductionDesignation<AdvancedFormValues>
                valueKey="series.0.productDesignationId"
                readOnly
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.simple.referenceArts')}
            >
              <SearchableSelectArtsReference<AdvancedFormValues>
                valueKey="series.0.productDesignationId"
                readOnly
              />
            </AntForm.Item>
          </Col>
          <Col span={6}>
            <AntForm.Item
              label={t('evaluationRequest.creation.simple.nominalCapacity')}
              required
            >
              <InputField<AdvancedFormValues>
                valueKey="nominalCapacity"
                readOnly={!editableFields.includes('nominalCapacity')}
              />
            </AntForm.Item>
          </Col>
          <Col span={10}>
            <AntForm.Item
              label={t('evaluationRequest.creation.advanced.description')}
            >
              <InputField<AdvancedFormValues>
                valueKey="description"
                component={TextArea}
                readOnly={!editableFields.includes('description')}
                showCount
                maxLength={500}
              />
            </AntForm.Item>
          </Col>
        </Row>
        <Spacer direction="vertical">
          <Flex column>
            <SeriesFieldArray readOnly={!editableFields.includes('series')} />
          </Flex>
          <Flex column>
            <EvaluationStepsFieldArray
              readOnly={!editableFields.includes('steps')}
            />
          </Flex>
          {values.statusId === StatusEnum.DONE ? (
            <Flex column>
              <CommentsAndConclusion
                readOnlyTechnician={
                  !editableFields.includes('conclusionTechnician')
                }
                readOnlyManager={!editableFields.includes('conclusionManager')}
                readOnlyApplicant={
                  !editableFields.includes('conclusionApplicant')
                }
              />
            </Flex>
          ) : null}
        </Spacer>

        <InputError
          error={
            error.context ? t(error.key, { context: error.context }) : undefined
          }
        />
        <Spacer />
        <div className={classNames(styles.actionsContainer, styles.noPrint)}>
          {actions}
        </div>
      </AntForm>
    </>
  );
}
