import { useQuery } from 'react-query';
import { emptyPaginatedData } from '../../../technical/api/constants';
import { selectRecords } from '../../../technical/selects/selectRecords';
import {
  fetchBenchStatuses,
  fetchBenchTypes,
  fetchBench,
  fetchUsedLanes,
} from '../api';
import { Pagination } from '../../../technical/hooks/usePagination';

export const benchKeys = {
  all: [{ scope: 'bench' }] as const,
  lists: () => [{ ...benchKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...benchKeys.lists()[0], search, page }] as const,
  usedLanes: () => [{ ...benchKeys.all[0], entity: 'usedLane' }] as const,
  benchUsedLanes: (benchId?: number) =>
    [{ ...benchKeys.usedLanes()[0], benchId }] as const,
};

export const benchStatusKeys = {
  all: [{ scope: 'bench-status' }] as const,
  lists: () => [{ ...benchStatusKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...benchStatusKeys.lists()[0], search, page }] as const,
};

export const benchTypeKeys = {
  all: [{ scope: 'bench-type' }] as const,
  lists: () => [{ ...benchTypeKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...benchTypeKeys.lists()[0], search, page }] as const,
};

export const useBenchStatusList = () => {
  const query = useQuery(benchStatusKeys.lists(), fetchBenchStatuses, {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
  });

  return query;
};

export const useBenchTypeList = () => {
  const query = useQuery(benchTypeKeys.lists(), fetchBenchTypes, {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
  });

  return query;
};

export const useBenchList = (benchType: number) => {
  const query = useQuery(benchKeys.lists(), () => fetchBench(benchType), {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
  });

  return (query.data || []).filter((bench) => bench.benchTypeId === benchType);
};

export const useUsedLanesList = (benchId?: number) => {
  const query = useQuery(
    benchKeys.benchUsedLanes(benchId),
    () => fetchUsedLanes(benchId!),
    { enabled: !!benchId, placeholderData: [] },
  );

  return query.data || [];
};
