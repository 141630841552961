import * as React from 'react';
import SelectField from 'technical/form/formik/selectField';
import InputField from 'technical/form/formik/InputField';
import { useApplicants, useTechnicians } from 'business/user/services/queries';
import { AugmentedUser } from 'business/user/types';
import RangePickerField from 'technical/form/formik/rangePickerField';
import { RecursiveFormNames } from 'technical/form/formik/types';
import { useEvaluationRequestStatus } from 'business/evaluationRequestStatus/hooks/queries';
import { usePrioritiesList } from 'business/priority/hooks/queries';
import { useTechnologiesList } from 'business/technology/hooks/queries';
import { useProductsList } from 'business/product/hooks/queries';

import { isStatusDraft } from 'business/evaluationRequest/utils/evaluationRequestStatus';
import SearchableSelectField from 'technical/form/formik/searchableSelectField';
import { useCategoriesList } from 'business/category/hooks/queries';
import { useClientSuppliersList } from 'business/clientSupplier/hooks/queries';
import { useProductDesignationsList } from 'business/productDesignation/hooks/queries';
import { Filters } from '../searchFilter/types';

interface SearchInputProps<FormValues> {
  filterBy: Filters;
  name: RecursiveFormNames<FormValues>;
  defaultValue: any;
}

function convertToOptions(users: AugmentedUser[]) {
  return users.map((user) => ({
    id: user.id,
    name: user.fullname,
  }));
}

function SearchInput<FormValues extends object>({
  filterBy,
  name,
  defaultValue,
}: SearchInputProps<FormValues>) {
  const applicants = useApplicants({
    select: convertToOptions,
    skip: filterBy !== 'applicant',
  });
  const technicians = useTechnicians({
    select: convertToOptions,
    skip: filterBy !== 'technician',
  });
  const statuses = useEvaluationRequestStatus({ skip: filterBy !== 'status' });
  const priorities = usePrioritiesList({ skip: filterBy !== 'priority' });
  const technologies = useTechnologiesList({});
  const products = useProductsList({});
  const categories = useCategoriesList({ formType: 'all' });
  const clientSuppliers = useClientSuppliersList({});
  const productDesignations = useProductDesignationsList({});

  switch (filterBy) {
    case 'requestDate':
    case 'maxStartDate':
    case 'startDate':
    case 'endDate':
    case 'estimatedEndDate':
    case 'intermediateExitDate':
      return (
        <RangePickerField<FormValues>
          valueKey={name}
          size="middle"
          defaultValue={
            [defaultValue?.start ?? null, defaultValue?.end ?? null] as any
          }
        />
      );
    case 'applicant':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={applicants.isLoading}
          optionsList={applicants.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'technician':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={technicians.isLoading}
          optionsList={technicians.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'status':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={statuses.isLoading}
          optionsList={statuses.data?.filter(({ id }) => !isStatusDraft(id))}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'priority':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={priorities.isLoading}
          optionsList={priorities.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'category':
      return (
        <SearchableSelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={categories.isLoading}
          optionsList={categories.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'product':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={products.isLoading}
          optionsList={products.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'clientSupplier':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={clientSuppliers.isLoading}
          optionsList={clientSuppliers.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'technology':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={technologies.isLoading}
          optionsList={technologies.data}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'productDesignation':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={productDesignations.isLoading}
          optionsList={productDesignations.data?.map((productDesignation) => ({
            id: productDesignation.name,
            name: productDesignation.name,
          }))}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'artsReference':
      return (
        <SelectField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          loading={productDesignations.isLoading}
          optionsList={productDesignations.data?.map((productDesignation) => ({
            id: productDesignation.referenceArts,
            name: productDesignation.referenceArts,
          }))}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    case 'reference':
    case 'project':
    case 'subject':
    case 'qualityPlanNumber':
    case 'orderedQuantity':
    case 'BRNumber':
    case 'lotNumber':
    case 'commandNumber':
    case 'purchaseOrder':
    case 'nominalCapacity':
    case 'description':
    case 'conclusions':
    case 'comments':
      return (
        <InputField<FormValues>
          valueKey={name}
          defaultValue={defaultValue}
          size="middle"
          style={{ width: '100%' }}
        />
      );
    default:
      throw new Error('');
  }
}

export default SearchInput;
