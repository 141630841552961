import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as AntForm } from 'antd';
import { useBenchList, useUsedLanesList } from 'business/bench/hooks/queries';
import { BenchType } from 'business/bench/types';
import {
  PositionItemsFormValues,
  PositionItemsByBench,
} from 'business/evaluationRequest/types';
import SelectField from 'technical/form/formik/selectField';
import Spacer from 'ui/spacer';
import Table from 'ui/table';
import { BenchProgram } from 'business/benchProgram/types';
import InputField from 'technical/form/formik/InputField';
import { distinct } from 'technical/utils/array';

interface UpdateItemPositionSubFormProps {
  positionItems: Partial<PositionItemsByBench>;
  index: number;
  benchTypeList: BenchType[];
  availableItems: string[];
  itemNames: string[];
  selectNItems: (nItems: number) => void;
  benchPrograms?: BenchProgram[];
  evaluationRequestId: number;
  stepId: number;
  version?: number;
}

const UpdateItemPositionSubForm: React.FC<UpdateItemPositionSubFormProps> = ({
  positionItems,
  index,
  benchTypeList,
  availableItems,
  itemNames,
  selectNItems,
  benchPrograms,
  evaluationRequestId,
  stepId,
  version,
}) => {
  const { t } = useTranslation();

  const benchList = useBenchList(positionItems?.benchType || 0);
  const selectedBench = benchList.find(
    (bench) => bench.id === positionItems?.benchId,
  );
  const benchProgram = benchPrograms?.find(
    (program) => program.benchId === selectedBench?.id,
  );

  const usedLanes = useUsedLanesList(selectedBench?.id).filter(
    (el) =>
      el.benchId === selectedBench?.id &&
      // we assume lanes from previous versions/steps will be free
      // because going to next version/step means that previous one are finished
      (el.evaluationRequestId !== evaluationRequestId ||
        el.step > stepId ||
        el.version > (version || 1)),
  );

  // The + is to make sure we count the items positioned on the bench,
  // and therefore not in the availableItems array
  const maxProductsCount = Math.min(
    selectedBench?.numberOfLanes || 0,
    availableItems.length + (positionItems?.nItems || 0),
  );
  const maxProductsOptionsList = Array.from(
    { length: Math.max(0, maxProductsCount) },
    (_, i) => i + 1,
  );
  // skip the numbers that are already selected on another evaluation requests
  const laneIndices = Array.from(
    Array(selectedBench?.numberOfLanes || 0).keys(),
  ).map((i) => i + 1);
  const laneOptionsList = laneIndices.map((el) => ({
    id: el,
    available: !usedLanes.map((x) => x.lane).includes(el),
    refs: distinct(
      usedLanes.filter((x) => x.lane === el).map((x) => x.evaluationRequestRef),
    ).join(', '),
  }));

  const columns = [
    {
      title: t('evaluationRequest.creation.itemPositions.itemNumber'),
      dataIndex: 'itemNumber',
      key: 'itemNumber',
      render: (_text: string, _record: any, itemIndex: number) => (
        <SelectField<PositionItemsFormValues>
          optionsList={itemNames.map((name) => ({ id: name, name }))}
          valueKey={`itemPositions.${index}.positionItems.${itemIndex}.element`}
        />
      ),
    },
    {
      title: t('evaluationRequest.creation.itemPositions.lane'),
      dataIndex: 'lane',
      key: 'lane',
      render: (_text: string, _record: any, itemIndex: number) => (
        <SelectField<PositionItemsFormValues>
          optionsList={laneOptionsList.map(({ id, available, refs }) => ({
            id,
            name: available
              ? `${id}`
              : t('evaluationRequest.creation.itemPositions.usedLane', {
                  lane: id,
                  evaluationRequestRefs: refs,
                }),
          }))}
          valueKey={`itemPositions.${index}.positionItems.${itemIndex}.lane`}
        />
      ),
    },
  ];

  const dataSource = (positionItems.positionItems || []).map((items, i) => ({
    key: `${items.element}_${i}`,
    itemNumber: items.element,
    lane: items.lane,
  }));

  return (
    <Spacer direction="vertical" size="small">
      <AntForm layout="inline">
        <AntForm.Item
          label={t('evaluationRequest.creation.itemPositions.benchType')}
        >
          <SelectField<PositionItemsFormValues>
            optionsList={benchTypeList.map((benchType) => ({
              id: benchType.id,
              name: benchType.name,
            }))}
            valueKey={`itemPositions.${index}.benchType`}
          />
        </AntForm.Item>
        <AntForm.Item
          label={t('evaluationRequest.creation.itemPositions.benchName')}
        >
          <SelectField<PositionItemsFormValues>
            optionsList={benchList.map((bench) => ({
              id: bench.id,
              name: bench.name,
            }))}
            valueKey={`itemPositions.${index}.benchId`}
          />
        </AntForm.Item>
        <AntForm.Item
          label={t('evaluationRequest.creation.itemPositions.nItems.label')}
        >
          <SelectField<PositionItemsFormValues>
            optionsList={maxProductsOptionsList.map((i) => ({
              id: i,
              name: `${i}`,
            }))}
            valueKey={`itemPositions.${index}.nItems`}
            onSelect={(nItems: any) => selectNItems(nItems)}
          />
        </AntForm.Item>
        <AntForm.Item
          label={t('evaluationRequest.creation.itemPositions.programNumber')}
        >
          <InputField<PositionItemsFormValues>
            valueKey={`itemPositions.${index}.benchProgram`}
            defaultValue={benchProgram?.program}
          />
        </AntForm.Item>
      </AntForm>

      <Spacer style={{ height: '1rem' }} />

      {dataSource.length > 0 && (
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      )}
    </Spacer>
  );
};

export default UpdateItemPositionSubForm;
