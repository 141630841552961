import React from 'react';

const ManagerContext = React.createContext<'creation' | 'update'>('creation');

export function useManagerContext() {
  return React.useContext(ManagerContext);
}

export interface ManagerContextProviderProps {
  children: React.ReactNode;
  context: 'creation' | 'update';
}
export function ManagerContextProvider({
  children,
  context,
}: ManagerContextProviderProps) {
  return (
    <ManagerContext.Provider value={context}>
      {children}
    </ManagerContext.Provider>
  );
}
