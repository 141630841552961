import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import { useGetReport, useUpdateReport } from 'business/report/hooks/queries';
import { RangeValue } from 'rc-picker/lib/interface';
import { useState, useEffect, ChangeEvent, useMemo } from 'react';

export const maxSelected = 5;

const useReportManager = () => {
  const [evaluationRequestStartDate, setEvaluationRequestStartDate] =
    useState<Date | null>(null);
  const [selectedEvaluationRequest, setSelectedEvaluationRequest] = useState<
    EvaluationRequest[] | undefined
  >(undefined);
  const [search, setSearch] = useState('');
  const [requestDate, setRequestDate] = useState<RangeValue<Date>>();

  const { data, isLoading, selectedEvals } = useGetReport();

  const { mutate } = useUpdateReport();
  const [query, { onPageChange, page: pagination }] = useEvaluationRequests(
    {
      order: {
        requestDate: 'DESC',
      },
      reference: search ? [search] : [],
      applicantName: search ? [search] : [],
      requestDate: requestDate
        ? [
            {
              start: requestDate[0]?.toISOString() ?? '',
              end: requestDate[1]?.toISOString() ?? '',
            },
          ]
        : [],
    },
    { current: 1, pageSize: 20 },
  );

  const addEvaluationRequestToSelected = (record: EvaluationRequest) => {
    if (
      selectedEvaluationRequest &&
      selectedEvaluationRequest.length >= maxSelected
    ) {
      return;
    }
    setSelectedEvaluationRequest((prev) => {
      return !(prev ?? []).map(({ id }) => id).includes(record.id)
        ? [...(prev ?? []), record]
        : prev;
    });
  };

  const removeEvaluationRequestToSelected = (record: EvaluationRequest) => {
    setSelectedEvaluationRequest((prev) =>
      (prev ?? []).filter((element) => element.id !== record.id),
    );
  };

  const submitReport = () => {
    if (!evaluationRequestStartDate) {
      return;
    }
    mutate({
      evaluationIds: (selectedEvaluationRequest ?? []).map(({ id }) => id),
      date: evaluationRequestStartDate,
    });
  };

  useEffect(() => {
    if (selectedEvals && !selectedEvaluationRequest) {
      setSelectedEvaluationRequest(selectedEvals);
    }
  }, [selectedEvals]);

  useEffect(() => {
    if (!evaluationRequestStartDate && data?.date) {
      setEvaluationRequestStartDate(data?.date);
    }
  }, [data?.date]);

  const rowKey = (record: EvaluationRequest) => record.id;

  const evaluationTableDataSource = useMemo(() => {
    return query.data?.evaluationRequests.filter(
      (e) =>
        !selectedEvaluationRequest?.map((request) => request.id).includes(e.id),
    );
  }, [query, selectedEvaluationRequest]);

  const isSelectable =
    !selectedEvaluationRequest ||
    selectedEvaluationRequest.length < maxSelected;

  return {
    isLoading,
    dateInputProps: {
      value: evaluationRequestStartDate,
      onChange: setEvaluationRequestStartDate,
    },
    searchInputProps: {
      value: search,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value ?? '');
      },
    },
    rangeSearchInputProps: {
      value: requestDate,
      onChange: setRequestDate,
    },
    evaluationTableProps: {
      pagination: {
        ...pagination,
        total: query.data?.total ?? 0,
      },
      onChange: onPageChange,
      dataSource: evaluationTableDataSource ?? [],
      loading: isLoading,
      rowKey,
    },
    selectedEvaluationTableProps: {
      pagination: false as const,
      dataSource: selectedEvaluationRequest ?? [],
      loading: isLoading,
      rowKey,
    },
    addEvaluationRequestToSelected,
    removeEvaluationRequestToSelected,
    submitReport,
    isSelectable,
  };
};

export default useReportManager;
