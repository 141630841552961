import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { Input, Form as AntForm } from 'antd';
import classnames from 'classnames';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import InputError from 'ui/form/inputError';
import * as Yup from 'yup';
import Routes from 'business/router/routes';
import { Link } from 'react-router-dom';

export interface ForgotPasswordFormValues {
  email: string;
}

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('common.form.validation.validEmail')
    .required('common.form.validation.required'),
});

interface Props {
  submitButton: {
    label: string;
  };
  cancelButton: {
    label: string;
  };
  className?: string;
  formSubmit: (values: ForgotPasswordFormValues) => void;
  isLoading: boolean;
  error?: string;
}

export default function ForgotPasswordForm({
  submitButton,
  cancelButton,
  formSubmit,
  className,
  isLoading,
  error,
}: Props) {
  const { t } = useTranslation();

  return (
    <Formik<ForgotPasswordFormValues>
      initialValues={initialValues}
      onSubmit={formSubmit}
      validationSchema={forgotPasswordSchema}
    >
      {({ submitForm, values, setFieldValue, errors, touched }) => (
        <AntForm layout="vertical" className={classnames(className)}>
          <Spacer direction="vertical" align="center">
            <Spacer direction="vertical" size="small">
              <AntForm.Item label={t('notConnected.login.email')}>
                <Field
                  component={Input}
                  name="email"
                  data-test-id="email-input"
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('email', value)
                  }
                />
                {errors.email && touched.email ? (
                  <InputError error={t(errors.email)} />
                ) : null}
              </AntForm.Item>

              <InputError error={error} />
            </Spacer>

            <Button
              id="submit-button"
              data-test-id="submit-button"
              type="primary"
              loading={isLoading}
              onClick={submitForm}
              block
            >
              {submitButton.label}
            </Button>

            <Link to={Routes.SignIn}>{cancelButton.label}</Link>
          </Spacer>
        </AntForm>
      )}
    </Formik>
  );
}
