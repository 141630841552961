import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import report from 'business/admin/pages/dataManagement/entities/report';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from '../../../../ui/form/datePicker';
import styles from './index.module.scss';
import useReportManager, { maxSelected } from './useReportManager';

const { columns } = report;

function ReportManager() {
  const { t } = useTranslation();

  const {
    evaluationTableProps,
    dateInputProps,
    searchInputProps,
    rangeSearchInputProps,
    isLoading,
    addEvaluationRequestToSelected,
    removeEvaluationRequestToSelected,
    selectedEvaluationTableProps,
    submitReport,
    isSelectable,
  } = useReportManager();

  return (
    <div className={styles.reportManager}>
      <Typography.Title level={4}>
        {t(`pages.dataManagement.tab-title.report`)}
      </Typography.Title>
      <div className={styles.searchForm}>
        <Form layout="vertical">
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item
                label={t(
                  `pages.dataManagement.entity.report.search.creationDate`,
                )}
              >
                <Spin spinning={isLoading}>
                  <DatePicker {...dateInputProps} />
                </Spin>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Divider />
      <Form layout="vertical">
        <Row gutter={32}>
          <Col span={5}>
            <Form.Item
              label={t('pages.dataManagement.entity.report.search.tableSearch')}
            >
              <Spin spinning={isLoading}>
                <Input allowClear {...searchInputProps}></Input>
              </Spin>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('pages.dataManagement.entity.report.search.rangeSearch')}
            >
              <DatePicker.RangePicker
                value={rangeSearchInputProps.value}
                onChange={rangeSearchInputProps.onChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* Entities list */}
      <div className={styles.evaluationsRequestTables}>
        <div className={styles.table}>
          <h3>{t('admin.report.evaluationRequestList')}</h3>
          <Table<EvaluationRequest>
            tableLayout="fixed"
            columns={useMemo(() => {
              const tableColumns = columns().map((column) => ({
                ...column,
                title: t(
                  `pages.dataManagement.entity.report.columns.${column.key}`,
                ),
              }));

              tableColumns.push({
                title: t(`common.add`),
                width: 100,
                render: (record) => {
                  return (
                    <Button
                      onClick={() => addEvaluationRequestToSelected(record)}
                      shape="circle"
                      type="primary"
                      disabled={!isSelectable}
                    >
                      <PlusOutlined />
                    </Button>
                  );
                },
              });

              return tableColumns;
            }, [columns, isSelectable])}
            {...evaluationTableProps}
          />
        </div>
        <div className={styles.table}>
          <h3>{t('admin.report.chosenEvaluationRequestList')}</h3>
          <Table<EvaluationRequest>
            tableLayout="fixed"
            {...selectedEvaluationTableProps}
            footer={(currentPageData) => {
              return (
                <span>
                  {currentPageData.length} / {maxSelected}
                </span>
              );
            }}
            columns={useMemo(() => {
              const tableColumns = columns().map((column) => ({
                ...column,
                title: t(
                  `pages.dataManagement.entity.report.columns.${column.key}`,
                ),
              }));

              tableColumns.push({
                title: t(`common.delete`),
                width: 100,
                render: (record) => {
                  return (
                    <Button
                      onClick={() => removeEvaluationRequestToSelected(record)}
                      shape="circle"
                      type="primary"
                    >
                      <MinusOutlined />
                    </Button>
                  );
                },
              });

              return tableColumns;
            }, [columns])}
          />
        </div>
      </div>
      <div>
        <Button
          type="primary"
          size="middle"
          htmlType="submit"
          onClick={submitReport}
          disabled={dateInputProps.value === null}
        >
          {t('admin.submit')}
        </Button>
      </div>
    </div>
  );
}

export default ReportManager;
