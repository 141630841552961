import axios from 'axios';
import { EvaluationStepCommentFormValues } from 'business/evaluationRequest/types';
import { useMutation } from 'react-query';
import { EvaluationStep } from './types';

export const fetchEvaluationStep = async ({
  id,
}: {
  id: number;
}): Promise<EvaluationStep> => {
  return (await axios.get(`/v1/evaluation-step/${id}`)).data;
};

export const usePostEvaluationStep = (
  id: number,
  onSuccess?: () => Promise<void>,
  onError?: () => void,
) => {
  return useMutation(
    async (values: EvaluationStepCommentFormValues) =>
      (await axios.post(`/v1/evaluation-step/${id}`, values)).data,
    {
      onSuccess,
      onError,
    },
  );
};
