import { UseQueryResult } from 'react-query';

interface QueryWrapperProps<T> {
  hook: () => UseQueryResult<T, unknown>;
  children: (query: UseQueryResult<T, unknown>) => React.ReactNode;
}

const QueryWrapper = <T,>({ hook, children }: QueryWrapperProps<T>) => {
  const query = hook();
  return <>{children(query)}</>;
};

export default QueryWrapper;
