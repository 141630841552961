import xlsx, { CellObject } from 'xlsx';

// Matches 'na' or 'n/a' (case insensitive)
export const NA_REGEX = /^(n[\.\/]?a)$/i;

const SEPARATOR_REGEX = /^-{5,}$/;

/*
 * We assume csv files are correctly formatted:
 * - one column
 * - values starting from row #6
 * - could have blank rows
 * - <=0 values are series separators
 */
export async function readMassMeasuresFromFile(
  data: ArrayBuffer,
): Promise<Array<Array<number | null>>> {
  const result: Array<Array<number | null>> = [];
  let workbook;
  try {
    workbook = xlsx.read(data);
  } catch {
    return result;
  }
  // get the first sheet
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  // get rows and columns counts
  if (!sheet['!ref']) {
    return result;
  }
  const range = xlsx.utils.decode_range(sheet['!ref']);
  let startParsing = false;

  // get values
  for (let rowNum = 0; rowNum <= range.e.r; rowNum += 1) {
    const cell: CellObject = sheet[xlsx.utils.encode_cell({ r: rowNum, c: 0 })];
    if (cell && typeof cell.v === 'string') {
      // skip rows before the separator '--------'
      if (!startParsing) {
        if (SEPARATOR_REGEX.test(cell.v.trim())) {
          startParsing = true;
          continue;
        } else {
          continue;
        }
      }

      try {
        // convert string to float (ex: '55.092 g')
        const value = Number(
          cell.v.replace(/(\d+((\.|,)\d+)?).+/, '$1').replace(',', '.'),
        );
        // create a new group when receiving 0 or negative value in file
        if (+value.toFixed(2) <= 0) {
          result.push([]);
        }
        // or get the value
        else {
          if (result.length === 0) {
            result.push([]);
          }
          result[result.length - 1].push(
            Number.isNaN(value) && NA_REGEX.test(cell.v.trim()) ? null : value,
          );
        }
      } catch {
        // eslint-disable-next-line no-continue
        continue;
      }
    }
  }
  return result;
}

/**
 * We assume xlsx files are correctly formatted:
 * - one row for each measure
 */
async function readMeasure(
  data: ArrayBuffer,
  columnIndex: number,
): Promise<Array<number | null>> {
  const result: (number | null)[] = [];
  let workbook;
  try {
    workbook = xlsx.read(data);
  } catch {
    return result;
  }
  // get the first sheet
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  // get rows and columns counts
  if (!sheet['!ref']) {
    return result;
  }
  const range = xlsx.utils.decode_range(sheet['!ref']);
  // get values
  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum += 1) {
    const cell: CellObject =
      sheet[xlsx.utils.encode_cell({ r: rowNum, c: columnIndex })];
    if (cell && typeof cell.v === 'number') {
      result.push(cell.v);
    } else if (
      cell &&
      typeof cell.v === 'string' &&
      NA_REGEX.test(cell.v.trim())
    ) {
      result.push(null);
    }
  }
  return result;
}

/**
 * impedance value = fourth column
 */
export async function readImpedanceMeasuresFromFile(
  data: ArrayBuffer,
): Promise<Array<number | null>> {
  return readMeasure(data, 3);
}

/**
 * voltage value = fifth column
 */
export async function readVoltageMeasuresFromFile(
  data: ArrayBuffer,
): Promise<Array<number | null>> {
  return readMeasure(data, 4);
}
