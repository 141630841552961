import React, { useEffect } from 'react';
import { Formik, FieldArray, FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useBenchTypeList } from 'business/bench/hooks/queries';
import { usePostEvaluationRequestPositions } from 'business/evaluationRequest/hooks/queries';
import { useFetchBenchPrograms } from 'business/benchProgram/hooks/queries';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import {
  PositionItem,
  PositionItemsFormValues,
  PositionItemsByBench,
  FetchedPositionItem,
  CreatePositionsArgs,
} from 'business/evaluationRequest/types';
import styles from './index.module.scss';
import UpdateItemPositionSubForm from '../updateItemPositionSubForm';
import { itemPositionsSchema } from '../../schema/itemPositionsSchema';

interface Serie {
  serie: number;
  nItems: number;
}

interface UpdatePositionsProps {
  evaluationRequestId: number;
  stepId: number;
  series: Serie[];
  defaultValues?: FetchedPositionItem[];
  updateVersion: () => void;
  backToMeasures: () => void;
  version?: number;
}

const getPositionItemsByBench = (
  positionItems: FetchedPositionItem[],
): PositionItemsByBench[] => {
  const positionItemsRecord: Record<string, PositionItemsByBench> =
    positionItems.reduce(
      (acc, item, index) => {
        return {
          ...acc,
          [item.benchId]: {
            benchId: item.benchId,
            benchType: item.bench.benchTypeId,
            nItems: (acc[item.benchId]?.nItems || 0) + 1,
            positionItems: [
              ...(acc[item.benchId]?.positionItems || []),
              { index, benchType: item.bench.benchTypeId, ...item },
            ],
          },
        };
      },
      {} as Record<string, PositionItemsByBench>,
    );
  return Object.values(positionItemsRecord);
};

const generateInitialFormValues = (
  defaultValues: FetchedPositionItem[],
): PositionItemsFormValues => {
  const positionItemsByBench = getPositionItemsByBench(defaultValues);
  return {
    itemPositions: positionItemsByBench,
  };
};

// Return generated item names sorted (S1_1 < S1_2)
const generateItemNames = (series: Serie[]): string[] => {
  return series.reduce((acc, serie) => {
    const itemNamesInSerie = Array.from(Array(serie.nItems).keys()).map(
      (index) => {
        return `S${serie.serie}_${index + 1}`;
      },
    );
    return [...acc, ...itemNamesInSerie];
  }, [] as string[]);
};

const createPositionItems =
  (startIndex: number) =>
  (
    nItems: number,
    availableItems: string[],
    benchId: number,
    benchType: number,
    stepId: number,
  ): PositionItem[] => {
    return Array.from({ length: Math.max(0, nItems) }, (_, index) => {
      return {
        element: availableItems[index],
        benchId,
        benchType,
        lane: startIndex + index + 1,
        stepId,
      };
    });
  };

const generateFirstPositionItems = createPositionItems(0);

const formatFormValues = (
  itemPositions: PositionItemsByBench[],
): CreatePositionsArgs => {
  const programs = itemPositions
    .filter(({ benchProgram }) => benchProgram !== undefined)
    .map(({ benchId, benchProgram }) => {
      if (!benchId || !benchProgram) {
        throw new Error('Invalid bench program');
      }
      return { benchId, program: benchProgram };
    });

  const positions = itemPositions.flatMap(({ benchId, positionItems }) => {
    return positionItems.map((position) => {
      if (!benchId || !position.lane) {
        throw new Error('Invalid position items');
      }
      return {
        benchId,
        lane: position.lane,
        element: position.element,
      };
    });
  });

  return { programs, positions };
};

const UpdateItemPositions: React.FC<UpdatePositionsProps> = ({
  evaluationRequestId,
  series,
  defaultValues,
  stepId,
  updateVersion,
  backToMeasures,
  version,
}) => {
  const { t } = useTranslation();
  const benchTypeQuery = useBenchTypeList();
  const { mutate, isLoading, isSuccess } = usePostEvaluationRequestPositions(
    evaluationRequestId,
    stepId,
  );
  const { data: benchPrograms } = useFetchBenchPrograms(stepId, version);
  const itemNames = generateItemNames(series);
  const emptyFormValues = {
    benchId: null,
    benchType: null,
    nItems: null,
  };
  const initialValues: PositionItemsFormValues =
    defaultValues && defaultValues.length
      ? generateInitialFormValues(defaultValues)
      : {
          itemPositions: [{ ...emptyFormValues }],
        };

  useEffect(() => {
    if (isSuccess) {
      updateVersion();
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={itemPositionsSchema}
      onSubmit={(value) => {
        const formatedValues = formatFormValues(
          value.itemPositions as PositionItemsByBench[],
        );
        mutate(formatedValues);
      }}
    >
      {({ values, submitForm }) => {
        const placedItems = values.itemPositions
          .map((itemPosition) =>
            (itemPosition?.positionItems || []).map(
              (positionItem) => positionItem.element,
            ),
          )
          .flat();

        const availableItems = itemNames.filter(
          (name) => !placedItems.includes(name),
        );

        return (
          <Spacer direction="vertical">
            <FieldArray name="itemPositions">
              {(arrayHelpers: FieldArrayRenderProps) => {
                return (
                  <Spacer direction="vertical">
                    {values.itemPositions.map((positionItems, index) => {
                      return (
                        <UpdateItemPositionSubForm
                          key={positionItems.benchId || 0}
                          index={index}
                          positionItems={positionItems}
                          benchTypeList={benchTypeQuery.data || []}
                          availableItems={availableItems}
                          itemNames={itemNames}
                          benchPrograms={benchPrograms}
                          // when updating the number of product we set up on a bench
                          selectNItems={(nItems) => {
                            // when nothing is selected yet
                            if (
                              !positionItems.nItems ||
                              positionItems.nItems === 0
                            ) {
                              // Create default position items
                              const newPositionItems =
                                generateFirstPositionItems(
                                  nItems,
                                  availableItems,
                                  positionItems.benchId || 0,
                                  positionItems.benchType || 0,
                                  stepId,
                                );
                              arrayHelpers.replace(index, {
                                ...positionItems,
                                nItems,
                                positionItems: newPositionItems,
                              });
                              // Check if a new form needs to be created
                              const hasEmptyForms =
                                values.itemPositions.filter(
                                  (itemPositions) =>
                                    !itemPositions.nItems &&
                                    itemPositions.benchId !==
                                      positionItems.benchId,
                                ).length > 0;
                              if (
                                availableItems.length - nItems > 0 &&
                                !hasEmptyForms
                              ) {
                                arrayHelpers.push({ ...emptyFormValues });
                              }
                            }
                            // when reducing the selected number (for instance, 4 -> 3)
                            else if (
                              nItems < positionItems.nItems &&
                              positionItems.positionItems
                            ) {
                              // Remove some position items
                              const newPositionItems =
                                positionItems.positionItems.slice(0, nItems);
                              arrayHelpers.replace(index, {
                                ...positionItems,
                                nItems,
                                positionItems: newPositionItems,
                              });

                              // Check if a new form needs to be created
                              const hasEmptyForms =
                                values.itemPositions.filter(
                                  (itemPositions) =>
                                    !itemPositions.nItems &&
                                    itemPositions.benchId !==
                                      positionItems.benchId,
                                ).length > 0;
                              if (!hasEmptyForms) {
                                arrayHelpers.push({ ...emptyFormValues });
                              }
                            }
                            // when increasing the selected number (for instance, 3 -> 4)
                            else if (
                              nItems > positionItems.nItems &&
                              positionItems.positionItems
                            ) {
                              // Add some position items
                              const diff = nItems - positionItems.nItems;
                              const addPositionItems = createPositionItems(
                                positionItems.nItems,
                              );

                              const newPositionItems = [
                                ...positionItems.positionItems,
                                ...addPositionItems(
                                  diff,
                                  availableItems,
                                  positionItems.benchId || 0,
                                  positionItems.benchType || 0,
                                  stepId,
                                ),
                              ];
                              arrayHelpers.replace(index, {
                                ...positionItems,
                                nItems,
                                positionItems: newPositionItems,
                              });

                              // Check if we need to remove a sub form
                              const hasEmptyForms =
                                values.itemPositions.filter(
                                  (itemPositions) => !itemPositions.nItems,
                                ).length > 0;
                              if (
                                hasEmptyForms &&
                                availableItems.length === diff
                              ) {
                                // note: not working with arrayHelpers.pop()
                                arrayHelpers.remove(
                                  values.itemPositions.length - 1,
                                );
                              }
                            }
                          }}
                          evaluationRequestId={evaluationRequestId}
                          stepId={stepId}
                          version={version}
                        />
                      );
                    })}
                  </Spacer>
                );
              }}
            </FieldArray>
            <Spacer />
            <div className={styles.actionsContainer}>
              {defaultValues && defaultValues.length ? (
                <Button onClick={backToMeasures}>
                  {t('evaluationRequest.creation.itemPositions.backToMeasures')}
                </Button>
              ) : null}
              <Button
                id="save-button"
                type="primary"
                loading={isLoading}
                onClick={submitForm}
              >
                {t('evaluationRequest.creation.itemPositions.validate')}
              </Button>
            </div>
          </Spacer>
        );
      }}
    </Formik>
  );
};

export default UpdateItemPositions;
