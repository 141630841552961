import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import InputError from '../../../../ui/form/inputError';
import { RecursiveFormNames } from '../types';

interface FieldErrorProps<FormValue extends object> {
  errors: FormikErrors<FormValue>;
  touched: FormikTouched<FormValue>;
  valueKey: RecursiveFormNames<FormValue>;
}

export default function FieldError<FormValue extends object>({
  errors,
  touched,
  valueKey,
}: FieldErrorProps<FormValue>) {
  const { t } = useTranslation();
  const error = get(errors, valueKey);
  const isTouched = get(touched, valueKey);

  return error && isTouched ? <InputError error={t(`${error}`)} /> : null;
}
