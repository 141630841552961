import { parseISO } from 'date-fns';

export const parseDate = (rawDate: string | Date | null) => {
  if (typeof rawDate === 'string') {
    try {
      return parseISO(rawDate);
    } catch {
      return rawDate;
    }
  } else {
    return rawDate;
  }
};

export const parseDateOrFail = (rawDate: string | Date | null) => {
  if (typeof rawDate === 'string') {
    return parseISO(rawDate);
  }
  return rawDate;
};
