import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { Input } from 'antd';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import {
  ProductDesignation,
  ProductDesignationFormValues,
} from 'business/productDesignation/types';
import { productDesignationKeys } from 'business/productDesignation/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import { removeUndefined } from 'technical/utils/formatters';
import ManagerContextReadOnly from 'business/admin/components/managerContext';

const { TextArea } = Input;

type ProductDesignationSearch = {
  name: string;
  referenceArts: string;
};

const config: DataManagementConfig<
  ProductDesignation,
  ProductDesignationFormValues,
  ProductDesignationSearch
> = {
  entity: 'product-designation',
  hooks: createAdminHooks({
    keys: productDesignationKeys,
    async fetch(page, search) {
      return (
        await axios.get('/v1/product-designation', {
          params: {
            ...(page ? getAPITablePosition(page) : {}),
            ...removeUndefined(search || {}),
          },
        })
      ).data;
    },
    async create(entity) {
      return axios.post('/v1/product-designation', {
        name: entity.name,
        referenceArts: entity.referenceArts,
        description: entity.description,
        productDesignationStatus: entity.productDesignationStatus,
        productNominalCapacity: entity.productNominalCapacity,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/product-designation/${entity.id}`, {
        name: entity.name,
        referenceArts: entity.referenceArts,
        description: entity.description,
        productDesignationStatus: entity.productDesignationStatus,
        productNominalCapacity: entity.productNominalCapacity,
      });
    },
    async del(id) {
      return axios.delete(`/v1/product-designation/${id}`);
    },
  }),
  search: {
    guard: (params): params is ProductDesignationSearch => {
      if ('name' in params) {
        if (typeof params.name !== 'string') {
          return false;
        }
      }
      if ('referenceArts' in params) {
        if (typeof params.referenceArts !== 'string') {
          return false;
        }
      }
      return true;
    },
    fields: ['name', 'referenceArts'],
  },
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        width: 280,
        render: (_value, entity) => (
          <ManagerContextReadOnly<ProductDesignation>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ProductDesignationFormValues>
                valueKey="name"
                readOnly={isReadOnly}
                maxLength={50}
                showCount
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'referenceArts',
        key: 'referenceArts',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ProductDesignation>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ProductDesignationFormValues>
                valueKey="referenceArts"
                readOnly={isReadOnly}
                maxLength={20}
                showCount
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'productDesignationStatus',
        key: 'productDesignationStatus',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ProductDesignation>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ProductDesignationFormValues>
                valueKey="productDesignationStatus"
                readOnly={isReadOnly}
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'productNominalCapacity',
        key: 'productNominalCapacity',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ProductDesignation>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ProductDesignationFormValues>
                valueKey="productNominalCapacity"
                readOnly={isReadOnly}
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ProductDesignation>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ProductDesignationFormValues>
                valueKey="description"
                readOnly={isReadOnly}
                component={TextArea}
                autoSize={{ minRows: 1, maxRows: 5 }}
                showCount
                maxLength={100}
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(50).required(),
    referenceArts: yup.string().trim().min(1).max(20).nullable(true),
    description: yup.string().trim().min(1).max(500).nullable(true),
    productDesignationStatus: yup.string().trim().min(1).max(5).nullable(true),
    productNominalCapacity: yup.number().nullable(true),
  }),
  defaultValues: {
    name: '',
    referenceArts: null,
    description: null,
    productDesignationStatus: null,
    productNominalCapacity: null,
  },
  permissions: {
    delete: (productDesignation) => !productDesignation.source,
    update: (productDesignation) => !productDesignation.source,
  },
};

export default config;
