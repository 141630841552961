import { Tooltip } from 'antd';
import React from 'react';
import styles from './index.module.scss';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
}

export default function EllipsisTooltip({ title, children }: Props) {
  return (
    <Tooltip title={title}>
      <div className={styles.ellipsis}>
        <span>{children}</span>
      </div>
    </Tooltip>
  );
}
