import React from 'react';
import { Navigate, useParams } from 'react-router';
import Routes from 'business/router/routes';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import PageLayout from '../../../../ui/PageLayout';
import CreationFormTabs from '../../components/CreationFormsTabs';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import { useFetchEvaluationRequest } from '../../hooks/queries';
import { evaluationRequestToFormValue } from '../../utils/evaluationRequestToFormValue';
import {
  AuthorizedAccessOnly,
  applicantOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'newEvaluationRequest';
const pagesBreadcrumb = ['home', 'evaluationRequest', currentPage];

const CopyEvaluationRequestPage: React.FC = () => {
  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);
  const { id } = useParams<{ id?: string }>();

  if (!id) {
    return <Navigate to={Routes.EvaluationRequests} />; // maybe change to 404
  }

  const { isLoading, data: evaluationRequest } = useFetchEvaluationRequest(id);

  return (
    <PageLayout
      header={
        <AuthenticatedHeader selectedKeys={[MenuKeyEnum.evaluationRequests]} />
      }
      breadcrumbSections={breadcrumbSections}
      isLoading={isLoading}
    >
      <AuthorizedAccessOnly policy={applicantOnly} fallback={<NotAuthorized />}>
        {evaluationRequest && (
          <CreationFormTabs
            evaluationRequest={evaluationRequestToFormValue(evaluationRequest)}
          />
        )}
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default CopyEvaluationRequestPage;
