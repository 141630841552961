import { Input, Form as AntForm } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Card from 'ui/card';
import InputError from 'ui/form/inputError';
import Spacer from 'ui/spacer';
import { Formik, Field } from 'formik';
import PasswordInput from '../../../../ui/form/passwordInput';

export interface AuthFormValues {
  email: string;
  password: string;
}

const initialValues = {
  email: '',
  password: '',
};

interface Props {
  submitButton: {
    label: string;
  };
  authError?: string;
  hint?: React.ReactNode;
  passwordHint?: React.ReactNode;
  bottomHint?: React.ReactNode;
  className?: string;
  formSubmit: (values: AuthFormValues) => void;
}

export default function AuthCard({
  submitButton,
  authError,
  hint,
  formSubmit,
  passwordHint,
  bottomHint,
  className,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card className={classnames(className)}>
      <Formik<AuthFormValues>
        initialValues={initialValues}
        onSubmit={formSubmit}
      >
        {({ submitForm, values, setFieldValue }) => (
          <AntForm layout="vertical">
            <Spacer direction="vertical" align="center">
              <AppLogo />
              <Spacer direction="vertical" size="small">
                <AntForm.Item label={t('notConnected.login.email')}>
                  <Field
                    component={Input}
                    name="email"
                    data-test-id="email-input"
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('email', value)
                    }
                  />
                </AntForm.Item>

                <Field
                  label={t('notConnected.login.password')}
                  component={PasswordInput}
                  dataTestId="password-input"
                  name="password"
                  id="password"
                  type="password"
                  passwordHint={passwordHint}
                  value={values.password}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('password', value)
                  }
                />

                <InputError error={authError} />
                {hint}
              </Spacer>

              <Button
                id="submit-button"
                data-test-id="submit-button"
                type="primary"
                htmlType="submit"
                onClick={submitForm}
                block
              >
                {submitButton.label}
              </Button>

              {bottomHint}
            </Spacer>
          </AntForm>
        )}
      </Formik>
    </Card>
  );
}
