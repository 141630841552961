import React, { useState, useEffect } from 'react';
import PageLayout from 'ui/PageLayout';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import { ColumnsType } from 'antd/lib/table';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import EvaluationStatusTag from 'business/evaluationRequest/components/evaluationRequestStatus';
import Table from 'ui/table';
import { PaginationProps, Select, Tabs, Typography } from 'antd';
import Flex from 'ui/flex';
import { format } from 'date-fns';
import match from 'technical/utils/match';
import {
  isStatusDone,
  StatusEnum,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import { AugmentedUser } from 'business/user/types';
import { OrderBy } from 'business/evaluationRequest/components/search/searchFilter/types';
import Spacer from 'ui/spacer';
import EvaluationRequestCount from 'business/evaluationRequest/components/evaluationRequestCount';
import Routes from '../../../router/routes';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import {
  useAuthenticatedUser,
  useTechnicians,
} from '../../../user/services/queries';
import {
  AuthorizedAccessOnly,
  technicianOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';

const TABS = ['all', 'pending', 'in-progress', 'done'] as const;

function convertToOptions(users: AugmentedUser[]) {
  return users.map((user) => ({
    id: user.id,
    name: user.fullname,
  }));
}

const columns = (t: TFunction): ColumnsType<EvaluationRequest> => [
  {
    title: t('evaluationRequest.table.reference'),
    dataIndex: 'reference',
    key: 'reference',
    sorter: true,
    render: (reference) => reference,
  },
  {
    title: t('evaluationRequest.table.quantity.header'),
    key: 'elements',
    render: (_value, evaluationRequest) => {
      const quantity =
        evaluationRequest.series?.reduce(
          (sum, serie) => sum + (serie.numberOfElement ?? 0),
          0,
        ) ?? evaluationRequest.toTestQuantity;

      const productDesignation =
        evaluationRequest.series?.[0]?.productDesignation ??
        evaluationRequest.productDesignation;

      return t('evaluationRequest.table.quantity.value', {
        count: quantity ?? 0,
        context: evaluationRequest.productId === 1 ? 'element' : 'battery',
        type: productDesignation?.name ?? '-',
      });
    },
  },
  {
    title: t('evaluationRequest.table.steps.header'),
    key: 'steps',
    render: (_value, evaluationRequest) => {
      const steps = evaluationRequest.steps ?? [];
      return t('evaluationRequest.table.steps.value', {
        count: steps.length,
        extra: steps.map((step) => `${step.evaluation?.name}`).join(', '),
      });
    },
  },
  {
    title: t('evaluationRequest.table.category.header'),
    dataIndex: 'category',
    key: 'category',
    // if priority entity does not exists, backend returns null, so we handle this case here
    render: (category) => <>{category ? `${category.name}` : null}</>,
  },
  {
    title: t('evaluationRequest.table.priority.longHeader'),
    key: 'maxStartDate',
    sorter: true,
    render: (_value, evaluationRequest) => {
      if (evaluationRequest.maxStartDate === null) {
        return null;
      }
      return `${format(
        new Date(evaluationRequest.maxStartDate),
        'dd/MM/yyyy',
      )} (${evaluationRequest.priority?.name})`;
    },
  },
  {
    title: t('evaluationRequest.table.status'),
    dataIndex: 'status',
    key: 'status',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (status) =>
      status ? <EvaluationStatusTag status={status} /> : null,
  },
  {
    title: t('evaluationRequest.table.action'),
    key: 'action',
    render: (_value, evaluationRequest) => {
      return (
        <Flex alignItems="center">
          <Link
            to={generatePath(Routes.EvaluationRequestDetail, {
              id: evaluationRequest.id.toString(),
            })}
          >
            {t('evaluation.request.go')}
          </Link>
          {process.env.REACT_APP_REPORT_ACTIVATED === 'true' &&
          isStatusDone(evaluationRequest.status.id) ? (
            <Link
              to={generatePath(Routes.EvaluationRequestReport, {
                id: evaluationRequest.id.toString(),
              })}
            >
              {t('evaluation.request.goToReport')}
            </Link>
          ) : null}
        </Flex>
      );
    },
  },
];

const EvaluationRequestPage: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuthenticatedUser();
  const technicians = useTechnicians({ select: convertToOptions });
  const [technician, setTechnician] = useState<number>(user.id);
  const [tab, setTab] = useState<(typeof TABS)[number]>('all');
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState<OrderBy>({
    maxStartDate: 'ASC',
  });
  const [query, { onPageChange }] = useEvaluationRequests({
    status: match(tab, {
      all: [StatusEnum.PENDING, StatusEnum.IN_PROGRESS, StatusEnum.DONE],
      pending: [StatusEnum.PENDING],
      'in-progress': [StatusEnum.IN_PROGRESS],
      done: [StatusEnum.DONE],
    }),
    technician: match(tab, {
      all: [null, technician],
      pending: [null, technician],
      'in-progress': [technician],
      done: [technician],
    }),
    order,
  });

  const data = query.data ?? {
    total: 0,
    pageSize: 10,
    evaluationRequests: [],
    current: 1,
  };
  const pagination: PaginationProps = {
    current: data.current,
    pageSize: data.pageSize,
    total: data.total,
  };

  useEffect(() => {
    setTotalCount(data.total);
  }, [data.total]);

  return (
    <PageLayout
      header={
        <AuthenticatedHeader selectedKeys={[MenuKeyEnum.toDoTechnician]} />
      }
      breadcrumbLevelActions={null}
    >
      <AuthorizedAccessOnly
        policy={technicianOnly}
        fallback={<NotAuthorized />}
      >
        <Tabs
          activeKey={tab}
          onChange={(key) => setTab(key as any)}
          destroyInactiveTabPane
          tabBarExtraContent={
            <>
              <Typography.Text>Technicien </Typography.Text>
              <Select
                value={technician}
                onChange={(value) => setTechnician(value)}
              >
                {technicians.data?.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </>
          }
        >
          {TABS.map((key) => (
            <Tabs.TabPane key={key} tab={t(`evaluationRequest.tabs.${key}`)}>
              <Spacer direction="vertical" size="small">
                <EvaluationRequestCount totalCount={totalCount} />
                <Table<EvaluationRequest>
                  columns={columns(t)}
                  dataSource={data.evaluationRequests}
                  loading={query.isLoading}
                  pagination={pagination}
                  rowKey={(record) => record.id}
                  onChange={(page, _filters, orderBy) => {
                    onPageChange(page);
                    if (!Array.isArray(orderBy)) {
                      setOrder({
                        [orderBy.columnKey as any]:
                          orderBy.order === 'ascend' ? 'ASC' : 'DESC',
                      });
                    }
                  }}
                  size="middle"
                />
              </Spacer>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default EvaluationRequestPage;
