import React from 'react';
import { useAuthenticatedUser } from 'business/user/services/queries';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  AuthorizedAccessOnly,
  allExemptAdmin,
  managerOnly,
  technicianOnly,
} from 'business/user/services/policy';
import EvaluationRequestManager from 'business/evaluationRequest/components/evaluationRequestManager';
import { EvaluationRequestWithSteps } from 'business/evaluationRequest/types';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import PageLayout from '../../../../ui/PageLayout';
import { useFetchEvaluationRequest } from '../../hooks/queries';
import Routes from '../../../router/routes';
import UpdateForms from '../../components/updateForms';
import {
  isStatusInProgress,
  StatusEnum,
} from '../../utils/evaluationRequestStatus';
import ValidationSection from '../../components/ValidationSection';
import Spacer from '../../../../ui/spacer';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import styles from './index.module.scss';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'evaluationRequestDetails';
const pagesBreadcrumb = ['home', 'evaluationRequest', currentPage];

const EvaluationRequestDetailPage: React.FC = () => {
  const me = useAuthenticatedUser();
  const isManager = managerOnly(me);

  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <Navigate to={Routes.EvaluationRequests} />; // maybe change to 404
  }
  // fetch
  const {
    isLoading,
    isFetched,
    data: evaluationRequest,
    refetch,
  } = useFetchEvaluationRequest(id);

  if (isFetched && evaluationRequest === undefined) {
    return <Navigate to={Routes.EvaluationRequests} />; // maybe change to 404
  }

  const isAuthor = evaluationRequest?.applicant?.id === me.id;

  const canEdit =
    (isAuthor && evaluationRequest.statusId <= StatusEnum.WAITING_VALIDATION) ||
    (isManager &&
      evaluationRequest &&
      evaluationRequest.statusId <= StatusEnum.PENDING &&
      evaluationRequest.statusId > StatusEnum.DRAFT);

  const canValidate =
    isManager &&
    evaluationRequest &&
    evaluationRequest.statusId >= StatusEnum.WAITING_VALIDATION; // && !isAuthor;

  return (
    <PageLayout
      isLoading={isLoading}
      header={
        <AuthenticatedHeader selectedKeys={[MenuKeyEnum.evaluationRequests]} />
      }
      breadcrumbSections={breadcrumbSections}
      classNames={styles.printable}
    >
      <AuthorizedAccessOnly
        policy={allExemptAdmin}
        fallback={<NotAuthorized />}
      >
        {isFetched && evaluationRequest && (
          <Spacer direction="vertical">
            <UpdateForms
              isDisabled={!canEdit}
              evaluationRequest={evaluationRequest}
            />
            {canValidate && (
              <ValidationSection
                status={evaluationRequest.status}
                id={evaluationRequest.id}
                applicantId={evaluationRequest.applicantId}
                reference={evaluationRequest.reference}
              />
            )}
            {evaluationRequest.steps?.length &&
            isStatusInProgress(evaluationRequest.statusId) ? (
              <AuthorizedAccessOnly policy={technicianOnly}>
                <EvaluationRequestManager
                  evaluationRequest={
                    evaluationRequest as EvaluationRequestWithSteps
                  }
                  refetchEvaluationRequest={refetch}
                />
              </AuthorizedAccessOnly>
            ) : null}
          </Spacer>
        )}
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default EvaluationRequestDetailPage;
