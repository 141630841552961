import { Pagination } from '../hooks/usePagination';

/**
 * Return table pagination position used by ant design from data return by api
 * @param pagination Pagination used by our table components
 *
 * return computed offset and limit used by API
 */
export const getAPITablePosition = ({ current, pageSize }: Pagination) => {
  const limit = pageSize;
  const offset = pageSize * (current - 1);
  return {
    offset,
    limit,
  };
};
