import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EvaluationRequest,
  EvaluationRequestWithSteps,
} from 'business/evaluationRequest/types';
import {
  evaluationRequestKeys,
  useEvaluationRequestPositions,
  usePostEvaluationRequestPositions,
} from 'business/evaluationRequest/hooks/queries';
import UpdateMeasures from 'business/measure/components/updateMeasures';
import { EvaluationStep } from 'business/evaluationStep/types';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Typography, Select, Spin, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCompleteEvaluationRequest } from 'business/evaluationRequest/hooks/actions';
import { useFetchBenchPrograms } from 'business/benchProgram/hooks/queries';
import { QueryObserverResult, useQueryClient } from 'react-query';
import UpdateItemPositions from '../updateItemPositions';
import styles from './index.module.scss';

interface EvaluationRequestManagerProps {
  evaluationRequest: EvaluationRequestWithSteps;
  refetchEvaluationRequest: () => Promise<
    QueryObserverResult<EvaluationRequest>
  >;
  readOnly?: boolean;
}

const EvaluationRequestManager: React.FC<EvaluationRequestManagerProps> = ({
  evaluationRequest,
  refetchEvaluationRequest,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const firstStep = evaluationRequest.steps
    .concat()
    .sort((a, b) => a.order - b.order)[0];
  const [selectedStep, setSelectedStep] = useState<EvaluationStep>(firstStep);
  const nextStep = useMemo(() => {
    const nextStepIndex =
      evaluationRequest.steps.findIndex(
        (step) => step.id === selectedStep?.id,
      ) + 1;
    return nextStepIndex < evaluationRequest.steps.length
      ? evaluationRequest.steps[nextStepIndex]
      : undefined;
  }, [evaluationRequest, selectedStep]);

  const [latestVersion, setLatestVersion] = useState<number | undefined>(
    selectedStep.lastPositions,
  );
  const [version, setVersion] = useState<number | undefined>(latestVersion);

  const { data: positions, isLoading } = useEvaluationRequestPositions(
    evaluationRequest.id,
    selectedStep.id,
    version,
  );
  const { data: programs } = useFetchBenchPrograms(selectedStep.id, version);
  const createNextPositionsQuery = usePostEvaluationRequestPositions(
    evaluationRequest.id,
    nextStep?.id ?? 0,
  );
  const updateEvaluationRequestStatusQuery = useCompleteEvaluationRequest({
    id: evaluationRequest.id,
  });

  useEffect(() => {
    const step = evaluationRequest.steps.find((s) => s.id === selectedStep.id);
    if (!step) {
      return;
    }
    setSelectedStep(step);
  }, [evaluationRequest]);

  const [view, setView] = useState<'updatePositions' | 'updateMesures'>(
    'updatePositions',
  );

  useEffect(() => {
    if (positions && positions.length) {
      setView('updateMesures');
    } else {
      setView('updatePositions');
    }
  }, [positions]);

  const stepsList = useMemo(() => {
    const nSteps = evaluationRequest.steps?.length || 0;
    return evaluationRequest.steps.map((step) => ({
      id: step.id,
      label: t('evaluationRequest.creation.current.stepPosition', {
        stepPosition: step.order,
        nSteps,
      }),
    }));
  }, [evaluationRequest]);

  const series = useMemo(
    () =>
      (
        evaluationRequest.series ??
        (evaluationRequest.orderedQuantity !== undefined
          ? [
              {
                serieNumber: 1,
                numberOfElement: evaluationRequest.orderedQuantity,
              },
            ]
          : [])
      ).map(({ serieNumber, numberOfElement }) => ({
        serie: serieNumber,
        nItems: numberOfElement || 0,
      })),
    [evaluationRequest],
  );

  const goToStep = useCallback((step: EvaluationStep) => {
    setSelectedStep(step);
    setVersion(step.lastPositions || 1);
    setLatestVersion(step.lastPositions || 1);
  }, []);

  const handleGoToNextStep = useCallback(() => {
    if (!nextStep) {
      return;
    }
    if (nextStep.lastPositions) {
      goToStep(nextStep);
    }
    // create new positions if necessary
    else {
      if (!positions) {
        return;
      }
      createNextPositionsQuery.mutate(
        {
          programs: (programs ?? []).map((p) => ({
            benchId: p.benchId,
            program: p.program,
          })),
          positions: positions.map((p) => ({
            benchId: p.benchId,
            element: p.element,
            lane: p.lane,
          })),
        },
        {
          onSuccess: () => {
            // needed to refresh evaluationRequest.steps
            queryClient.invalidateQueries(
              evaluationRequestKeys.detail(evaluationRequest.id),
            );
            goToStep(nextStep);
          },
        },
      );
    }
  }, [nextStep, positions, programs]);

  return (
    <div className={styles.noPrint}>
      <Typography.Title level={4}>
        {t('evaluationRequest.creation.current.title')}
      </Typography.Title>
      <AntForm.Item label={t('evaluationRequest.creation.current.step')}>
        <Select
          value={selectedStep?.id}
          onChange={(stepId) => {
            const foundStep = evaluationRequest.steps.find(
              (step) => step.id === stepId,
            );
            if (foundStep) {
              goToStep(foundStep);
            }
          }}
        >
          {stepsList.map(({ id, label }) => (
            <Select.Option key={id} value={id}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </AntForm.Item>

      {isLoading && <Spin />}
      {!isLoading && view === 'updatePositions' && (
        <UpdateItemPositions
          stepId={selectedStep?.id}
          series={series}
          defaultValues={positions}
          evaluationRequestId={evaluationRequest.id}
          updateVersion={() => {
            const newLatestVersion = (latestVersion || 0) + 1;
            setLatestVersion(newLatestVersion);
            setVersion(newLatestVersion);
          }}
          version={version}
          backToMeasures={() => setView('updateMesures')}
        />
      )}
      {!isLoading &&
        view === 'updateMesures' &&
        positions &&
        positions.length && (
          <UpdateMeasures
            readOnly={readOnly}
            positions={positions}
            changePositions={() => {
              setView('updatePositions');
              setVersion(latestVersion);
            }}
            latestVersion={latestVersion || 0}
            setVersion={setVersion}
            version={version || 0}
            stepId={selectedStep?.id}
            step={selectedStep}
            refetchEvaluationRequest={refetchEvaluationRequest}
            goToNextStep={
              nextStep !== undefined ? handleGoToNextStep : undefined
            }
            finish={
              nextStep === undefined
                ? () => {
                    Modal.confirm({
                      title: t(
                        'evaluationRequest.update.completeRequest.confirm',
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        updateEvaluationRequestStatusQuery.mutate({
                          evaluationRequestId: evaluationRequest.id,
                        });
                      },
                    });
                  }
                : undefined
            }
            evaluationRequest={evaluationRequest}
          />
        )}
    </div>
  );
};

export default EvaluationRequestManager;
