import * as yup from 'yup';
import { getDuplicates } from '../../../technical/utils/getDuplicates';
import { PositionItem } from '../types';

export const itemPositionsSchema = yup.object().shape({
  itemPositions: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          benchId: yup
            .number()
            .typeError('common.form.validation.required')
            .required('common.form.validation.required'),
          benchType: yup
            .number()
            .typeError('common.form.validation.required')
            .required('common.form.validation.required'),
          nItems: yup
            .number()
            .typeError('common.form.validation.required')
            .required('common.form.validation.required'),
          benchProgram: yup
            .string()
            .typeError('common.form.validation.required'),
          positionItems: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  element: yup
                    .string()
                    .required('common.form.validation.required')
                    .matches(/S[1-9][0-9]*_[1-9][0-9]*/),
                  benchId: yup
                    .number()
                    .typeError('common.form.validation.required')
                    .required('common.form.validation.required'),
                  benchType: yup
                    .number()
                    .typeError('common.form.validation.required')
                    .required('common.form.validation.required'),
                  lane: yup
                    .number()
                    .typeError('common.form.validation.required')
                    .required('common.form.validation.required'),
                  stepId: yup
                    .number()
                    .typeError('common.form.validation.required')
                    .required('common.form.validation.required'),
                })
                .required('common.form.validation.required'),
            )
            .required('common.form.validation.required'),
        })
        .required('common.form.validation.required'),
    )
    .required('common.form.validation.required')
    // Make sure that no element is positionned on duplicated places
    .test(
      'elementNames',
      'evaluationRequest.creation.itemPositions.validation.duplicatedElements',
      (value) => {
        const elements = value
          ?.map((item) => {
            const positionItems = item.positionItems ?? [];
            return positionItems.map(({ element }: PositionItem) => element);
          })
          .flat();

        const duplicates = getDuplicates(elements ?? []);

        const validationErrors: yup.ValidationError[] = (value
          ?.flatMap((item, index) => {
            const positionItems: PositionItem[] = item.positionItems ?? [];
            const errors = positionItems.map(({ element }, itemIndex) => {
              if (!duplicates.includes(element)) {
                return undefined;
              }
              return new yup.ValidationError(
                'evaluationRequest.creation.itemPositions.validation.duplicatedElements',
                undefined,
                `itemPositions.${index}.positionItems.${itemIndex}.element`,
              );
            });
            return errors;
          })
          .filter(Boolean) ?? []) as yup.ValidationError[];
        return new yup.ValidationError(validationErrors);
      },
    )
    // Make sure that there is no item positioned on the same lane of a bench
    .test(
      'lanes',
      'evaluationRequest.creation.itemPositions.validation.duplicatedLanes',
      (value) => {
        const lanesByBench = value?.map((item) => {
          const positionItems: PositionItem[] = item.positionItems ?? [];
          return positionItems.map(({ lane }) => lane);
        });
        const duplicatesByBench = lanesByBench?.map(getDuplicates);
        const duplicates = duplicatesByBench?.flat() ?? [];
        const validationErrors: yup.ValidationError[] = (value
          ?.flatMap((item, index) => {
            const positionItems: PositionItem[] = item.positionItems ?? [];
            return positionItems.map(({ lane }, itemIndex) => {
              if (!duplicates.includes(lane)) {
                return undefined;
              }
              return new yup.ValidationError(
                'evaluationRequest.creation.itemPositions.validation.duplicatedLanes',
                undefined,
                `itemPositions.${index}.positionItems.${itemIndex}.lane`,
              );
            });
          })
          .filter(Boolean) ?? []) as yup.ValidationError[];
        return new yup.ValidationError(validationErrors);
      },
    ),
});
