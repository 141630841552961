import { useMutation } from 'react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { postMail } from '../api';

export const usePostMail = () => {
  const { t } = useTranslation();
  return useMutation(postMail, {
    onSuccess: () => {
      notification.success({ message: t('sendComment.send.success') });
    },
    onError: () => {
      notification.error({ message: t('sendComment.send.error') });
    },
  });
};
