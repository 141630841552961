import React, { ReactElement } from 'react';
import { Header as AntHeader } from 'antd/lib/layout/layout';
import { Avatar, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { AugmentedUser } from 'business/user/types';
import AppLogo from '../appLogo';
import styles from './index.module.scss';
import colors from '../colors';

export interface HeaderProps {
  user: AugmentedUser;
  menuItems: {
    label: string;
    key: string | number;
    children?: ReactElement;
  }[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  onSignOut: () => void;
}

const Header: React.FC<HeaderProps> = ({
  user,
  menuItems = [],
  defaultSelectedKeys,
  selectedKeys,
  onSignOut,
}) => {
  const { t } = useTranslation();
  return (
    <AntHeader className={styles.header}>
      <AppLogo className={styles.logo} />
      <Menu
        className={styles.menu}
        mode="horizontal"
        defaultSelectedKeys={defaultSelectedKeys}
        selectedKeys={selectedKeys}
      >
        {menuItems.map(({ label, key, children }) => (
          <Menu.Item className={styles.menuItem} key={key}>
            {label}
            {children}
          </Menu.Item>
        ))}
      </Menu>

      <div className={styles.avatarContainer}>
        <Dropdown
          overlay={
            <Menu className={styles.userDropdown}>
              <Menu.Item className={styles.infos} key="infos">
                {t('common.userDropdown.connectedAs')}
                <br />
                <span className={styles.user}>{user.email}</span>
                {user.fullname ? (
                  <>
                    <br />
                    <span className={styles.user}>({user.fullname})</span>
                  </>
                ) : null}
                <br />
                sur Banc LE - {t('env', { context: process.env.NODE_ENV })}
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item onClick={onSignOut} key="sign-out">
                {t('common.userDropdown.signOut')}
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <Avatar
            // colors dont work properly using classname
            style={{
              color: colors.defaultAvatarColor,
              backgroundColor: colors.defaultAvatarBgColor,
            }}
          >
            {user.initials ?? 'U'}
          </Avatar>
        </Dropdown>
      </div>
    </AntHeader>
  );
};

export default Header;
