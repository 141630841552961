// see https://ant.design/docs/react/replace-moment#Use-date-fns
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export type DatePickerProps = React.ComponentProps<typeof DatePicker>;

export default DatePicker;
