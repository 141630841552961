import React from 'react';
import { Typography, Form as AntForm, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik } from 'formik';
import InputField from '../../../../technical/form/formik/InputField';
import SelectField from '../../../../technical/form/formik/selectField';
import { useRoleList } from '../../../role/hooks/queries';
import { useUserInvitation } from '../../hooks/actions';

interface InvitationFormValues {
  email: string;
  roleIds: number[];
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('common.form.validation.validEmail')
    .required('common.form.validation.required'),
  roleIds: yup
    .array()
    .of(yup.number().positive().integer())
    .min(1, 'common.form.validation.required')
    .required('common.form.validation.required'),
});

export interface InviteUserProps {
  userId: number;
  defaultEmail: string;
  defaultRoleIds: number[];
  children: (props: {
    showModal: () => void;
    visible: boolean;
  }) => React.ReactElement;
}
export function InviteUser({
  userId,
  defaultEmail = '',
  defaultRoleIds = [],
  children,
}: InviteUserProps) {
  const { t } = useTranslation();
  const { mutate } = useUserInvitation();
  const { data: roles } = useRoleList();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {children({ showModal, visible: isModalVisible })}
      <Formik<InvitationFormValues>
        initialValues={{
          email: defaultEmail,
          roleIds: defaultRoleIds,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          mutate(
            { id: userId, ...values },
            {
              onSuccess() {
                handleOk();
                resetForm();
              },
            },
          );
        }}
      >
        {({ submitForm }) => (
          <Modal
            open={isModalVisible}
            onOk={submitForm}
            onCancel={handleCancel}
            okText={t('admin.user.invitation.form.send')}
          >
            <AntForm
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 10 }}
              layout="vertical"
            >
              <Typography.Title level={4}>
                {t('admin.user.invitation.form.title')}
              </Typography.Title>
              <Typography.Paragraph>
                {t('admin.user.invitation.form.message')}
              </Typography.Paragraph>

              <AntForm.Item
                required
                label={t('admin.user.invitation.form.email')}
              >
                <InputField<InvitationFormValues>
                  valueKey="email"
                  type="email"
                />
              </AntForm.Item>

              <AntForm.Item
                required
                label={t('admin.user.invitation.form.roles')}
              >
                <SelectField<InvitationFormValues>
                  mode="multiple"
                  optionsList={roles}
                  valueKey="roleIds"
                  maxTagCount={1}
                />
              </AntForm.Item>
            </AntForm>
          </Modal>
        )}
      </Formik>
    </>
  );
}
