import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Card from 'ui/card';
import { Typography } from 'antd';
import Button from 'ui/button';
import { useNavigate } from 'react-router';
import ForgotPasswordForm from '../forgotPasswordForm';
import { requestPasswordReset } from '../../api';
import Routes from '../../../router/routes';

interface Props {
  className?: string;
}

export default function ForgotPasswordCard({ className }: Props) {
  const [requestError, setRequestError] = useState<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sendResetPasswordRequest = useMutation(requestPasswordReset);
  const title = (
    <Typography.Title level={4}>
      {t('notConnected.forgotPassword.title')}
    </Typography.Title>
  );

  useEffect(() => {
    if (sendResetPasswordRequest.isError) {
      setRequestError('common.form.genericFormError');
    }
  }, [sendResetPasswordRequest.isError, sendResetPasswordRequest.error]);

  if (sendResetPasswordRequest.isSuccess) {
    return (
      <Card className={classnames(className)}>
        {title}
        <Typography.Paragraph>
          {t('notConnected.forgotPassword.successText')}
        </Typography.Paragraph>
        <Button onClick={() => navigate(Routes.SignIn)}>
          {t('notConnected.forgotPassword.returnToSignIn')}
        </Button>
      </Card>
    );
  }
  return (
    <Card className={classnames(className)}>
      <Typography.Title level={4}>
        {t('notConnected.forgotPassword.title')}
      </Typography.Title>
      <Typography.Paragraph>
        {t('notConnected.forgotPassword.instructions')}
      </Typography.Paragraph>
      <ForgotPasswordForm
        formSubmit={(values) => {
          sendResetPasswordRequest.mutate(values, {
            onSuccess: () => {
              setRequestError(undefined);
            },
            onError: () => {
              setRequestError(t('common.form.genericFormError'));
            },
          });
        }}
        submitButton={{
          label: t('notConnected.forgotPassword.submit'),
        }}
        cancelButton={{
          label: t('notConnected.forgotPassword.returnToSignIn'),
        }}
        isLoading={sendResetPasswordRequest.isLoading}
        error={requestError}
      />
    </Card>
  );
}
