import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from 'business/evaluationRequest/types';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedUser } from 'business/user/services/queries';
import ScheduleEvaluationRequestModal from 'business/evaluationRequest/components/scheduleEvaluationRequestModal';

import {
  isStatusPending,
  StatusEnum,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import Button from 'ui/button';

interface UpdateFormActionsProps {
  isLoading: boolean;
  onDelete: () => void;
}

const UpdateFormActions: React.FC<UpdateFormActionsProps> = ({
  isLoading,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { submitForm, values, setFieldValue } = useFormikContext<FormValues>();

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);

  const hasATechnician = !!values.technicianId;
  const user = useAuthenticatedUser();

  const handleStatusUpdate = () => {
    if (!values.id) {
      return;
    }

    setFieldValue('technicianId', hasATechnician ? null : user.id);
    setFieldValue(
      'statusId',
      hasATechnician ? StatusEnum.PENDING : StatusEnum.IN_PROGRESS,
    );
    submitForm();
  };

  return (
    <>
      <div />
      <div style={{ display: 'flex', gap: '1rem' }}>
        {/* Take charge / offload request */}
        {values.statusId &&
        values.steps.length > 0 &&
        isStatusPending(values.statusId) ? (
          <Button
            id="take-charge-button"
            data-test-id="take-charge-button"
            type="primary"
            loading={isLoading}
            onClick={() =>
              hasATechnician
                ? handleStatusUpdate()
                : setIsScheduleModalVisible(true)
            }
          >
            {hasATechnician
              ? t('evaluationRequest.update.offloadRequest')
              : t('evaluationRequest.update.takeChargeOfRequest')}
          </Button>
        ) : null}

        {values.statusId === StatusEnum.DRAFT &&
        values.applicantId === user.id ? (
          <Button
            danger
            type="primary"
            id="delete-button"
            data-test-id="delete-button"
            loading={isLoading}
            onClick={onDelete}
          >
            {t('evaluationRequest.update.delete')}
          </Button>
        ) : null}

        {/* Apply updates */}
        {(values.statusId && values.statusId === StatusEnum.DRAFT) ||
        values.statusId === StatusEnum.WAITING_VALIDATION ||
        values.statusId === StatusEnum.IN_PROGRESS ? (
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            loading={isLoading}
            onClick={submitForm}
          >
            {t('evaluationRequest.update.submit')}
          </Button>
        ) : null}
        {values.statusId && values.statusId === StatusEnum.DRAFT ? (
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            loading={isLoading}
            onClick={() => {
              setFieldValue('statusId', StatusEnum.WAITING_VALIDATION);
              submitForm();
            }}
          >
            {t('evaluationRequest.update.submitAndSend')}
          </Button>
        ) : null}
      </div>
      <ScheduleEvaluationRequestModal
        isOpen={isScheduleModalVisible}
        onCancel={() => setIsScheduleModalVisible(false)}
        onSubmit={(date) => {
          setFieldValue('estimatedEndDate', date);
          setIsScheduleModalVisible(false);
          handleStatusUpdate();
        }}
      />
    </>
  );
};

export default UpdateFormActions;
