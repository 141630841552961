import React from 'react';
import ForgotPasswordCard from '../../components/forgotPasswordCard';
import BackGroundImage from '../../../../ui/backgroundImage';
import loginBG from '../../../../assets/loginBG.png';
import PageAuth from '../pageAuth';

const ForgotPasswordPage: React.FC = () => {
  return (
    <PageAuth>
      <BackGroundImage image={loginBG} />
      <ForgotPasswordCard />
    </PageAuth>
  );
};

export default ForgotPasswordPage;
