import axios from 'axios';
import { Pagination } from 'technical/hooks/usePagination';
import {
  PaginatedBenchPrograms,
  PaginatedBenchProgramsWithNItems,
} from './types';

export const fetchBenchPrograms = async (
  stepId?: number,
  version?: number,
): Promise<PaginatedBenchPrograms> => {
  return (
    await axios.get('/v1/bench-programs', { params: { stepId, version } })
  ).data;
};

export const fetchAllBenchPrograms = async (
  page: Pagination,
): Promise<PaginatedBenchProgramsWithNItems> => {
  return (
    await axios.get(
      `/v1/bench-program?offset=${(page.current - 1) * page.pageSize}&limit=${
        page.pageSize
      }`,
    )
  ).data;
};
