import React from 'react';
import classnames from 'classnames';

import styles from './index.module.scss';

interface BackGroundImageProps {
  image: string;
}

const BackGroundImage: React.FC<BackGroundImageProps> = ({ image }) => {
  return (
    <div className={classnames(styles.common, styles.container)}>
      <div
        className={styles.common}
        style={{ backgroundSize: 'cover', backgroundImage: `url(${image})` }}
      />
      <div className={classnames(styles.common, styles.colorLayer)} />
    </div>
  );
};

export default BackGroundImage;
