import { AdvancedFormValues } from '../types';
import { EvaluationStepFormValues } from '../../evaluationStep/types';
import { SerieFormValues } from '../../serie/types';

export const evaluationStepsAddOrder = (steps?: EvaluationStepFormValues[]) => {
  return (
    steps?.map((step, index) => ({
      ...step,
      order: index + 1,
    })) ?? []
  );
};

export const seriesAddSerieNumber = (series?: SerieFormValues[]) => {
  return (
    series?.map((serie, index) => ({
      ...serie,
      serieNumber: index + 1,
    })) ?? []
  );
};

/**
 * format eval request to post by adding missing order in advancedEvaluationRequestFrontFrontToBack
 * @param evaluationRequest
 */
export const advancedEvaluationRequestFromFrontToBack = (
  evaluationRequest: AdvancedFormValues,
) => {
  return {
    ...evaluationRequest,
    steps: evaluationStepsAddOrder(evaluationRequest.steps),
    series: seriesAddSerieNumber(evaluationRequest.series),
  };
};
