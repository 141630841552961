import * as jsurl from 'jsurl';
import { decode, encode } from 'qss';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

export function stringify({ ...search }: Record<string, unknown>) {
  if (search) {
    Object.keys(search).forEach((key) => {
      const val = search[key];
      if (typeof val === 'undefined' || val === undefined) {
        // eslint-disable-next-line no-param-reassign
        delete search[key];
      } else if (val && typeof val === 'object' && val !== null) {
        try {
          // eslint-disable-next-line no-param-reassign
          search[key] = jsurl.stringify(val);
        } catch (err) {
          // silent
        }
      }
    });
  }

  const searchStr = encode(search as Record<string, string>);

  return searchStr ? `?${searchStr}` : '';
}

export function parse(searchStr: string): Record<string, any> {
  const search = searchStr.startsWith('?') ? searchStr.substring(1) : searchStr;

  const query: Record<string, unknown> = decode(search);

  // Try to parse any query params that might be json
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in query) {
    const value = query[key];
    if (typeof value === 'string') {
      try {
        query[key] = jsurl.parse(value);
      } catch (err) {
        //
      }
    }
  }

  return query;
}

export function useSearchParams<Params extends Record<any, unknown>>(
  guard: (params: Record<any, unknown>) => params is Params,
) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => {
    const parsed = parse(search);
    if (guard(parsed)) {
      return parsed as Params;
    }
    return parsed;
  }, [search]);

  const setParams = useCallback(
    (args: Params | ((old: Params | null) => Params)) => {
      const next = typeof args === 'function' ? args(params as Params) : args;
      const stringified = guard(next) ? stringify(next) : search;
      navigate({ search: stringified }, { replace: true });
    },
    [params, search, navigate],
  );

  return [params as Params, setParams] as const;
}
