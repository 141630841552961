import React, { useState } from 'react';
import AnomalyList from 'business/anomaly/components/anomalyList';
import AuthenticatedHeader from 'business/user/components/authenticatedHeader';
import {
  AuthorizedAccessOnly,
  technicianOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';
import PageLayout from 'ui/PageLayout';
import { useFetchAnomaly } from 'business/anomaly/hooks/queries';
import { TablePaginationConfig } from 'antd';

const AnomalyListPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading } = useFetchAnomaly({
    current: currentPage,
    pageSize,
  });
  const { records: anomalyList, count } = data || {};

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 10);
  };
  return (
    <PageLayout header={<AuthenticatedHeader />}>
      <AuthorizedAccessOnly
        policy={technicianOnly}
        fallback={<NotAuthorized />}
      >
        <AnomalyList
          anomalyList={anomalyList ?? []}
          isLoading={isLoading}
          pagination={{ current: currentPage, pageSize, total: count || 0 }}
          onPaginationChange={onPaginationChange}
        />
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default AnomalyListPage;
