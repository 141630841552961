import React from 'react';
import classNames from 'classnames';

export default function Label({
  required,
  className,
  ...rest
}: { required?: boolean } & React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>) {
  return (
    <div className="ant-form-item-label">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        {...rest}
        className={classNames(
          required ? 'ant-form-item-required' : null,
          className,
        )}
      />
    </div>
  );
}
