import { useFormikContext } from 'formik';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import SelectField from 'technical/form/formik/selectField';
import { RecursiveFormNames } from 'technical/form/formik/types';
import useDebounce from 'technical/hooks/useDebounce';
import {
  useArtsReferenceOptions,
  useProductDesignationOptions,
} from '../hooks/queries';

export interface SearchableSelectProductionDesignationProps<FormValues> {
  readOnly: boolean;
  valueKey: RecursiveFormNames<FormValues>;
}
export function SearchableSelectProductionDesignation<
  FormValues extends object,
>({
  readOnly,
  valueKey,
}: SearchableSelectProductionDesignationProps<FormValues>) {
  const { values } = useFormikContext<FormValues>();
  const [search, setSearch] = useState<string>();
  const debounced = useDebounce(search, 200);

  const id = get(values, valueKey);

  useEffect(
    function resetSearch() {
      setSearch(undefined);
    },
    [id],
  );

  const params = search
    ? { name: debounced }
    : {
        id,
      };

  const { data: productDesignations, isLoading } = useProductDesignationOptions(
    params as any,
  );

  return (
    <SelectField<FormValues>
      valueKey={valueKey}
      optionsList={productDesignations}
      onSearch={(term) => setSearch(term)}
      onSelect={() => setSearch(undefined)}
      filterOption={false}
      readOnly={readOnly}
      allowClear
      loading={isLoading}
      data-test-id={valueKey}
    />
  );
}

export function SearchableSelectArtsReference<FormValues extends object>({
  readOnly,
  valueKey,
}: SearchableSelectProductionDesignationProps<FormValues>) {
  const { values } = useFormikContext<FormValues>();
  const [search, setSearch] = useState<string>();
  const debounced = useDebounce(search, 200);

  const id = get(values, valueKey);

  useEffect(
    function resetSearch() {
      setSearch(undefined);
    },
    [id],
  );

  const params: any = search
    ? {
        referenceArts: debounced,
      }
    : {
        id,
      };

  const { data: artsReferences, isLoading } = useArtsReferenceOptions(params);

  return (
    <SelectField<FormValues>
      valueKey={valueKey}
      optionsList={artsReferences}
      onSearch={(term) => setSearch(term)}
      onSelect={() => setSearch(undefined)}
      filterOption={false}
      readOnly={readOnly}
      allowClear
      loading={isLoading}
    />
  );
}
