import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { useCallback } from 'react';
import { fetchClientSuppliersList } from '../api/fetchClientSuppliersList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';
import { ClientSupplier, PaginatedClientSuppliers, SearchArgs } from '../types';

export const clientSupplierKeys = {
  all: [{ scope: 'clientSupplier' }] as const,
  lists: () => [{ ...clientSupplierKeys.all[0], entity: 'list' }] as const,
  list: (page?: Pagination, search?: SearchArgs) =>
    [{ ...clientSupplierKeys.lists()[0], search, page }] as const,
};

export function useClientSuppliersList<Selected = ClientSupplier[]>({
  select = (data) => data as any,
  page,
  search,
  skip = false,
}: {
  select?: (data: ClientSupplier[]) => Selected;
  page?: Pagination;
  search?: SearchArgs;
  skip?: boolean;
}) {
  const clientSuppliersQuery = useQuery(
    clientSupplierKeys.list(page, search),
    () => fetchClientSuppliersList(page, search),
    {
      select: useCallback(
        (data: PaginatedClientSuppliers) => {
          return select(selectRecords(data));
        },
        [select],
      ),
      placeholderData: emptyPaginatedData,
      keepPreviousData: true,
      enabled: !skip,
    },
  );

  return clientSuppliersQuery;
}

function toClientSupplierOptions(list: ClientSupplier[]) {
  return list.map(({ id, name }) => ({ id, name }));
}

export function useClientSuppliersOptions(search?: SearchArgs) {
  return useClientSuppliersList({
    select: toClientSupplierOptions,
    page: { current: 1, pageSize: 100 },
    search,
    skip: !search,
  });
}
