import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import { Priority, PriorityFormValues } from 'business/priority/types';
import { priorityKeys } from 'business/priority/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';

const config: DataManagementConfig<
  Priority,
  Pick<Priority, 'name' | 'delay'>
> = {
  entity: 'priority',
  hooks: createAdminHooks({
    keys: priorityKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/priority', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post(`/v1/priority`, {
        name: entity.name,
        delay: entity.delay,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/priority/${entity.id}`, {
        name: entity.name,
        delay: entity.delay,
      });
    },
    async del(id) {
      return axios.delete(`/v1/priority/${id}`);
    },
  }),
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<PriorityFormValues>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={20}
            />
          );
        },
      },
      {
        dataIndex: 'delay',
        key: 'delay',
        render: (_value, entity) => {
          return (
            <InputField<PriorityFormValues>
              valueKey="delay"
              type="number"
              min={0}
              readOnly={!permissions.update(entity)}
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(20).required(),
    delay: yup.number().integer().positive().required(),
  }),
  defaultValues: {
    name: '',
    delay: 0,
  },
  permissions: {},
};

export default config;
