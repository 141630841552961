import axios from 'axios';
import { UserAnonymization, UserInvitation } from './types';

export const postUserInvitation = async (params: UserInvitation) => {
  return (
    await axios.post(`/v1/user/${params.id}/invite`, {
      email: params.email,
      roleIds: params.roleIds,
    })
  ).data;
};

export const postRemoveUserAccess = async (params: UserAnonymization) => {
  return (await axios.post(`/v1/user/${params.id}/remove-access`)).data;
};

export const deleteRemoveUser = async (id: number) => {
  return (await axios.delete(`/v1/user/${id}`)).data;
};
