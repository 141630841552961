import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import get from 'lodash.get';
import { format } from 'date-fns';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import DatePicker from '../../../../ui/form/datePicker';
import FieldError from '../fieldError';
import { RecursiveFormNames } from '../types';
import ReadonlyField from '../ReadonlyField';

type FieldProps<T> = Omit<
  PickerProps<Date>,
  'name' | 'onChange' | 'value' | 'id'
> & {
  valueKey: RecursiveFormNames<T>;
  readOnly?: boolean;
};

/**
 * Ant design Datepicker formik controlled
 * we use custom ant datepicker to use date-fns
 * avoid onChange duplication and stupid error like typo in id or onChange
 * @param valueKey
 * @param otherProps
 * @constructor
 */
function DatePickerField<T extends object>({
  valueKey,
  readOnly,
  ...otherProps
}: FieldProps<T>) {
  const { values, setFieldValue, errors, touched } = useFormikContext<T>();

  const value = get(values, valueKey) as any;
  return (
    <>
      {readOnly ? (
        <ReadonlyField value={value ? format(value, 'dd/MM/yyyy') : '-'} />
      ) : (
        <>
          <FormikField
            component={DatePicker}
            id={valueKey}
            name={valueKey}
            value={value}
            format="DD/MM/yyyy"
            onChange={(date: Date | null) =>
              setFieldValue(valueKey as string, date)
            }
            {...otherProps}
          />
          <FieldError<T>
            errors={errors}
            touched={touched}
            valueKey={valueKey}
          />
        </>
      )}
    </>
  );
}

export default DatePickerField;
