import classnames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export default function Card({ children, className, dataTestId }: Props) {
  return (
    <div
      className={classnames(styles.card, className)}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
}
