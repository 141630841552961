import React from 'react';
import { useFormikContext } from 'formik';
import Button from '../../../../ui/button';
import styles from './index.module.scss';
import { StatusEnum } from '../../utils/evaluationRequestStatus';

interface CreateFormActionsProps {
  // actions should probably be passed as react node to allow factorisation between update and create form
  saveDraftButton: {
    label: string;
  };
  submitButton: {
    label: string;
  };
  cancelButton: {
    label: string;
  };
  isLoading: boolean;
}

const CreateFormActions: React.FC<CreateFormActionsProps> = ({
  cancelButton,
  saveDraftButton,
  submitButton,
  isLoading,
}) => {
  const { resetForm, submitForm, setFieldValue } = useFormikContext();
  return (
    <>
      <Button
        id="cancel-button"
        danger
        type="primary"
        loading={isLoading}
        onClick={() => resetForm()}
      >
        {cancelButton.label}
      </Button>
      <div className={styles.saveContainer}>
        <Button
          id="save-button"
          data-test-id="save-button"
          type="primary"
          loading={isLoading}
          onClick={() => {
            setFieldValue('statusId', StatusEnum.DRAFT);
            submitForm();
          }}
        >
          {submitButton.label}
        </Button>
        <Button
          id="submit-button"
          data-test-id="submit-button"
          type="primary"
          loading={isLoading}
          onClick={() => {
            setFieldValue('statusId', StatusEnum.WAITING_VALIDATION);
            submitForm();
          }}
        >
          {saveDraftButton.label}
        </Button>
      </div>
    </>
  );
};

export default CreateFormActions;
