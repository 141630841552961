import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchPrioritiesList } from '../api/fetchPrioritiesList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';

export const priorityKeys = {
  all: [{ scope: 'priority' }] as const,
  lists: () => [{ ...priorityKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...priorityKeys.lists()[0], search, page }] as const,
};

export const usePrioritiesList = ({ skip = false }: { skip?: boolean }) => {
  const prioritiesQuery = useQuery(priorityKeys.lists(), fetchPrioritiesList, {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
    enabled: !skip,
  });

  return prioritiesQuery;
};
