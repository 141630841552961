import { Values } from 'technical/types';

export const RoleEnum = {
  Admin: 'Administrateur',
  Technician: 'Technicien',
  Applicant: 'Demandeur',
  Manager: 'Responsable',
} as const;

export type ValidRole = Values<typeof RoleEnum>;

export interface Role {
  id: number;
  name: ValidRole;
}

export interface User {
  firstname: string | null;
  lastname: string | null;
  id: number;
  roles: Role[];
  email: string;
  service: string | null;
  kelioId: string | null;
}

export interface StrictUser {
  firstname: string | null;
  lastname: string | null;
  id: number;
  roleIds: number[];
  email: string;
  service: string | null;
  kelioId: string | null;
}

export interface AugmentedUser {
  firstname: string | null;
  lastname: string | null;
  fullname: string;
  initials?: string;
  id: number;
  roles: Role[];
  email: string;
  service: string | null;
  kelioId: string | null;
}

export interface UserFormValues {
  id?: number;
  firstname: string | null;
  lastname: string | null;
  roleIds: number[];
  email: string;
  service: string | null;
  kelioId: string | null;
}

export type SearchArgs = Partial<
  Pick<StrictUser, 'firstname' | 'lastname' | 'email'>
> | null;
