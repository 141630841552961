import { emptyPaginatedData } from 'technical/api/constants';
import { selectRecords } from 'technical/selects/selectRecords';
import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchMeasureTypes } from '../api';

export const measureTypeKeys = {
  all: [{ scope: 'measureTypes' }] as const,
  lists: () => [{ ...measureTypeKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination) =>
    [{ ...measureTypeKeys.lists()[0], page }] as const,
};

export const useFetchMeasureTypes = () => {
  return useQuery(measureTypeKeys.lists(), fetchMeasureTypes, {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
  });
};
