import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Header from '../../../../ui/header';
import { useAuthenticatedUser } from '../../services/queries';
import { useAuthentication } from '../../services/actions';
import {
  authenticatedMenuItems,
  MenuKeyEnum,
} from '../../../applicant/constants/menu';
import { getPageTradKey } from '../../../../technical/translate/utils/getPageTradKey';
import { Values } from '../../../../technical/types';

interface AuthenticatedHeaderProps {
  selectedKeys?: Values<typeof MenuKeyEnum>[];
}

const AuthenticatedHeader: React.FC<AuthenticatedHeaderProps> = ({
  selectedKeys,
}) => {
  const me = useAuthenticatedUser();
  const { signOut } = useAuthentication();
  const { t } = useTranslation();

  const filteredMenuItems = authenticatedMenuItems
    .filter(({ policy }) => policy(me))
    .map((menuItem) => ({
      ...menuItem,
      label: t(getPageTradKey(menuItem.key)),
      children: <Link to={menuItem.path} />,
    }));

  return (
    <Header
      user={me}
      menuItems={filteredMenuItems}
      onSignOut={signOut}
      selectedKeys={selectedKeys}
    />
  );
};

export default AuthenticatedHeader;
