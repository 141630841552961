import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import Button from 'ui/button';
import CreateAnomaly from '../createAnomaly';

interface AnomalyCreationButtonProps {
  evaluationRequest: EvaluationRequest;
  version: number;
}

const AnomalyCreationButton: React.FC<AnomalyCreationButtonProps> = ({
  evaluationRequest,
  version,
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button danger onClick={() => setVisible(true)}>
        {t('evaluationRequest.creation.measures.reportAnomaly')}
      </Button>
      <CreateAnomaly
        evaluationRequest={evaluationRequest}
        version={version}
        visible={visible}
        cancel={() => setVisible(false)}
      />
    </>
  );
};

export default AnomalyCreationButton;
