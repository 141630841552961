import React from 'react';
import { Tag, TagProps } from 'antd';
import { StatusEnum } from '../../utils/evaluationRequestStatus';

/**
 * key : id of the status
 * value : severity
 */
const statusColor: { [key: number]: TagProps['color'] } = {
  [StatusEnum.DRAFT]: 'processing', // brouillon
  [StatusEnum.WAITING_VALIDATION]: 'error', // en attente de validation
  [StatusEnum.PENDING]: 'error', // en attente de traitement
  [StatusEnum.IN_PROGRESS]: undefined, // en cours
  [StatusEnum.DONE]: 'success', // terminee
  [StatusEnum.CANCELED]: undefined, // annulee
};

export interface EvaluationStatusTagProps {
  status: {
    id: number;
    name: string;
  };
}

const EvaluationStatusTag: React.FC<EvaluationStatusTagProps> = ({
  status,
}) => {
  const color = statusColor[status.id];

  return <Tag color={color}>{status.name}</Tag>;
};

export default EvaluationStatusTag;
