import classnames from 'classnames';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  className?: string;
}

export default function DraggableBodyRow({
  index,
  moveRow,
  className,
  ...rest
}: Props) {
  const type = 'DraggableBodyRow';
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? 'drop-over-downward' : 'drop-over-upward',
      };
    },
    drop: (item: { index: number }) => moveRow(item.index, index),
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={classnames(
        styles.draggableBodyRow,
        className,
        isOver ? dropClassName : '',
      )}
      {...rest}
    />
  );
}
