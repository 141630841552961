import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Typography } from 'antd';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAuthenticatedUser } from 'business/user/services/queries';
import SimpleCreateEvaluationRequestForm from '../simpleCreationForm';
import { FormValues, preValidationEditableFields } from '../../types';
import { StatusEnum } from '../../utils/evaluationRequestStatus';
import Routes from '../../../router/routes';
import EvaluationStatusTag from '../evaluationRequestStatus';
import styles from './index.module.scss';
import CreateFormActions from '../createFormActions';
import AdvancedCreationForm from '../advancedCreationForm';
import { advancedEvaluationRequestFromFrontToBack } from '../../utils/advancedEveluationRequestFromFrontToBack';
import { generateLocalId } from '../../../../technical/utils/generateLocalId';
import { emptyFormSerie } from '../advancedCreationForm/seriesFieldArray';
import { emptyFormEvaluationStep } from '../evaluationStepsCreationForm/EvaluationStepsFieldArray';
import {
  simpleSchema,
  advancedSchema,
} from '../../schema/evaluationRequestSchema';
import { useCreateEvaluationRequest } from '../../hooks/actions';

const { TabPane } = Tabs;

const initialValues = {
  statusId: StatusEnum.DRAFT,
  // required
  priorityId: null,
  categoryId: null,
  clientSupplierId: null,
  description: null,
  subject: null,
  toTestQuantity: null,

  projectNumber: null,
  productDesignationId: null,
  lotNumber: null,

  commandNumber: null,
  orderedQuantity: null,
  BRNumber: null,
  purchaseOrder: null,
  qualityPlanNumber: null,

  // only advanced
  technologyId: null,
  productId: null,
  requestDate: null,
  nominalCapacity: null,
  series: [], // [generateLocalId(emptyFormSerie)],
  steps: [], // [generateLocalId(emptyFormEvaluationStep)],
};

enum FormTabs {
  simple = 'simple-form',
  advanced = 'advanced-form',
}

const validationSchemas = {
  [FormTabs.simple]: simpleSchema,
  [FormTabs.advanced]: advancedSchema,
};

interface CreationFormTabsProps {
  evaluationRequest?: FormValues;
}

const CreationFormTabs: React.FC<CreationFormTabsProps> = ({
  evaluationRequest,
}) => {
  const [currentTab, setCurrentTab] = useState<FormTabs>(
    evaluationRequest?.type
      ? FormTabs[evaluationRequest.type]
      : FormTabs.simple,
  );
  const [requestError, setRequestError] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const me = useAuthenticatedUser();
  const userName = me.fullname;

  const { createSimple, createAdvanced } = useCreateEvaluationRequest({
    setRequestError,
    onSuccess: () => navigate(Routes.EvaluationRequests),
  });

  // todo should fetch tag using id 1 to get trad
  const draftTag = {
    id: StatusEnum.DRAFT,
    name: t('evaluationRequest.creation.draft'),
  };

  const isLoading = createSimple.isLoading || createAdvanced.isLoading;

  const actions = useMemo(
    () => (
      <CreateFormActions
        submitButton={{ label: t('evaluationRequest.creation.submit') }}
        saveDraftButton={{ label: t('evaluationRequest.creation.saveDraft') }}
        cancelButton={{ label: t('evaluationRequest.creation.cancel') }}
        isLoading={isLoading}
      />
    ),
    [isLoading],
  );

  const tabs = [
    {
      key: FormTabs.simple,
      label: t('evaluationRequest.creation.simpleTab.title'),
      component: (
        <SimpleCreateEvaluationRequestForm
          actions={actions}
          error={{ key: 'common.form.genericFormError', context: requestError }}
          userName={userName}
          editableFields={preValidationEditableFields}
        />
      ),
    },
    {
      key: FormTabs.advanced,
      label: t('evaluationRequest.creation.advancedTab.title'),
      component: (
        <AdvancedCreationForm
          actions={actions}
          error={{
            key: 'common.form.genericFormError',
            context: requestError,
          }}
          userName={userName}
          editableFields={preValidationEditableFields}
        />
      ),
    },
  ];

  const onSubmit = (values: FormValues) => {
    if (currentTab === FormTabs.simple) {
      createSimple.mutate(values);
    } else if (currentTab === FormTabs.advanced) {
      createAdvanced.mutate(advancedEvaluationRequestFromFrontToBack(values));
    }
  };

  return (
    <Formik<FormValues>
      initialValues={evaluationRequest || initialValues}
      validationSchema={validationSchemas[currentTab]}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => {
        useEffect(
          function resetSeriesAndSteps() {
            if (currentTab === FormTabs.advanced) {
              setFieldValue(
                'series',
                values.series ?? [generateLocalId(emptyFormSerie)],
              );
              setFieldValue(
                'steps',
                values.steps ?? [generateLocalId(emptyFormEvaluationStep)],
              );
            }
            if (currentTab === FormTabs.simple) {
              setFieldValue('series', null);
              setFieldValue('steps', null);
            }
          },
          [currentTab],
        );

        return (
          <>
            <div className={styles.titleContainer}>
              <Typography.Title className={styles.title} level={4}>
                {t('evaluationRequest.creation.title')}
              </Typography.Title>
              <EvaluationStatusTag status={draftTag} />
            </div>

            <Tabs
              defaultActiveKey={FormTabs.simple}
              activeKey={currentTab}
              onChange={(tabKey) => setCurrentTab(tabKey as FormTabs)}
              destroyInactiveTabPane
            >
              {tabs.map(({ key, label, component }) => (
                <TabPane tab={label} key={key}>
                  {component}
                </TabPane>
              ))}
            </Tabs>
          </>
        );
      }}
    </Formik>
  );
};

export default CreationFormTabs;
