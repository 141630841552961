import axios from 'axios';
import { PaginatedMeasures, MeasurePostValue } from './types';

export const fetchMeasures = async ({
  stepId,
  cycle,
  version,
  measureTypeId,
}: {
  stepId: number;
  cycle?: number;
  version: number;
  measureTypeId?: number;
}): Promise<PaginatedMeasures> => {
  return (
    await axios.get(
      `/v1/measures?stepId=${stepId}&cycle=${cycle}&version=${version}&measureTypeId=${measureTypeId}`,
    )
  ).data;
};

export const insertMeasures = async ({
  values,
}: {
  values: MeasurePostValue[];
}) => {
  return (await axios.post('/v1/measures', values)).data;
};
