import * as React from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik';
import Spacer from 'ui/spacer';
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import groupBy from 'lodash.groupby';
import Flex from 'ui/flex';
import SelectField from 'technical/form/formik/selectField';
import { filterFormSchema } from 'business/evaluationRequest/schema/filterFormSchema';
import { useEvaluationRequestsGlobalSearch } from '../../hooks/queries';
import {
  FILTERS,
  Filters,
  TextFilters,
  IdFilters,
  RangeFilters,
  FilterDefinitions,
  FormikFilterRow,
} from './searchFilter/types';
import SearchInput from './searchInput';

type FormFilter = {
  filters: FormikFilterRow[];
};

const SearchForm: React.FC = () => {
  const { t } = useTranslation();
  const [params, handlers] = useEvaluationRequestsGlobalSearch();

  const searchOptions = React.useMemo(() => {
    const options = FILTERS.map((key) => ({
      id: key,
      name: t(`evaluation.search.filterBy.${key}`),
    })).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return options;
  }, [t]);

  const formSubmit = (values: FormFilter) => {
    const grouped = groupBy(values.filters, (element) => element.by);

    const data: Partial<FilterDefinitions> = {};

    FILTERS.forEach((group) => {
      const filters = grouped[group];
      if (!filters) {
        return;
      }
      const list = filters.map(
        ({ search }) =>
          (typeof search === 'object' && search !== null
            ? { start: search.start.toJSON(), end: search.end.toJSON() }
            : // WARNING: here we cast as any because i do not know how to make typescript happy with this
              search) as any,
      );
      data[group] = list;
    });

    handlers.onSearchChange(data);
  };

  return (
    <Spacer direction="vertical" size="small">
      <Spacer direction="vertical" size="small">
        <Row gutter={16}>
          <Col span={4}>{t('evaluation.search.filter.by')}</Col>
          <Col span={6}>{t('evaluation.search.filter.search')}</Col>
        </Row>

        <Formik<FormFilter>
          initialValues={{
            filters: Object.entries(params).flatMap(([by, searches], i) =>
              searches.map((search, j) => {
                const id = (i + 1) * (j + 1);
                switch (by as Filters) {
                  case 'reference':
                  case 'project':
                  case 'subject':
                  case 'qualityPlanNumber':
                  case 'orderedQuantity':
                  case 'BRNumber':
                  case 'lotNumber':
                  case 'commandNumber':
                  case 'purchaseOrder':
                  case 'description':
                  case 'category':
                  case 'comments':
                  case 'conclusions':
                    return {
                      id,
                      by: by as TextFilters,
                      search: search as string,
                    };
                  case 'requestDate':
                  case 'maxStartDate':
                  case 'startDate':
                  case 'endDate':
                  case 'estimatedEndDate':
                  case 'intermediateExitDate':
                    if (typeof search !== 'object' || search === null) {
                      throw new Error(`search should be an object`);
                    }
                    return {
                      id,
                      by: by as RangeFilters,
                      search: {
                        start: new Date(search.start),
                        end: new Date(search.end),
                      },
                    };
                  case 'status':
                  case 'applicant':
                  case 'technician':
                  case 'priority':
                  case 'nominalCapacity':
                  case 'technology':
                  case 'product':
                  case 'clientSupplier':
                    return {
                      id,
                      by: by as IdFilters,
                      search: search as number,
                    };
                  case 'productDesignation':
                  case 'artsReference':
                    return {
                      id,
                      by: by as TextFilters,
                      search: search as string,
                    };
                  default:
                    throw new Error(`filter '${by}' is not supported`);
                }
              }),
            ),
          }}
          onSubmit={formSubmit}
          validationSchema={filterFormSchema}
        >
          {({ values }) => (
            <Form>
              <Spacer direction="vertical" size="small">
                <FieldArray name="filters">
                  {({ push, remove }: FieldArrayRenderProps) => (
                    <>
                      {values.filters.map((filter, index) => (
                        <Row gutter={16} key={filter.id}>
                          <Col span={4}>
                            <SelectField<FormFilter>
                              size="middle"
                              defaultValue={filter.by}
                              valueKey={`filters.${index}.by`}
                              optionsList={searchOptions}
                              style={{ width: '100%' }}
                            />
                          </Col>
                          <Col span={6}>
                            <Flex style={{ gap: 4 }}>
                              <Flex column style={{ gap: 4, width: '100%' }}>
                                <SearchInput<FormFilter>
                                  filterBy={filter.by}
                                  name={`filters.${index}.search`}
                                  defaultValue={filter.search}
                                />
                              </Flex>
                              <Button
                                type="text"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                size="middle"
                                onClick={() => remove(index)}
                              />
                            </Flex>
                          </Col>
                        </Row>
                      ))}
                      <Spacer direction="horizontal" size="small">
                        <Button
                          icon={<PlusOutlined />}
                          size="middle"
                          onClick={() =>
                            push({
                              by: 'reference',
                              search: undefined,
                              id: Date.now(),
                            })
                          }
                        >
                          {t('evaluation.search.addFilter')}
                        </Button>
                        <Button
                          type="primary"
                          icon={<SearchOutlined />}
                          size="middle"
                          htmlType="submit"
                        >
                          {t('evaluation.search.action')}
                        </Button>
                      </Spacer>
                    </>
                  )}
                </FieldArray>
              </Spacer>
            </Form>
          )}
        </Formik>
      </Spacer>
    </Spacer>
  );
};

export default SearchForm;
