import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function PageAuth({ children }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.env}>
        {t('env', { context: process.env.REACT_APP_ENV })}
      </div>
      {children}
    </div>
  );
}
