import { notification } from 'antd';
import Routes from 'business/router/routes';
import { useAuthenticatedUser } from 'business/user/services/queries';
import { RoleEnum } from 'business/user/types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  completeEvaluationRequest,
  createAdvancedEvaluationsRequest,
  createSimpleEvaluationsRequest,
  deleteEvaluationRequest,
  scheduleEvaluationRequest,
  updateAdvancedEvaluationsRequest,
  updateEvaluationRequestStatus,
  updateSimpleEvaluationsRequest,
} from '../api';
import { evaluationRequestKeys } from './queries';

export const useUpdateEvaluationRequestStatus = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(updateEvaluationRequestStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(evaluationRequestKeys.detail(id));

      notification.success({
        message: t('evaluationRequest.review.statusUpdate.success'),
      });
    },
    onError: () => {
      notification.error({
        message: t('evaluationRequest.review.statusUpdate.error'),
      });
    },
  });
};

export const useScheduleEvaluationRequest = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(scheduleEvaluationRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(evaluationRequestKeys.detail(id));

      notification.success({
        message: t('evaluationRequest.review.statusUpdate.success'),
      });
    },
    onError: () => {
      notification.error({
        message: t('evaluationRequest.review.statusUpdate.error'),
      });
    },
  });
};

export const useCompleteEvaluationRequest = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(completeEvaluationRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(evaluationRequestKeys.detail(id));

      notification.success({
        message: t('evaluationRequest.review.statusUpdate.success'),
      });
    },
    onError: () => {
      notification.error({
        message: t('evaluationRequest.review.statusUpdate.error'),
      });
    },
  });
};

export const useCreateEvaluationRequest = ({
  setRequestError,
  onSuccess,
}: {
  onSuccess?: () => void;
  setRequestError: (error: string) => void;
}) => {
  const queryClient = useQueryClient();

  const queryOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(evaluationRequestKeys.all);
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      setRequestError('common.form.genericFormError');
    },
  };
  const createSimple = useMutation(
    createSimpleEvaluationsRequest,
    queryOptions,
  );
  const createAdvanced = useMutation(
    createAdvancedEvaluationsRequest,
    queryOptions,
  );

  return { createSimple, createAdvanced };
};

export const useUpdateEvaluationRequest = ({
  setRequestError,
}: {
  setRequestError: (error: string) => void;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const queryOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(evaluationRequestKeys.all);
      notification.success({
        message: t('evaluationRequest.update.success'),
      });
    },
    onError: () => {
      setRequestError('common.form.genericFormError');
      notification.error({
        message: t('evaluationRequest.update.error'),
      });
    },
  };

  const updateSimple = useMutation(
    updateSimpleEvaluationsRequest,
    queryOptions,
  );
  const updateAdvanced = useMutation(
    updateAdvancedEvaluationsRequest,
    queryOptions,
  );

  return { updateSimple, updateAdvanced };
};

export const useRemoveEvaluationRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const me = useAuthenticatedUser();

  const queryOptions = {
    onSuccess: () => {
      notification.success({ message: t('toast.deletion.success') });

      const isManager = me.roles.find((role) => role.name === RoleEnum.Manager);
      navigate(
        isManager ? Routes.WaitingValidation : Routes.EvaluationRequests,
      );

      queryClient.invalidateQueries(evaluationRequestKeys.all);
    },
    onError: () => notification.error({ message: t('toast.deletion.error') }),
  };

  const removeEvaluationRequest = useMutation(
    deleteEvaluationRequest,
    queryOptions,
  );

  return { removeEvaluationRequest };
};
