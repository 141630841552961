import { useMemo, useState } from 'react';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useSearchParams } from './useSearchParams';

// const defaultPagination = { limit: 10, offset: 0 };
export interface Pagination {
  pageSize: number;
  current: number;
}

const defaultPagination = { pageSize: 10, current: 1 };

function guard(params: Record<any, unknown>): params is { p: Pagination } {
  if ('p' in params) {
    const page = params.p as Pagination;
    if (typeof page.current === 'number' && typeof page.pageSize === 'number') {
      return true;
    }
  }
  return false;
}
/**
 * hold pagination state and expose function to update page state.
 * onPage change convert page data used by ant to param used by our API
 * @param initialPagination
 */
export function usePagination(initialPagination = defaultPagination) {
  const [params, setParams] = useSearchParams<{ p: Pagination }>(guard);

  const handlers = useMemo(() => {
    return {
      onPageChange(page: TablePaginationConfig) {
        setParams((old) => ({
          ...old,
          p: {
            current: page.current ?? initialPagination.current,
            pageSize: page.pageSize ?? initialPagination.pageSize,
          },
        }));
      },
    };
  }, [setParams]);

  return [params?.p ?? initialPagination, handlers] as const;
}

export function usePaginationState(initialPagination = defaultPagination) {
  const [pagination, setPagination] = useState(initialPagination);

  const handlers = useMemo(() => {
    return {
      onPageChange(page: TablePaginationConfig) {
        setPagination({
          current: page.current ?? initialPagination.current,
          pageSize: page.pageSize ?? initialPagination.pageSize,
        });
      },
    };
  }, [setPagination]);

  return [pagination, handlers] as const;
}
