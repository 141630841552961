import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchEvaluationsList } from '../api/fetchEvaluationList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';

export const evaluationKeys = {
  all: [{ scope: 'evaluation' }] as const,
  lists: () => [{ ...evaluationKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...evaluationKeys.lists()[0], search, page }] as const,
};

export const useEvaluationsList = () => {
  const prioritiesQuery = useQuery(
    evaluationKeys.lists(),
    fetchEvaluationsList,
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
    },
  );

  return prioritiesQuery;
};
