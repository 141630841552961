import React from 'react';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import PageLayout from '../../../../ui/PageLayout';
import CreationFormTabs from '../../components/CreationFormsTabs';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import {
  AuthorizedAccessOnly,
  applicantOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'newEvaluationRequest';
const pagesBreadcrumb = ['home', 'evaluationRequest', currentPage];

const NewEvaluationRequest: React.FC = () => {
  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);

  return (
    <PageLayout
      header={
        <AuthenticatedHeader selectedKeys={[MenuKeyEnum.evaluationRequests]} />
      }
      breadcrumbSections={breadcrumbSections}
    >
      <AuthorizedAccessOnly policy={applicantOnly} fallback={<NotAuthorized />}>
        <CreationFormTabs />
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default NewEvaluationRequest;
