import React, { CSSProperties } from 'react';
import MenuLink from 'ui/menu/link';
import appLogo from 'config/appLogo.png'; // TODO ask real svg and replace png, current svg is not working properly
import classNames from 'classnames';
import styles from './index.module.scss';

interface AppLogoProps {
  height?: string;
  path?: string;
  clickable?: boolean;
  style?: CSSProperties;
  className?: string;
}

export default function AppLogo({
  path = '/',
  clickable,
  className,
  ...otherProps
}: AppLogoProps) {
  return clickable ? (
    <MenuLink path={path}>
      <img
        className={classNames(styles.appLogo, className)}
        src={appLogo}
        alt="App logo"
        {...otherProps}
      />
    </MenuLink>
  ) : (
    <img
      className={classNames(styles.appLogo, className)}
      src={appLogo}
      alt="App logo"
      {...otherProps}
    />
  );
}
