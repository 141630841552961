import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Card from 'ui/card';
import { Typography } from 'antd';
import Button from 'ui/button';
import { useNavigate } from 'react-router';
import { resetPassword } from '../../api';
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../resetPasswordForm';
import Routes from '../../../router/routes';

interface Props {
  className?: string;
  token: string;
}

export default function ResetPasswordCard({ className, token }: Props) {
  const [requestError, setRequestError] = useState<string>();
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resetPasswordMutation = useMutation(
    ({ newPassword, confirmationPassword }: ResetPasswordFormValues) =>
      resetPassword({ newPassword, token, confirmationPassword }),
  );
  const title = (
    <Typography.Title level={4}>
      {t('notConnected.resetPassword.title')}
    </Typography.Title>
  );

  if (resetPasswordMutation.isSuccess) {
    return (
      <Card className={classnames(className)}>
        {title}
        <Typography.Paragraph>
          {t('notConnected.resetPassword.successText')}
        </Typography.Paragraph>
        <Button type="primary" onClick={() => navigate(Routes.SignIn)}>
          {t('notConnected.resetPassword.returnToSignIn')}
        </Button>
      </Card>
    );
  }
  return (
    <Card className={classnames(className)}>
      {title}
      <Typography.Paragraph>
        {t('notConnected.resetPassword.instructions')}
      </Typography.Paragraph>
      <ResetPasswordForm
        formSubmit={(values) => {
          resetPasswordMutation.mutate(values, {
            onSuccess: () => {
              setRequestError(undefined);
              setShowRedirect(false);
            },
            onError: (error: any) => {
              const msg = error.response?.data?.error?.message || '';
              if (msg.search('the token is invalid') > -1) {
                setRequestError(
                  t('notConnected.resetPassword.errors.invalidToken'),
                );
                setShowRedirect(true);
              } else {
                setRequestError(t('common.form.genericFormError'));
              }
            },
          });
        }}
        submitButton={{
          label: t('notConnected.resetPassword.submit'),
        }}
        isLoading={resetPasswordMutation.isLoading}
        error={requestError}
        redirectToForgotPwd={showRedirect}
      />
    </Card>
  );
}
