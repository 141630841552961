import React from 'react';
import styles from './index.module.scss';

interface ReadonlyFieldProps {
  value: string;
}

const ReadonlyField: React.FC<ReadonlyFieldProps> = ({ value }) => {
  return <span className={styles.readonlyField}>{value || '-'}</span>;
};

export default ReadonlyField;
