import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Form as AntForm } from 'antd';
import classnames from 'classnames';
import Routes from 'business/router/routes';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import InputError from 'ui/form/inputError';
import * as Yup from 'yup';
import PasswordInput from '../../../../ui/form/passwordInput';

export interface ResetPasswordFormValues {
  newPassword: string;
  confirmationPassword: string;
}

const initialValues = {
  newPassword: '',
  confirmationPassword: '',
};

const forgotPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'common.form.validation.minLength')
    .required('common.form.validation.required'), // todo ask spec for password policy
  confirmationPassword: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'common.form.validation.notEqualPassword',
    )
    .required('common.form.validation.required'), // todo add same as password
});

interface Props {
  submitButton: {
    label: string;
  };
  className?: string;
  formSubmit: (values: ResetPasswordFormValues) => void;
  isLoading: boolean;
  error?: string;
  redirectToForgotPwd: boolean;
}

export default function ResetPasswordForm({
  submitButton,
  formSubmit,
  isLoading,
  className,
  error,
  redirectToForgotPwd,
}: Props) {
  const { t } = useTranslation();

  return (
    <Formik<ResetPasswordFormValues>
      initialValues={initialValues}
      onSubmit={formSubmit}
      validationSchema={forgotPasswordSchema}
    >
      {({ submitForm, values, setFieldValue, errors, touched }) => (
        <AntForm layout="vertical" className={classnames(className)}>
          <Spacer direction="vertical" align="center">
            <Spacer direction="vertical" size="small">
              <AntForm.Item>
                <Field
                  label={t('notConnected.resetPassword.newPassword')}
                  component={PasswordInput}
                  name="newPassword"
                  data-test-id="password-input"
                  id="newPassword"
                  type="password"
                  value={values.newPassword}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('newPassword', value)
                  }
                />
                {errors.newPassword && touched.newPassword ? (
                  <InputError error={t(errors.newPassword)} />
                ) : null}
              </AntForm.Item>

              <AntForm.Item>
                <Field
                  label={t('notConnected.resetPassword.confirmPassword')}
                  component={PasswordInput}
                  name="confirmationPassword"
                  data-test-id="confirmationPassword-input"
                  id="confirmationPassword"
                  type="password"
                  value={values.confirmationPassword}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('confirmationPassword', value)
                  }
                />
                {errors.confirmationPassword && touched.confirmationPassword ? (
                  <InputError error={t(errors.confirmationPassword)} />
                ) : null}
              </AntForm.Item>

              <InputError error={error} />
              {redirectToForgotPwd && (
                <Link color="tundora" to={Routes.ForgotPassword}>
                  {t('notConnected.resetPassword.returnToForgotPwd')}
                </Link>
              )}
            </Spacer>
            <Button
              id="submit-button"
              data-test-id="submit-button"
              type="primary"
              onClick={submitForm}
              block
              loading={isLoading}
            >
              {submitButton.label}
            </Button>
          </Spacer>
        </AntForm>
      )}
    </Formik>
  );
}
