import PageAuth from 'business/user/pages/pageAuth';
import React from 'react';
import LoginCard from 'business/user/components/loginCard';
import BackGroundImage from 'ui/backgroundImage';
import loginBG from '../../../../assets/loginBG.png';

function SignInPage() {
  return (
    <PageAuth>
      <BackGroundImage image={loginBG} />

      <LoginCard />
    </PageAuth>
  );
}

export default SignInPage;
