import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import invariant from 'tiny-invariant';
import * as api from '../api';
import { AugmentedUser, RoleEnum, SearchArgs, User, ValidRole } from '../types';

export const userKeys = {
  all: [{ scope: 'users' }] as const,
  lists: () => [{ ...userKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search?: SearchArgs) =>
    [{ ...userKeys.lists()[0], search, page }] as const,
  details: () => [{ ...userKeys.all[0], entity: 'detail' }] as const,
  detail: (id: number) => [{ ...userKeys.details()[0], id }] as const,
  me: () => [{ ...userKeys.details()[0], id: 'me' }] as const,
};

export function useWaitingForAuthenticatedUser() {
  const queryClient = useQueryClient();
  const { data } = useQuery(userKeys.me(), api.getAuthenticatedUser, {
    suspense: true,
    useErrorBoundary: false /* refetchOnWindowFocus: true */,
    onError() {
      queryClient.resetQueries({
        predicate(query) {
          const key = (query.queryKey[0] as any) ?? {};
          return key.id !== 'me';
        },
      });
      // TODO: add error toast
    },
  });

  return data ?? null;
}

export function augmentUser(user: User): AugmentedUser {
  const initials =
    [user.firstname?.charAt(0), user.lastname?.charAt(0)]
      .filter(Boolean)
      .join('') || undefined;

  const fullname =
    [user.firstname, user.lastname].filter(Boolean).join(' ') || user.email;

  return {
    ...user,
    initials,
    fullname,
  };
}

export const useAuthenticatedUser = () => {
  const { data: user } = useQuery(userKeys.me(), api.getAuthenticatedUser, {
    select: augmentUser,
  });
  invariant(user, 'you should only use this in AuthenticatedApp context.');
  return user;
};

function byRoles(roles: Array<ValidRole>) {
  return function filtered(user: User) {
    return Boolean(user.roles.find(({ name }) => roles.includes(name)));
  };
}

/**
 * The roles param should be a stable array of ValidRoles (useMemo) to prevent useless rerender
 */
export function useUsers<Selected = AugmentedUser[]>(
  roles?: Array<ValidRole>,
  select: (users: AugmentedUser[]) => Selected = (users) => users as any,
  skip: boolean = false,
) {
  const filterUsersByRole = useCallback(
    (data: Awaited<ReturnType<typeof api.getUsers>>) => {
      if (!roles) {
        return select(data.records.map(augmentUser));
      }
      return select(data.records.filter(byRoles(roles)).map(augmentUser));
    },
    [roles, select],
  );
  const query = useQuery(userKeys.lists(), api.getUsers, {
    select: filterUsersByRole,
    enabled: !skip,
  });

  return query;
}

export function useApplicants<Selected>({
  select,
  skip = false,
}: {
  select?: (users: AugmentedUser[]) => Selected;
  skip?: boolean;
}) {
  const roles = useMemo(() => [RoleEnum.Applicant], []);
  return useUsers(roles, select, skip);
}

export function useTechnicians<Selected>({
  select,
  skip = false,
}: {
  select?: (users: AugmentedUser[]) => Selected;
  skip?: boolean;
}) {
  const roles = useMemo(() => [RoleEnum.Technician], []);
  return useUsers(roles, select, skip);
}
