import React from 'react';

import { Button, Col, Typography, Input, Row, Form as AntForm } from 'antd';
import { useTranslation } from 'react-i18next';
import InputField from 'technical/form/formik/InputField';
import Flex from 'ui/flex';
import { FormValues } from 'business/evaluationRequest/types';
import { useFormikContext } from 'formik';
import { useAuthenticatedUser } from 'business/user/services/queries';
import styles from './index.module.scss';

const { TextArea } = Input;

interface CommentsAndConclusionProps {
  readOnlyTechnician?: boolean;
  readOnlyManager?: boolean;
  readOnlyApplicant?: boolean;
}

const CommentsAndConclusion: React.FC<CommentsAndConclusionProps> = ({
  readOnlyTechnician = false,
  readOnlyManager = false,
  readOnlyApplicant = false,
}) => {
  const { t } = useTranslation();
  const { submitForm, values, setFieldValue } = useFormikContext<FormValues>();
  const user = useAuthenticatedUser();

  return (
    <>
      <Typography.Title level={4}>
        {t('evaluationRequest.creation.commentsAndconclusion')}
      </Typography.Title>
      <AntForm className={styles.form} layout="vertical">
        {values.steps.map((step) => (
          <Row key={step.id}>
            <span>
              {t('evaluationRequest.creation.current.step')}{' '}
              {step.evaluation?.identifier}
              {' : '}
              <span className={styles.readOnly}>{step.comment}</span>
            </span>
          </Row>
        ))}
        <Row gutter={24}>
          <Col flex="1">
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.conclusionTechnician')}
            >
              <InputField<FormValues>
                component={TextArea}
                valueKey="conclusionTechnician"
                readOnly={readOnlyTechnician}
              />
            </AntForm.Item>
            {!readOnlyTechnician && (
              <Row className={styles.actionContainer}>
                <Flex>
                  <Button block type="primary" onClick={submitForm}>
                    {t('evaluationRequest.creation.submitConclusionTechnician')}
                  </Button>
                </Flex>
              </Row>
            )}
          </Col>
          <Col flex="1">
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.conclusionManager')}
            >
              <InputField<FormValues>
                component={TextArea}
                valueKey="conclusionManager"
                readOnly={readOnlyManager}
              />
            </AntForm.Item>
            {!readOnlyManager && (
              <Row className={styles.actionContainer}>
                <Flex>
                  <Button
                    block
                    type="primary"
                    onClick={() => {
                      setFieldValue('managerId', user.id);
                      submitForm();
                    }}
                  >
                    {t('evaluationRequest.creation.submitConclusionManager')}
                  </Button>
                </Flex>
              </Row>
            )}
          </Col>
          <Col flex="1">
            <AntForm.Item
              required
              label={t('evaluationRequest.creation.conclusionApplicant')}
            >
              <InputField<FormValues>
                component={TextArea}
                valueKey="conclusionApplicant"
                readOnly={readOnlyApplicant}
              />
            </AntForm.Item>
            {!readOnlyApplicant && (
              <Row className={styles.actionContainer}>
                <Flex>
                  <Button block type="primary" onClick={submitForm}>
                    {t('evaluationRequest.creation.submitConclusionApplicant')}
                  </Button>
                </Flex>
              </Row>
            )}
          </Col>
        </Row>
      </AntForm>
    </>
  );
};

export default CommentsAndConclusion;
