import * as React from 'react';
import { RoleEnum } from 'business/user/types';
import type { User, ValidRole } from 'business/user/types';
import {
  useAuthenticatedUser,
  useWaitingForAuthenticatedUser,
} from './queries';

export function createAuthorizationPolicy(roles?: Array<ValidRole>) {
  return function policy(user: User) {
    if (!roles) {
      return true;
    }
    return user.roles.some((role) => roles.includes(role.name));
  };
}

export const everyone = createAuthorizationPolicy(Object.values(RoleEnum));
export const everyRoleExceptAdmin = createAuthorizationPolicy([
  RoleEnum.Applicant,
  RoleEnum.Manager,
  RoleEnum.Technician,
]);

export const allExemptAdmin = createAuthorizationPolicy([
  RoleEnum.Technician,
  RoleEnum.Applicant,
  RoleEnum.Manager,
]);

export const managerAndApplicantOnly = createAuthorizationPolicy([
  RoleEnum.Applicant,
  RoleEnum.Manager,
]);

export const adminOnly = createAuthorizationPolicy([RoleEnum.Admin]);
export const technicianOnly = createAuthorizationPolicy([RoleEnum.Technician]);
export const applicantOnly = createAuthorizationPolicy([RoleEnum.Applicant]);
export const managerOnly = createAuthorizationPolicy([RoleEnum.Manager]);

export interface AuthorizedOnlyProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  policy?: (user: User) => boolean;
}
export function AuthorizedAccessOnly({
  children,
  fallback,
  policy = everyone,
}: AuthorizedOnlyProps) {
  const user = useAuthenticatedUser();

  if (policy(user)) {
    return <>{children}</>;
  }
  return fallback ? <>{fallback}</> : null;
}

export interface AuthenticatedOnlyProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}
export function AuthenticatedOnly({
  fallback,
  children,
}: AuthenticatedOnlyProps) {
  const user = useWaitingForAuthenticatedUser();

  return <>{user ? children : fallback}</>;
}
