import React from 'react';
import { Select, SelectProps } from 'antd';
import { Field as FormikField, useFormikContext } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import get from 'lodash.get';
import styles from './index.module.scss';
import FieldError from '../fieldError';
import ReadonlyField from '../ReadonlyField';
import { RecursiveFormNames } from '../types';

type FieldProps<T extends object> = Omit<
  FieldAttributes<SelectProps<any>>,
  'name' | 'onChange' | 'value' | 'id' | 'component'
> & {
  valueKey: RecursiveFormNames<T>;
  optionsList?: {
    id: number | string | null;
    name: string;
    disabled?: boolean;
  }[];
  readOnly?: boolean;
};

/**
 * Ant design select formik controlled
 * avoid onChange duplication and stupid error like typo in id or onChange
 * @param valueKey
 * @param otherProps
 * @param options Options to display
 * @constructor
 */
function SearchableSelectField<T extends object>({
  valueKey,
  optionsList = [],
  readOnly = false,
  ...otherProps
}: FieldProps<T>) {
  const { values, setFieldValue, errors, touched } = useFormikContext<T>();
  const selectedValue = get(values, valueKey);
  return (
    <>
      {readOnly ? (
        <ReadonlyField
          value={optionsList
            .filter((option) =>
              Array.isArray(selectedValue)
                ? selectedValue.includes(option.id)
                : option.id === selectedValue,
            )
            .map((option) => option.name)
            .join(', ')}
        />
      ) : (
        <>
          <FormikField
            component={Select}
            className={styles.select}
            id={valueKey}
            name={valueKey}
            value={selectedValue}
            onChange={(value: number) => {
              const option = optionsList.find((o) => o.id === value);
              setFieldValue(valueKey as string, option?.name.trim());
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: JSX.Element) => {
              const children = option.props.children ?? '';
              return children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            // onSearch={(term: any) => setFieldValue(valueKey as string, term)}
            {...otherProps}
          >
            {optionsList.map(({ id, name, disabled = false }) => (
              <Select.Option key={id} value={id} disabled={disabled}>
                {name}
              </Select.Option>
            ))}
          </FormikField>
          <FieldError<T>
            errors={errors}
            touched={touched}
            valueKey={valueKey}
          />
        </>
      )}
    </>
  );
}

export default SearchableSelectField;
