import axios from 'axios';
import { Pagination } from 'technical/hooks/usePagination';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import { PaginatedProductDesignations, SearchArgs } from '../types';

export const fetchProductDesignationList = async (
  page?: Pagination,
  search?: SearchArgs,
): Promise<PaginatedProductDesignations> => {
  return (
    await axios.get(`/v1/product-designation`, {
      params: {
        ...(page ? getAPITablePosition(page) : {}),
        ...search,
      },
    })
  ).data;
};
