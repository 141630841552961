import * as yup from 'yup';
import {
  FILTERS,
  Filters,
  FormikFilterRow,
  TextFilters,
  IdFilters,
  RangeFilters,
  TEXT_FILTERS,
  ID_FILTERS,
  RANGE_FILTERS,
} from '../components/search/searchFilter/types';

const textFilterSchema = yup
  .object({
    id: yup.number().required('common.form.validation.required'),
    search: yup.string().min(1).required('common.form.validation.required'),
    by: yup
      .mixed<TextFilters>()
      .oneOf([...TEXT_FILTERS])
      .required('common.form.validation.required'),
  })
  .required('common.form.validation.required');

const idFilterSchema = yup
  .object({
    id: yup.number().required('common.form.validation.required'),
    search: yup
      .number()
      .integer()
      .positive()
      .required('common.form.validation.required'),
    by: yup
      .mixed<IdFilters>()
      .oneOf([...ID_FILTERS])
      .required('common.form.validation.required'),
  })
  .required('common.form.validation.required');
const rangeFilterSchema = yup
  .object({
    id: yup.number().required('common.form.validation.required'),
    search: yup
      .object({
        start: yup.date().required('common.form.validation.required'),
        end: yup.date().required('common.form.validation.required'),
      })
      .required('common.form.validation.required'),
    by: yup
      .mixed<RangeFilters>()
      .oneOf([...RANGE_FILTERS])
      .required('common.form.validation.required'),
  })
  .required();

const defaultFilterSchema = yup
  .object({
    by: yup
      .mixed<Filters>()
      .oneOf([...FILTERS])
      .required('common.form.validation.required'),
  })
  .required('common.form.validation.required');

const lazyFilterSchema = yup.lazy((value: FormikFilterRow) => {
  if (!FILTERS.includes(value.by)) {
    return defaultFilterSchema;
  }
  // beacause we first checked that value.by was a valid filter
  // we can cast value.by in the following if statements
  if (TEXT_FILTERS.includes(value.by as TextFilters)) {
    return textFilterSchema;
  }
  if (ID_FILTERS.includes(value.by as IdFilters)) {
    return idFilterSchema;
  }
  if (RANGE_FILTERS.includes(value.by as RangeFilters)) {
    return rangeFilterSchema;
  }
  return defaultFilterSchema;
});

export const filterFormSchema = yup.object({
  filters: yup
    .array()
    // https://github.com/jquense/yup/issues/1283#issuecomment-786611795
    // this might be a bug in the yup type definition
    // @ts-expect-error
    .of(lazyFilterSchema)
    .ensure(),
});
