export const StatusEnum = {
  DRAFT: 1,
  WAITING_VALIDATION: 2,
  PENDING: 3,
  IN_PROGRESS: 4,
  DONE: 5,
  CANCELED: 6,

  1: 'DRAFT',
  2: 'WAITING_VALIDATION',
  3: 'PENDING',
  4: 'IN_PROGRESS',
  5: 'DONE',
  6: 'CANCELED',
} as const;

export const isStatusDraft = (statusId: number) => {
  return StatusEnum.DRAFT === statusId;
};

export const isStatusDone = (statusId: number) => {
  return StatusEnum.DONE === statusId;
};

export const isStatusCanceled = (statusId: number) => {
  return StatusEnum.CANCELED === statusId;
};

export const isStatusInProgress = (statusId: number) => {
  return StatusEnum.IN_PROGRESS === statusId;
};

export const isStatusPending = (statusId: number) => {
  return StatusEnum.PENDING === statusId;
};

export const isStatusWaitingValidation = (statusId: number) => {
  return StatusEnum.WAITING_VALIDATION === statusId;
};

export const isStatusNotValidated = (statusId: number) => {
  return StatusEnum.PENDING > statusId;
};
