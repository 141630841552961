import axios from 'axios';

import {
  PaginatedBenchStatuses,
  PaginatedBenchTypes,
  PaginatedBenchStatusesApi,
  PaginatedBench,
  UsedLane,
} from './types';

export const fetchBenchStatuses = async (): Promise<PaginatedBenchStatuses> => {
  const benchStatuses: PaginatedBenchStatusesApi = (
    await axios.get('/v1/bench-status')
  ).data;

  return {
    count: benchStatuses.count,
    records: benchStatuses.records.map((record) => ({
      id: record.id,
      name: record.status,
    })),
  };
};

export const fetchBenchTypes = async (): Promise<PaginatedBenchTypes> => {
  return (await axios.get('/v1/bench-type')).data;
};

export const fetchBench = async (
  benchType: number,
): Promise<PaginatedBench> => {
  return (await axios.get('/v1/bench', { params: { benchType } })).data;
};

export const fetchUsedLanes = async (benchId: number): Promise<UsedLane[]> => {
  return (await axios.get(`/v1/bench/${benchId}/usedLanes`)).data;
};
