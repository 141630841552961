import * as yup from 'yup';

export const anomalySchema = yup.object({
  cycle: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  evaluationRequestId: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  stepId: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  sequenceType: yup
    .string()
    .trim()
    .max(20)
    .required('common.form.validation.required'),
  programNumber: yup
    .number()
    .typeError('common.form.validation.shouldBeNumber')
    .required('common.form.validation.required'),
  date: yup.date().required('common.form.validation.required'),
});
