import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { RangeValue } from 'rc-picker/lib/interface';
import get from 'lodash.get';
import { format } from 'date-fns';
import DatePicker from '../../../../ui/form/datePicker';
import FieldError from '../fieldError';
import { RecursiveFormNames } from '../types';
import ReadonlyField from '../ReadonlyField';

type FieldProps<T extends object> = Omit<
  FieldAttributes<RangePickerProps<Date>>,
  'name' | 'onChange' | 'value' | 'id'
> & {
  valueKey: RecursiveFormNames<T>;
  readOnly?: boolean;
};

/**
 * Ant design RAngepicker formik controlled
 * we use custom ant datepicker to use date-fns
 * avoid onChange duplication and stupid error like typo in id or onChange
 * @param valueKey
 * @param otherProps
 * @constructor
 */
function RangePickerField<T extends object>({
  valueKey,
  readOnly,
  ...otherProps
}: FieldProps<T>) {
  const { values, setFieldValue, errors, touched } = useFormikContext<T>();

  const dates = get(values, valueKey) as
    | { start?: Date; end?: Date }
    | undefined;
  const value: RangeValue<Date> = [
    dates?.start ? dates.start : null,
    dates?.end ? dates.end : null,
  ];

  return (
    <>
      {readOnly ? (
        <ReadonlyField
          value={value
            .map((date) => (date ? format(date, 'dd/MM/yyyy') : '...'))
            .join(' -> ')}
        />
      ) : (
        <>
          <FormikField
            component={DatePicker.RangePicker}
            id={valueKey}
            name={valueKey}
            value={value}
            format="DD/MM/yyyy"
            onChange={(range: RangeValue<Date>) => {
              setFieldValue(valueKey as string, {
                start: range?.[0],
                end: range?.[1],
              });
            }}
            {...otherProps}
          />
          <FieldError<T>
            errors={errors}
            touched={touched}
            // we only care about the start date because ant range picker always sets
            // both (end/start) on change
            valueKey={`${valueKey}.start` as RecursiveFormNames<T>}
          />
        </>
      )}
    </>
  );
}

export default RangePickerField;
