import { CopyOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DataManagementConfig } from 'business/admin/types';
import { FormikValues, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import Flex from 'ui/flex';

interface ActionColumnsProps<
  Entity extends { id: number },
  CreateParams extends FormikValues,
  Search extends Record<any, unknown> = any,
> {
  config: DataManagementConfig<Entity, CreateParams, Search>;
  entity: Entity;
  setCreation: (param: CreateParams | null) => void;

  updateEntity: UseMutateFunction<void, unknown, Entity, unknown>;
  isUpdateLoading: boolean;
}

const ActionColumns = <
  Entity extends { id: number },
  CreateParams extends FormikValues,
  Search extends Record<any, unknown> = any,
>({
  config: { permissions, hooks },
  entity,
  setCreation,
  isUpdateLoading,
  updateEntity,
}: ActionColumnsProps<Entity, CreateParams, Search>) => {
  const { t } = useTranslation();
  const { values, submitForm, dirty } = useFormikContext<Entity>();

  const { mutate: deleteEntity, isLoading: isDeleteLoading } =
    hooks.useDeletion();

  return (
    <Flex justify="flex-end" alignItems="center">
      {permissions.update?.(entity) && dirty ? (
        <Button
          type="primary"
          size="middle"
          loading={isUpdateLoading}
          htmlType="submit"
          onClick={() => submitForm()}
          style={{ marginRight: 12 }}
        >
          {t('pages.dataManagement.save')}
        </Button>
      ) : null}
      {permissions.add?.() ? (
        <Button
          type="text"
          icon={<CopyOutlined />}
          shape="circle"
          onClick={() => setCreation(values as unknown as CreateParams)}
        />
      ) : null}
      {
        // Update permission for disable element
        permissions.disable?.(entity) ? (
          <Popconfirm
            title={t('pages.dataManagement.disable.confirmation')}
            placement="topRight"
            onConfirm={() => updateEntity({ ...entity, disabled: 1 })}
            okText={t('pages.dataManagement.disable.ok')}
            cancelText={t('common.cancel')}
          >
            <Tooltip title={t('pages.dataManagement.disable.info')}>
              <Button
                type="text"
                shape="circle"
                icon={<StopOutlined />}
                danger
              />
            </Tooltip>
          </Popconfirm>
        ) : null
      }
      {permissions.delete?.(entity) && (
        <Popconfirm
          title={t('pages.dataManagement.deletion.confirmation')}
          placement="topRight"
          onConfirm={() => deleteEntity(entity.id)}
          okText={t('pages.dataManagement.deletion.ok')}
          cancelText={t('common.cancel')}
        >
          <Button
            type="text"
            shape="circle"
            loading={isDeleteLoading}
            icon={<DeleteOutlined />}
            danger
          />
        </Popconfirm>
      )}
    </Flex>
  );
};

export default ActionColumns;
