/**
 * return a copy of the param object with the addition of id
 * this is usefull in antdesign table with locally generated row
 * @param object
 */
export function generateLocalId<TObject>(
  object: TObject,
): TObject & { id: number } {
  return { ...object, id: new Date().getTime() };
}
