import { Flatten, Values } from 'technical/types';

export type DateRange = { start: Date; end: Date };
export type DateAsStringRange = { start: string; end: string };

export const ID_FILTERS = [
  'id',
  'applicant',
  'technician',
  'status',
  'priority',
] as const;
export const TEXT_FILTERS = [
  'reference',
  'orderedQuantity',
  'BRNumber',
  'subject',
  'qualityPlanNumber',
  'project',
  'lotNumber',
  'commandNumber',
  'purchaseOrder',
  'nominalCapacity',
  'description',
  'artsReference',
  'clientSupplier', // Used as text
  'productDesignation', // Used as text
  'category', // Used as text
  'technology', // Used as text
  'product', // Used as text
  'comments',
  'conclusions',
  'applicantName',
] as const;
export const RANGE_FILTERS = [
  'startDate',
  'maxStartDate',
  'endDate',
  'requestDate',
  'estimatedEndDate',
  'intermediateExitDate',
] as const;

type FilterRecords = Record<(typeof ID_FILTERS)[number], Array<number | null>> &
  Record<(typeof TEXT_FILTERS)[number], Array<string>> &
  Record<(typeof RANGE_FILTERS)[number], Array<DateAsStringRange>>;

type FormikFilterRecords = Record<
  (typeof ID_FILTERS)[number],
  Array<number | null>
> &
  Record<(typeof TEXT_FILTERS)[number], Array<string>> &
  Record<(typeof RANGE_FILTERS)[number], Array<DateRange>>;

export type FilterDefinitions = {
  [Key in keyof FilterRecords]: FilterRecords[Key];
};

export type FormikFilterDefinitions = {
  [Key in keyof FormikFilterRecords]: FormikFilterRecords[Key];
};

export const FILTERS = [
  ...ID_FILTERS,
  ...TEXT_FILTERS,
  ...RANGE_FILTERS,
] as const;

export type Filters = (typeof FILTERS)[number];
export type IdFilters = (typeof ID_FILTERS)[number];
export type TextFilters = (typeof TEXT_FILTERS)[number];
export type RangeFilters = (typeof RANGE_FILTERS)[number];
export type FilterValues = Values<FilterDefinitions>;

export type FormikFilterRow = {
  [Key in keyof FormikFilterDefinitions]: {
    id: number;
    by: Key;
    search?: Flatten<FormikFilterDefinitions[Key]>;
  };
}[keyof FormikFilterDefinitions];

export type OrderBy = Partial<
  Record<
    | 'reference'
    | 'status'
    | 'priority'
    | 'startDate'
    | 'endDate'
    | 'requestDate'
    | 'maxStartDate',
    'ASC' | 'DESC'
  >
>;
