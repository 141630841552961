import { Pagination } from 'technical/hooks/usePagination';
import { useQuery } from 'react-query';
import { emptyPaginatedData } from 'technical/api/constants';
import { selectRecords } from 'technical/selects/selectRecords';
import { fetchAllBenchPrograms, fetchBenchPrograms } from '../api';

const benchProgramKeys = {
  all: [{ scope: 'bench-program' }] as const,
  lists: () => [{ ...benchProgramKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...benchProgramKeys.lists()[0], search, page }] as const,
  details: () => [{ ...benchProgramKeys.all[0], entity: 'detail' }] as const,
  detail: (stepId?: number, version?: number) =>
    [{ ...benchProgramKeys.details()[0], stepId, version }] as const,
};

export const useFetchBenchPrograms = (stepId?: number, version?: number) => {
  return useQuery(
    benchProgramKeys.detail(stepId, version),
    () => fetchBenchPrograms(stepId, version),
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
      enabled: !!stepId,
    },
  );
};

export const useFetchAllBenchPrograms = (page: Pagination) => {
  return useQuery(
    benchProgramKeys.list(page, null),
    () => fetchAllBenchPrograms(page),
    {
      placeholderData: emptyPaginatedData,
    },
  );
};
