import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Link from '../link';
import Routes from '../../business/router/routes';
import Spacer from '../spacer';

const NotAuthorized: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Spacer direction="vertical" size="small">
      <Typography.Title level={4}>
        {t('user.notAuthorized.title')}
      </Typography.Title>
      <Typography.Text>{t('user.notAuthorized.message')}</Typography.Text>
      <Link to={Routes.Home}>{t('user.notAuthorized.cta')}</Link>
    </Spacer>
  );
};

export default NotAuthorized;
