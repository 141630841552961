import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './business/App';
import reportWebVitals from './reportWebVitals';
import './translations';

function prepare() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_USE_MOCKING_SERVICE === 'true'
  ) {
    /* eslint-disable-next-line global-require */
    const { worker } = require('./mocks/browser');
    return worker.start();
  }

  return Promise.resolve();
}

prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
