import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'ui/link';
import Routes from 'business/router/routes';
import AuthCard from '../authCard';
import { useAuthentication } from '../../services/actions';

interface Props {
  className?: string;
}

export default function LoginCard({ className }: Props) {
  const [signInError, setSignInError] = useState<string>();
  const { t } = useTranslation();
  const { signIn } = useAuthentication();

  return (
    <AuthCard
      className={classnames(className)}
      formSubmit={(values) => {
        signIn(values, {
          onSuccess: () => {
            setSignInError(undefined);
          },
          onError: (err: any) => {
            if (err.response && err.response.status === 400) {
              setSignInError(t('notConnected.login.errors.wrongCredentials'));
            } else {
              setSignInError(t('common.form.genericFormError'));
            }
          },
        });
      }}
      submitButton={{
        label: t('notConnected.login.submit'),
      }}
      authError={signInError}
      passwordHint={
        <Link color="tundora" to={Routes.ForgotPassword}>
          {t('pages.forgotPassword.title', {
            context: 'question',
          })}
        </Link>
      }
    />
  );
}
