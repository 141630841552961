import React from 'react';
import { format } from 'date-fns';
import { augmentUser } from 'business/user/services/queries';
import { AugmentedUser } from 'business/user/types';
import { ColumnsType } from 'antd/lib/table';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import { ReportManagementConfig } from 'business/admin/types';

const config: ReportManagementConfig = {
  entity: 'report',
  columns(): ColumnsType<EvaluationRequest> {
    return [
      {
        dataIndex: 'reference',
        key: 'reference',
        width: 100,
        render: (reference: string) => <>{reference}</>,
      },
      {
        dataIndex: 'applicant',
        key: 'applicant',
        render: (applicant: AugmentedUser) => (
          <>{applicant ? augmentUser(applicant).fullname : null}</>
        ),
      },
      {
        dataIndex: 'requestDate',
        key: 'requestDate',
        render: (date: string) => format(new Date(date), 'dd/MM/yyyy'),
      },
    ];
  },
};

export default config;
