import { Paginated } from 'technical/api/types';

// /!\ These values come from the database. They can only be updated with a migration.
export enum MeasureTypeName {
  Capacitance = 1,
  Tension = 2,
  Temperature = 3,
  Intensity = 4,
  Mass = 5,
  Impedance = 6,
  Other = 7,
}

export interface MeasureType {
  id: number;
  name: string;
  unit: string;
  measure: string;
}

export type PaginatedMeasureTypes = Paginated<MeasureType>;
