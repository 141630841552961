import classnames from 'classnames';
import colors from 'ui/colors';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  to: string;
  children: React.ReactNode;
  prefix?: string;
  color?: keyof typeof colors;
  align?: 'center' | 'left' | 'right';
  className?: string;
}

export default function Link({
  to,
  children,
  prefix,
  className,
  align,
  color,
}: Props) {
  const LinkComponent = () => (
    <ReactRouterLink
      className={classnames(styles.link, { className: !prefix })}
      style={{ color: color ? colors[color] : undefined, textAlign: align }}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );

  if (prefix) {
    return (
      <p
        className={classnames(className, styles.prefixedLink)}
        style={{ textAlign: align }}
      >
        {prefix}
        {/* Space between the prefix and the link */}
        {` `}
        <LinkComponent />
      </p>
    );
  }

  return <LinkComponent />;
}
