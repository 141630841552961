import React from 'react';
import { Breadcrumb as AntBreadcrumb, BreadcrumbProps } from 'antd';
import classnames from 'classnames';
import styles from './index.module.scss';

interface BreadCrumbProps extends BreadcrumbProps {
  breadCrumbSections: { key: string; label: string }[];
}

const Breadcrumb: React.FC<BreadCrumbProps> = ({
  breadCrumbSections,
  className,
  ...other
}) => {
  return (
    <AntBreadcrumb
      className={classnames(styles.breadCrumb, className)}
      {...other}
    >
      {breadCrumbSections.map((section) => (
        <AntBreadcrumb.Item key={section.key}>
          {section.label}
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  );
};

export default Breadcrumb;
