import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { Input } from 'antd';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import { Category, CategoryFormValues } from 'business/category/types';
import { categoryKeys } from 'business/category/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import SelectField from 'technical/form/formik/selectField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';

const { TextArea } = Input;

const config: DataManagementConfig<
  Category,
  Pick<Category, 'name' | 'description' | 'formType'>
> = {
  entity: 'category',
  hooks: createAdminHooks({
    keys: categoryKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/category', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post(`/v1/category`, {
        name: entity.name,
        description: entity.description,
        formType: entity.formType,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/category/${entity.id}`, {
        name: entity.name,
        description: entity.description,
        formType: entity.formType,
      });
    },
    async del(id) {
      return axios.delete(`/v1/category/${id}`);
    },
  }),
  columns(permissions, t) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<CategoryFormValues>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={50}
            />
          );
        },
      },
      {
        dataIndex: 'formType',
        key: 'formType',
        render: (_value, entity) => {
          return (
            <SelectField<CategoryFormValues>
              valueKey="formType"
              optionsList={[
                {
                  id: null,
                  name: t('categories.names.all'),
                },
                {
                  id: 1,
                  name: t('categories.names.simple'),
                },
                {
                  id: 2,
                  name: t('categories.names.advanced'),
                },
              ]}
              disabled={!permissions.update(entity)}
            />
          );
        },
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => {
          return (
            <InputField<CategoryFormValues>
              valueKey="description"
              readOnly={!permissions.update(entity)}
              component={TextArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              showCount
              maxLength={100}
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(50).required(),
    description: yup.string().trim().min(1).max(100).required(),
    formType: yup.number().integer().min(1).max(2).nullable(true),
  }),
  defaultValues: {
    name: '',
    description: '',
    formType: null,
  },
  permissions: {},
};

export default config;
