import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router';

import Routes from 'business/router/routes';

import SignInPage from 'business/user/pages/signIn';
import ForgotPasswordPage from 'business/user/pages/forgotPassword';
import ResetPasswordPage from 'business/user/pages/resetPassword';

import EvaluationRequestsPage from 'business/evaluationRequest/pages/evaluationRequests';
import ToDoPage from 'business/evaluationRequest/pages/toDoTechnician';
import NewEvaluationRequestPage from 'business/evaluationRequest/pages/newEvaluationRequest';
import SearchEvaluationRequestPage from 'business/evaluationRequest/pages/globalSearch';
import { authenticatedMenuItems } from 'business/applicant/constants/menu';
import { useAuthenticatedUser } from 'business/user/services/queries';
import BenchProgramsListPage from 'business/benchProgram/pages/benchProgramsListPage';
import AnomalyListPage from 'business/anomaly/pages/anomalyListPage';
import WaitingValidationListPage from 'business/evaluationRequest/pages/waitingValidation';
import WeeklyBurdenPage from 'business/evaluationRequest/pages/weeklyBurden';
import EvaluationRequestDetail from '../evaluationRequest/pages/evaluationRequestDetail';
import CopyEvaluationRequestPage from '../evaluationRequest/pages/copyEvaluationRequest';
import EvaluationRequestReportPage from '../evaluationRequest/pages/report';
import DataManagementPage from '../admin/pages/dataManagement';
import FourOhFour from './fourOhFour';

export function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path={Routes.SignIn} element={<SignInPage />} />
      <Route path={Routes.ForgotPassword} element={<ForgotPasswordPage />} />
      <Route path={Routes.ResetPassword} element={<ResetPasswordPage />} />
      <Route path="*" element={<Navigate to={Routes.SignIn} />} />
    </Switch>
  );
}

export function AuthenticatedApp() {
  const user = useAuthenticatedUser();
  return (
    <Switch>
      <Route
        path={Routes.EvaluationRequestDetail}
        element={<EvaluationRequestDetail />}
      />
      <Route
        path={Routes.EvaluationRequestReport}
        element={<EvaluationRequestReportPage />}
      />
      <Route
        path={Routes.CopyEvaluationRequest}
        element={<CopyEvaluationRequestPage />}
      />
      <Route path={Routes.ToDo} element={<ToDoPage />} />
      <Route
        path={Routes.WaitingValidation}
        element={<WaitingValidationListPage />}
      />
      <Route path={Routes.WeeklyBurden} element={<WeeklyBurdenPage />} />
      <Route
        path={Routes.EvaluationRequests}
        element={<EvaluationRequestsPage />}
      />
      <Route
        path={Routes.NewEvaluationRequest}
        element={<NewEvaluationRequestPage />}
      />
      <Route
        path={Routes.SearchEvaluationRequest}
        element={<SearchEvaluationRequestPage />}
      />
      <Route
        path={`${Routes.DataManagement}/*`}
        element={<DataManagementPage />}
      />
      <Route path={Routes.BenchPrograms} element={<BenchProgramsListPage />} />
      <Route path={Routes.AnomalyList} element={<AnomalyListPage />} />
      <Route
        path="*"
        element={
          <Navigate
            to={
              authenticatedMenuItems.find(({ policy }) => policy(user))?.path ??
              Routes.SearchEvaluationRequest
            }
          />
        }
      ></Route>
      <Route path="*" element={<FourOhFour />} />
    </Switch>
  );
}
