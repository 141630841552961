import React, { useState, useEffect, useCallback } from 'react';
import { Col, Form as AntForm, Row, Select, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import InputField from 'technical/form/formik/InputField';
import SelectField from 'technical/form/formik/selectField';
import { Formik } from 'formik';
import { MeasureFormValues, MeasurePostValue } from 'business/measure/types';
import {
  EvaluationRequest,
  FetchedPositionItem,
} from 'business/evaluationRequest/types';
import { measuresSchema } from 'business/measure/schema/measuresSchema';
import AnomalyCreationButton from 'business/anomaly/components/anomalyCreationButton';
import { useFetchMeasureTypes } from 'business/measureType/hooks/queries';
import {
  useFetchMeasures,
  usePostMeasures,
} from 'business/measure/hooks/queries';
import Button from 'ui/button';
import {
  readImpedanceMeasuresFromFile,
  readMassMeasuresFromFile,
  readVoltageMeasuresFromFile,
} from 'business/measure/utils/services';
import ImportFileButton from 'ui/importFileButton';
import InputError from 'ui/form/inputError';
import InputWarning from 'ui/form/inputWarning';
import { MeasureTypeName } from 'business/measureType/types';
import EvaluationStepCommentModal from 'business/evaluationRequest/components/EvaluationStepCommentModal';
import { usePostEvaluationStep } from 'business/evaluationStep/api';
import { EvaluationStep } from 'business/evaluationStep/types';
import { QueryObserverResult } from 'react-query';
import styles from './index.module.scss';

interface UpdateMeasuresProps {
  positions: FetchedPositionItem[];
  latestVersion: number;
  changePositions: () => void;
  setVersion: (version: number) => void;
  version: number;
  stepId: number;
  step: EvaluationStep;
  goToNextStep?: () => void;
  finish?: () => void;
  evaluationRequest: EvaluationRequest;
  readOnly?: boolean;
  refetchEvaluationRequest: () => Promise<
    QueryObserverResult<EvaluationRequest>
  >;
}

const formatPostValues = (
  formValues: MeasureFormValues,
  positions: FetchedPositionItem[],
): MeasurePostValue[] => {
  const { cycle, measureTypeId, measures } = formValues;
  return (
    positions
      .map((position, index) => {
        const measure = measures[index];
        return {
          id: Number.isNaN(measure?.id) ? null : measure?.id,
          cycle,
          value: Number.isNaN(measure?.value) ? null : Number(measure?.value), // will transform Number(null|undefined) = 0; Number('na'|NaN) = NaN
          positionId: position.id,
          measureTypeId,
        };
      })
      // Todo ART-216 - check if we allow nullable value or 0 value
      .filter(({ value }) => !!value) as MeasurePostValue[] // will filter all wrong value null, undefined, 0, NaN
  );
};

const defaultValues: MeasureFormValues = {
  measureTypeId: 1,
  cycle: 0,
  measures: [],
};

const UpdateMeasures: React.FC<UpdateMeasuresProps> = ({
  positions,
  changePositions,
  latestVersion,
  setVersion,
  version,
  stepId,
  step,
  goToNextStep,
  finish,
  evaluationRequest,
  readOnly = false,
  refetchEvaluationRequest,
}) => {
  const { t } = useTranslation();
  const { data: measureTypes } = useFetchMeasureTypes();
  const { mutate, isLoading: isPostLoading } = usePostMeasures();

  const [isStepCommentModalVisible, setIsStepCommentModalVisible] = useState<{
    isOpen: boolean;
    stayOnPage: boolean;
  }>({ isOpen: false, stayOnPage: false });

  const versionOptions = Array.from(Array(latestVersion).keys()).map(
    (i) => i + 1,
  );

  const measureTypesOptionsList = (measureTypes ?? []).map((measureType) => ({
    id: measureType.id,
    name: `${measureType.measure} (${measureType.unit})`,
  }));
  const columns = [
    {
      title: t('evaluationRequest.creation.measures.measure'),
      dataIndex: 'measure',
      key: 'measure',
      render: (_text: string, _record: any, index: number) => (
        <InputField<MeasureFormValues>
          valueKey={`measures.${index}.value`}
          readOnly={readOnly}
        />
      ),
    },
    {
      title: t('evaluationRequest.creation.itemPositions.itemNumber'),
      dataIndex: 'itemNumber',
      key: 'itemNumber',
    },
    {
      title: t('evaluationRequest.creation.itemPositions.lane'),
      dataIndex: 'lane',
      key: 'lane',
    },
    {
      title: t('evaluationRequest.creation.measures.bench'),
      dataIndex: 'bench',
      key: 'bench',
    },
  ];

  // file management
  const [fileError, setFileError] = useState<string>();
  const [fileWarning, setFileWarning] = useState<string>();

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={measuresSchema}
      onSubmit={(values) => {
        const postValues = formatPostValues(values, positions || []);
        mutate(postValues);
      }}
    >
      {({ submitForm, values, setFieldValue }) => {
        const {
          data: measures,
          isLoading,
          isFetching,
        } = useFetchMeasures(
          stepId,
          values.cycle,
          version,
          values.measureTypeId,
        );

        const { mutate: mutateEvaluationStep } = usePostEvaluationStep(
          stepId,
          async () => {
            const next = finish || goToNextStep;
            await refetchEvaluationRequest();
            if (!next || isStepCommentModalVisible.stayOnPage) {
              return;
            }
            // Wait for rerender with the latest comment
            setTimeout(next);
          },
        );

        useEffect(() => {
          if (!isFetching && measures) {
            setFieldValue(
              'measures',
              positions.map((position) => {
                const foundMeasure = measures.find(
                  (measure) => measure.positionId === position.id,
                );
                // if no measure is found, we set the value to null, and undefined id
                return {
                  id: foundMeasure?.id,
                  value: foundMeasure?.value ?? null,
                };
              }),
            );
          }
        }, [measures]);

        const handleFileLoad = useCallback(
          async (file: File | null, measureType: MeasureTypeName) => {
            if (!file) {
              return;
            }

            const buffer = await file.arrayBuffer();

            let importedValues: Array<number | null> = [];
            try {
              /* eslint-disable-next-line default-case */
              switch (measureType) {
                case MeasureTypeName.Mass:
                  if (!['text/csv'].includes(file.type)) {
                    setFileError(
                      t('evaluationRequest.update.measures.errors.mimetype'),
                    );
                    return;
                  }
                  importedValues = (
                    await readMassMeasuresFromFile(buffer)
                  ).reduce((previous, current) => previous.concat(current), []);
                  break;
                case MeasureTypeName.Impedance:
                  if (
                    ![
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ].includes(file.type)
                  ) {
                    setFileError(
                      t('evaluationRequest.update.measures.errors.mimetype'),
                    );
                    return;
                  }
                  importedValues = await readImpedanceMeasuresFromFile(buffer);
                  break;
                case MeasureTypeName.Tension:
                  if (
                    ![
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ].includes(file.type)
                  ) {
                    setFileError(
                      t('evaluationRequest.update.measures.errors.mimetype'),
                    );
                    return;
                  }
                  importedValues = await readVoltageMeasuresFromFile(buffer);
                  break;
              }
            } catch {
              setFileError(
                t('evaluationRequest.update.measures.errors.mimetype'),
              );
              return;
            }

            setFileError(undefined);

            if (
              positions.length > importedValues.length ||
              importedValues.includes(null)
            ) {
              setFileWarning(
                t(
                  'evaluationRequest.update.measures.warnings.notEnoughMeasures',
                ),
              );
            } else if (positions.length < importedValues.length) {
              setFileWarning(
                t('evaluationRequest.update.measures.warnings.tooManyMeasures'),
              );
            } else {
              setFileWarning(undefined);
            }
            setFieldValue('measureTypeId', measureType);
            // we must refresh measure type dropdown first before updating values
            setTimeout(
              () =>
                setFieldValue(
                  'measures',
                  importedValues.map((value) => ({ value })),
                ),
              250,
            );
          },
          [positions, values],
        );

        const dataSource = (positions || []).map((items, i) => ({
          key: `${items.element}_${i}`,
          itemNumber: items.element,
          lane: items.lane,
          bench: items.bench.name,
          measure: values.measures[i],
        }));

        if (isLoading) {
          return <Spin />;
        }
        return (
          <>
            <div className={styles.commentsBox}>
              <Row gutter={8} className={styles.comments}>
                <Col>
                  <div>
                    {t('evaluationRequest.creation.current.stepComment')} :{' '}
                  </div>
                </Col>
                <Col>
                  <div className={styles.comment}> {step.comment}</div>
                </Col>
              </Row>
              <Row>
                <Button
                  id="save-button"
                  type="primary"
                  onClick={() => {
                    setIsStepCommentModalVisible({
                      isOpen: true,
                      stayOnPage: true,
                    });
                  }}
                >
                  {' '}
                  {t('evaluationRequest.creation.current.updateComment')}{' '}
                </Button>
              </Row>
            </div>
            <AntForm layout="inline">
              <AntForm.Item
                label={t('evaluationRequest.creation.measures.version')}
              >
                <Select value={version || 0} onChange={setVersion}>
                  {versionOptions.map((versionOption) => (
                    <Select.Option key={versionOption} value={versionOption}>
                      {versionOption}
                    </Select.Option>
                  ))}
                </Select>
              </AntForm.Item>

              <AntForm.Item
                label={t('evaluationRequest.creation.measures.type')}
              >
                <SelectField<MeasureFormValues>
                  valueKey="measureTypeId"
                  optionsList={measureTypesOptionsList}
                />
              </AntForm.Item>
              <AntForm.Item
                label={t('evaluationRequest.creation.measures.cycle')}
              >
                <InputField<MeasureFormValues> valueKey="cycle" />
              </AntForm.Item>

              {/* File import */}
              {!readOnly ? (
                <>
                  <AntForm.Item>
                    <ImportFileButton
                      onFileChange={(file) =>
                        handleFileLoad(file, MeasureTypeName.Mass)
                      }
                      accept=".csv"
                    >
                      {t(
                        'evaluationRequest.update.measures.importMassMeasures',
                      )}
                    </ImportFileButton>
                  </AntForm.Item>
                  <AntForm.Item>
                    <ImportFileButton
                      onFileChange={(file) =>
                        handleFileLoad(file, MeasureTypeName.Impedance)
                      }
                      accept=".xlsx"
                    >
                      {t(
                        'evaluationRequest.update.measures.importImpedanceMeasures',
                      )}
                    </ImportFileButton>
                  </AntForm.Item>
                  <AntForm.Item>
                    <ImportFileButton
                      onFileChange={(file) =>
                        handleFileLoad(file, MeasureTypeName.Tension)
                      }
                      accept=".xlsx"
                    >
                      {t(
                        'evaluationRequest.update.measures.importVoltageMeasures',
                      )}
                    </ImportFileButton>
                  </AntForm.Item>
                  <InputError error={fileError} />
                  <InputWarning message={fileWarning} />
                </>
              ) : null}
            </AntForm>

            {dataSource.length > 0 && (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            )}

            <div className={styles.actionsContainer}>
              <Space>
                <Button onClick={changePositions}>
                  {t('evaluationRequest.creation.measures.changePositions')}
                </Button>
                <AnomalyCreationButton
                  evaluationRequest={evaluationRequest}
                  version={version}
                />
                <Button
                  id="save-button"
                  type="primary"
                  loading={isPostLoading}
                  onClick={submitForm}
                >
                  {t('evaluationRequest.creation.measures.validate')}
                </Button>
                {goToNextStep ? (
                  <Button
                    id="save-button"
                    type="primary"
                    loading={isPostLoading}
                    onClick={() => {
                      if (step.comment) {
                        return goToNextStep();
                      }
                      return setIsStepCommentModalVisible({
                        isOpen: true,
                        stayOnPage: false,
                      });
                    }}
                  >
                    {t('evaluationRequest.update.nextStep')}
                  </Button>
                ) : null}
                {finish ? (
                  <Button
                    id="save-button"
                    type="primary"
                    loading={isPostLoading}
                    onClick={() => {
                      if (step.comment) {
                        return finish();
                      }
                      return setIsStepCommentModalVisible({
                        isOpen: true,
                        stayOnPage: false,
                      });
                    }}
                  >
                    {t('evaluationRequest.update.completeRequest.button')}
                  </Button>
                ) : null}
              </Space>
            </div>
            <EvaluationStepCommentModal
              isOpen={isStepCommentModalVisible.isOpen}
              isStayOnPage={isStepCommentModalVisible.stayOnPage}
              onCancel={() =>
                setIsStepCommentModalVisible({
                  isOpen: false,
                  stayOnPage: false,
                })
              }
              value={step?.comment}
              onSubmit={(comment) => {
                mutateEvaluationStep({ comment });
                setIsStepCommentModalVisible({
                  isOpen: false,
                  stayOnPage: false,
                });
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateMeasures;
