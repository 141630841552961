import React, { useState, useEffect } from 'react';
import PageLayout from 'ui/PageLayout';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import { ColumnsType } from 'antd/lib/table';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import EvaluationStatusTag from 'business/evaluationRequest/components/evaluationRequestStatus';
import Table from 'ui/table';
import { PaginationProps, Select, Typography } from 'antd';
import Flex from 'ui/flex';
import { format } from 'date-fns';
import { AugmentedUser } from 'business/user/types';
import {
  isStatusDone,
  StatusEnum,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import { OrderBy } from 'business/evaluationRequest/components/search/searchFilter/types';
import EvaluationRequestCount from 'business/evaluationRequest/components/evaluationRequestCount';
import Spacer from 'ui/spacer';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import Routes from '../../../router/routes';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import { augmentUser, useTechnicians } from '../../../user/services/queries';
import {
  AuthorizedAccessOnly,
  managerOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'weekly-burden';
const pagesBreadcrumb = ['home', currentPage];

function convertToOptions(users: AugmentedUser[]) {
  return users.map((user) => ({
    id: user.id,
    name: user.fullname,
  }));
}

const columns = (t: TFunction): ColumnsType<EvaluationRequest> => [
  {
    title: t('evaluationRequest.table.reference'),
    dataIndex: 'reference',
    key: 'reference',
    sorter: true,
    render: (reference) => <>{reference}</>,
  },
  {
    title: t('evaluationRequest.table.applicant'),
    dataIndex: 'applicant',
    key: 'applicant',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (applicant) => (
      <>{applicant ? augmentUser(applicant).fullname : null}</>
    ),
  },
  {
    title: t('evaluationRequest.table.technician'),
    dataIndex: 'technician',
    key: 'technician',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (value) => <>{value ? augmentUser(value).fullname : null}</>,
  },
  {
    title: t('evaluationRequest.table.requestDate'),
    dataIndex: 'requestDate',
    key: 'requestDate',
    sorter: true,
    render: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : '-'),
  },
  {
    title: t('evaluationRequest.table.estimatedEndDate'),
    dataIndex: 'estimatedEndDate',
    key: 'estimatedEndDate',
    sorter: true,
    render: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : ''),
  },
  {
    title: t('evaluationRequest.table.priority.shortHeader'),
    dataIndex: 'priority',
    key: 'priority',
    sorter: true,
    // if priority entity does not exists, backend returns null, so we handle this case here
    render: (priority) => <>{priority ? `${priority.name}` : null}</>,
  },
  {
    title: t('evaluationRequest.table.status'),

    dataIndex: 'status',
    key: 'status',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (status) =>
      status ? <EvaluationStatusTag status={status} /> : null,
  },
  {
    title: t('evaluationRequest.table.action'),
    key: 'action',
    render: (_value, evaluationRequest) => {
      return (
        <Flex alignItems="center" justify="space-around">
          <Link
            to={generatePath(Routes.EvaluationRequestDetail, {
              id: evaluationRequest.id.toString(),
            })}
          >
            {t('evaluation.request.go')}
          </Link>
          {process.env.REACT_APP_REPORT_ACTIVATED === 'true' &&
          isStatusDone(evaluationRequest.status.id) ? (
            <Link
              to={generatePath(Routes.EvaluationRequestReport, {
                id: evaluationRequest.id.toString(),
              })}
            >
              {t('evaluation.request.goToReport')}
            </Link>
          ) : null}
        </Flex>
      );
    },
  },
];

const WeeklyBurdenPage: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);
  const technicians = useTechnicians({ select: convertToOptions });
  const [technician, setTechnician] = useState<number>();
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState<OrderBy>({
    requestDate: 'DESC',
  });
  const [query, { onPageChange }] = useEvaluationRequests({
    technician: technician !== undefined ? [technician] : undefined,
    status: [StatusEnum.IN_PROGRESS, StatusEnum.PENDING, StatusEnum.DONE],
    order,
  });

  const data = query.data ?? {
    total: 0,
    pageSize: 10,
    evaluationRequests: [],
    current: 1,
  };
  const pagination: PaginationProps = {
    current: data.current,
    pageSize: data.pageSize,
    total: data.total,
  };

  useEffect(() => {
    setTotalCount(data.total);
  }, [data.total]);

  return (
    <PageLayout
      header={<AuthenticatedHeader selectedKeys={[MenuKeyEnum.weeklyBurden]} />}
      breadcrumbSections={breadcrumbSections}
      breadcrumbLevelActions={
        <>
          <Typography.Text>Technicien </Typography.Text>
          <Select
            value={technician}
            onChange={(value) => setTechnician(value)}
            allowClear
            style={{ width: 200 }}
          >
            {technicians.data?.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </>
      }
    >
      <AuthorizedAccessOnly policy={managerOnly} fallback={<NotAuthorized />}>
        <Spacer direction="vertical" size="small">
          <EvaluationRequestCount totalCount={totalCount} />
          <Table<EvaluationRequest>
            columns={columns(t)}
            dataSource={data.evaluationRequests}
            loading={query.isLoading}
            pagination={pagination}
            rowKey={(record) => record.id}
            onChange={(page, _filters, orderBy) => {
              onPageChange(page);
              if (!Array.isArray(orderBy)) {
                setOrder({
                  [orderBy.columnKey as any]:
                    orderBy.order === 'ascend' ? 'ASC' : 'DESC',
                });
              }
            }}
            size="middle"
          />
        </Spacer>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default WeeklyBurdenPage;
