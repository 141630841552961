import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { Input } from 'antd';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import { Technology, TechnologyFormValues } from 'business/technology/types';
import { technologyKeys } from 'business/technology/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';

const { TextArea } = Input;

const config: DataManagementConfig<
  Technology,
  Pick<Technology, 'name' | 'description'>
> = {
  entity: 'technology',
  hooks: createAdminHooks({
    keys: technologyKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/technology', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post(`/v1/technology`, {
        name: entity.name,
        description: entity.description,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/technology/${entity.id}`, {
        name: entity.name,
        description: entity.description,
      });
    },
    async del(id) {
      return axios.delete(`/v1/technology/${id}`);
    },
  }),
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<TechnologyFormValues>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={20}
            />
          );
        },
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => {
          return (
            <InputField<TechnologyFormValues>
              valueKey="description"
              readOnly={!permissions.update(entity)}
              component={TextArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              showCount
              maxLength={100}
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(20).required(),
    description: yup.string().trim().min(1).max(100).required(),
  }),
  defaultValues: {
    name: '',
    description: '',
  },
  permissions: {},
};

export default config;
