export const sequenceTypes = [
  {
    id: 'C',
    name: 'Charge',
  },
  {
    id: 'D',
    name: 'Decharge',
  },
  {
    id: 'R',
    name: 'Repos',
  },
];
