import { useTranslation } from 'react-i18next';
import { getPageTradKey } from '../utils/getPageTradKey';

/**
 * return sections translated to be displayed
 * @param pages the array of sections, must exist in pages trad file : { pages : { title : '...' } }
 */
export const usePagesTranslation = (pages: string[]) => {
  const { t } = useTranslation();

  return pages.map((page) => ({
    key: page,
    label: t(getPageTradKey(page)),
  }));
};
