import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form as AntForm } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import * as yup from 'yup';
import InputField from '../../../../technical/form/formik/InputField';
import Button from '../../../../ui/button';

export interface MailFormValues {
  textContent: string;
}

const initialValues: MailFormValues = {
  textContent: '',
};

const mailValidationSchema = yup.object({
  textContent: yup.string().required('common.form.validation.required'),
});

interface MailFormProps {
  subject?: string;
  onSubmit: (
    values: MailFormValues,
    formikHelpers: FormikHelpers<MailFormValues>,
  ) => void;
  isLoading: boolean;
  className?: string;
}
const MailForm: React.FC<MailFormProps> = ({
  onSubmit,
  isLoading,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Formik<MailFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={mailValidationSchema}
    >
      {({ submitForm }) => (
        <AntForm layout="vertical" className={className}>
          <AntForm.Item label={t('sendComment.addCommentToApplicant')}>
            <InputField<MailFormValues>
              valueKey="textContent"
              component={TextArea}
              showCount
              maxLength={500}
            />
          </AntForm.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            onClick={submitForm}
          >
            {t('sendComment.sendCommentToApplicant')}
          </Button>
        </AntForm>
      )}
    </Formik>
  );
};

export default MailForm;
