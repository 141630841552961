import React, { useState, useEffect } from 'react';
import PageLayout from 'ui/PageLayout';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { useEvaluationRequests } from 'business/evaluationRequest/hooks/queries';
import { ColumnsType } from 'antd/lib/table';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import EvaluationStatusTag from 'business/evaluationRequest/components/evaluationRequestStatus';
import Table from 'ui/table';
import { PaginationProps, Tabs } from 'antd';
import Flex from 'ui/flex';
import { format } from 'date-fns';
import match from 'technical/utils/match';
import {
  isStatusDone,
  StatusEnum,
} from 'business/evaluationRequest/utils/evaluationRequestStatus';
import { OrderBy } from 'business/evaluationRequest/components/search/searchFilter/types';
import EllipsisTooltip from 'ui/ellipsisTooltip';
import { distinct } from 'technical/utils/array';
import Spacer from 'ui/spacer';
import EvaluationRequestCount from 'business/evaluationRequest/components/evaluationRequestCount';
import { usePagesTranslation } from '../../../../technical/translate/hooks/usePagesTranslation';
import Button from '../../../../ui/button';
import Routes from '../../../router/routes';
import AuthenticatedHeader from '../../../user/components/authenticatedHeader';
import { MenuKeyEnum } from '../../../applicant/constants/menu';
import {
  augmentUser,
  useAuthenticatedUser,
} from '../../../user/services/queries';
import {
  AuthorizedAccessOnly,
  applicantOnly,
} from 'business/user/services/policy';
import NotAuthorized from 'ui/notAuthrorized';

const currentPage = 'evaluationRequests';
const pagesBreadcrumb = ['home', currentPage];

const TABS = [
  'all',
  'draft',
  'waiting-validation',
  'pending',
  'in-progress',
  'done',
] as const;

const columns = (t: TFunction): ColumnsType<EvaluationRequest> => [
  {
    title: t('evaluationRequest.table.reference'),
    dataIndex: 'reference',
    key: 'reference',
    sorter: true,
    render: (reference) => <>{reference}</>,
  },
  {
    title: t('evaluationRequest.table.applicant'),
    dataIndex: 'applicant',
    key: 'applicant',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (applicant) => (
      <>{applicant ? augmentUser(applicant).fullname : null}</>
    ),
  },
  {
    title: t('evaluationRequest.table.requestDate'),
    dataIndex: 'requestDate',
    key: 'requestDate',
    sorter: true,
    render: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  {
    title: t('evaluationRequest.table.estimatedEndDate'),
    dataIndex: 'estimatedEndDate',
    key: 'estimatedEndDate',
    sorter: true,
    render: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : ''),
  },
  {
    title: t('evaluationRequest.table.priority.shortHeader'),
    dataIndex: 'priority',
    key: 'priority',
    sorter: true,
    // if priority entity does not exists, backend returns null, so we handle this case here
    render: (priority) => <>{priority ? `${priority.name}` : null}</>,
  },
  {
    title: t('evaluationRequest.table.status'),

    dataIndex: 'status',
    key: 'status',
    // if status entity does not exists, backend returns null, so we handle this case here
    render: (status) =>
      status ? <EvaluationStatusTag status={status} /> : null,
  },
  {
    title: t('evaluationRequest.table.subject'),
    dataIndex: 'subject',
    key: 'subject',
    ellipsis: true,
    render: (subject) => (
      <EllipsisTooltip title={subject}>{subject}</EllipsisTooltip>
    ),
  },
  {
    title: t('evaluationRequest.table.productDesignations'),
    dataIndex: 'series',
    key: 'series',
    ellipsis: true,
    render: (_, { series }) => {
      const productNames = distinct(
        (series || []).map((serie) => serie.productDesignation?.name),
      );
      return (
        <EllipsisTooltip
          title={
            series
              ? productNames.map((product) => (
                  <div key={product}>{product}</div>
                ))
              : ''
          }
        >
          {series ? productNames.map((product) => product).join(', ') : ''}
        </EllipsisTooltip>
      );
    },
  },
  {
    title: t('evaluationRequest.table.description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    render: (description) => (
      <EllipsisTooltip title={description}>{description}</EllipsisTooltip>
    ),
  },
  {
    title: t('evaluationRequest.table.action'),
    key: 'action',
    render: (_value, evaluationRequest) => {
      return (
        <Flex alignItems="center" justify="space-around">
          <Link
            to={generatePath(Routes.EvaluationRequestDetail, {
              id: evaluationRequest.id.toString(),
            })}
          >
            {t('evaluation.request.go')}
          </Link>
          {process.env.REACT_APP_REPORT_ACTIVATED === 'true' &&
          isStatusDone(evaluationRequest.status.id) ? (
            <Link
              to={generatePath(Routes.EvaluationRequestReport, {
                id: evaluationRequest.id.toString(),
              })}
            >
              {t('evaluation.request.goToReport')}
            </Link>
          ) : null}
          <Link
            to={generatePath(Routes.CopyEvaluationRequest, {
              id: evaluationRequest.id.toString(),
            })}
          >
            {t('evaluation.request.copy')}
          </Link>
        </Flex>
      );
    },
  },
];

const EvaluationRequestPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthenticatedUser();
  const breadcrumbSections = usePagesTranslation(pagesBreadcrumb);
  const [tab, setTab] = useState<(typeof TABS)[number]>('all');
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState<OrderBy>({
    requestDate: 'DESC',
  });
  const [query, { onPageChange }] = useEvaluationRequests({
    applicant: [user.id],
    status: match(tab, {
      all: undefined,
      draft: [StatusEnum.DRAFT],
      'waiting-validation': [StatusEnum.WAITING_VALIDATION],
      pending: [StatusEnum.PENDING],
      'in-progress': [StatusEnum.IN_PROGRESS],
      done: [StatusEnum.DONE],
    }),
    order,
  });

  const data = query.data ?? {
    total: 0,
    pageSize: 10,
    evaluationRequests: [],
    current: 1,
  };
  const pagination: PaginationProps = {
    current: data.current,
    pageSize: data.pageSize,
    total: data.total,
  };

  useEffect(() => {
    setTotalCount(data.total);
  }, [data.total]);

  return (
    <PageLayout
      header={
        <AuthenticatedHeader selectedKeys={[MenuKeyEnum.evaluationRequests]} />
      }
      breadcrumbSections={breadcrumbSections}
      breadcrumbLevelActions={
        <Button
          type="primary"
          size="middle"
          onClick={() => navigate(Routes.NewEvaluationRequest)}
        >
          {t('evaluationRequest.create.cta')}
        </Button>
      }
    >
      <AuthorizedAccessOnly policy={applicantOnly} fallback={<NotAuthorized />}>
        <Tabs
          activeKey={tab}
          onChange={(key) => setTab(key as any)}
          destroyInactiveTabPane
        >
          {TABS.map((key) => (
            <Tabs.TabPane key={key} tab={t(`evaluationRequest.tabs.${key}`)}>
              <Spacer direction="vertical" size="small">
                <EvaluationRequestCount totalCount={totalCount} />
                <Table<EvaluationRequest>
                  columns={columns(t)}
                  dataSource={data.evaluationRequests}
                  loading={query.isLoading}
                  pagination={pagination}
                  rowKey={(record) => record.id}
                  onChange={(page, _filters, orderBy) => {
                    onPageChange(page);
                    if (!Array.isArray(orderBy)) {
                      setOrder({
                        [orderBy.columnKey as any]:
                          orderBy.order === 'ascend' ? 'ASC' : 'DESC',
                      });
                    }
                  }}
                  size="middle"
                />
              </Spacer>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default EvaluationRequestPage;
