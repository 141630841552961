import { useQuery } from 'react-query';
import { emptyPaginatedData } from 'technical/api/constants';
import { Pagination } from 'technical/hooks/usePagination';
import { selectRecords } from 'technical/selects/selectRecords';
import { getEvaluationsRequestStatuses } from '../api';

export const evaluationRequestStatusKeys = {
  all: [{ scope: 'evaluationRequestStatus' }] as const,
  lists: () =>
    [{ ...evaluationRequestStatusKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...evaluationRequestStatusKeys.lists()[0], search, page }] as const,
};

export function useEvaluationRequestStatus({
  skip = false,
}: {
  skip: boolean;
}) {
  return useQuery(
    evaluationRequestStatusKeys.lists(),
    () => getEvaluationsRequestStatuses(),
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
      enabled: !skip,
    },
  );
}
