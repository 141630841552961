import { useQuery } from 'react-query';
import { fetchRoles } from '../api';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';

const RoleKeys = {
  all: [{ scope: 'role' }] as const,
  lists: () => [{ ...RoleKeys.all[0], entity: 'list' }] as const,
};

export const useRoleList = () => {
  const categoriesQuery = useQuery(RoleKeys.lists(), fetchRoles, {
    select: selectRecords,
    placeholderData: emptyPaginatedData,
  });

  return categoriesQuery;
};
