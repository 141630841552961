import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import { StrictUser, User, UserFormValues } from 'business/user/types';
import { userKeys } from 'business/user/services/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import SelectField from 'technical/form/formik/selectField';
import { Paginated } from 'technical/api/types';
import { useRoleList } from 'business/role/hooks/queries';
import { InviteUser } from 'business/admin/components/inviteUser';
import Button from 'ui/button';
import { EditOutlined } from '@ant-design/icons';
import { removeUndefined } from 'technical/utils/formatters';
import { UserAccessDeletion } from 'business/admin/components/anonymizeUser';
import { deleteRemoveUser } from 'business/admin/api';
import QueryWrapper from 'business/admin/components/queryWrapper';

type UserSearch = {
  firstname: string | null;
  lastname: string | null;
  email: string;
};

const config: DataManagementConfig<StrictUser, UserFormValues, UserSearch> = {
  entity: 'user',
  hooks: createAdminHooks({
    keys: userKeys,
    async fetch(page, search) {
      const result: Paginated<User> = (
        await axios.get('/v1/user', {
          params: {
            ...(page ? getAPITablePosition(page) : {}),
            ...removeUndefined(search || {}),
          },
        })
      ).data;
      return {
        count: result.count,
        records: result.records.map(
          (user) =>
            ({
              roleIds: user.roles.map((role) => role.id),
              ...user,
            }) as StrictUser,
        ),
      };
    },
    async create(entity) {
      return axios.post(`/v1/user`, {
        firstname: entity.firstname,
        lastname: entity.lastname,
        roleIds: entity.roleIds,
        email: entity.email,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/user/${entity.id}`, {
        firstname: entity.firstname,
        lastname: entity.lastname,
        roleIds: entity.roleIds,
        service: entity.service,
      });
    },
    async del(id) {
      return deleteRemoveUser(id);
    },
  }),
  search: {
    guard: (params): params is UserSearch => {
      if ('firstname' in params) {
        if (typeof params.firstname !== 'string') {
          return false;
        }
      }
      if ('lastname' in params) {
        if (typeof params.lastname !== 'string') {
          return false;
        }
      }
      if ('email' in params) {
        if (typeof params.email !== 'string') {
          return false;
        }
      }
      return true;
    },
    fields: ['firstname', 'lastname', 'email'],
  },
  columns(permissions) {
    return [
      {
        dataIndex: 'firstname',
        key: 'firstname',
        width: 140,
        render: () => {
          return (
            <InputField<UserFormValues>
              valueKey="firstname"
              readOnly
              showCount
              maxLength={128}
            />
          );
        },
      },
      {
        dataIndex: 'lastname',
        key: 'lastname',
        width: 140,
        render: () => {
          return (
            <InputField<UserFormValues>
              valueKey="lastname"
              readOnly
              showCount
              maxLength={128}
            />
          );
        },
      },
      {
        dataIndex: 'email',
        key: 'email',
        width: 240,
        render: (_value, entity) => {
          if (entity.email) {
            return (
              <>
                {entity.email !== 'arts@matters.tech' ? (
                  <>
                    <InviteUser
                      userId={entity.id}
                      defaultEmail={entity.email}
                      defaultRoleIds={entity.roleIds}
                    >
                      {({ showModal }) => (
                        <Button
                          icon={<EditOutlined />}
                          size="small"
                          type="default"
                          // shape="circle"
                          onClick={showModal}
                          style={{ marginRight: 6 }}
                        />
                      )}
                    </InviteUser>
                    <UserAccessDeletion
                      userId={entity.id}
                      firstName={entity.firstname || ''}
                      lastName={entity.lastname || ''}
                      email={entity.email}
                    />
                  </>
                ) : null}
                <InputField<UserFormValues> valueKey="email" readOnly />
              </>
            );
          }
          return (
            <InviteUser
              userId={entity.id}
              defaultEmail={entity.email}
              defaultRoleIds={entity.roleIds}
            >
              {({ showModal }) => (
                <Button type="primary" size="small" onClick={showModal}>
                  Inviter l`utilisateur
                </Button>
              )}
            </InviteUser>
          );
        },
      },
      {
        dataIndex: 'roleIds',
        key: 'roleIds',
        width: 230,
        render: (_value, entity) => (
          <QueryWrapper hook={useRoleList}>
            {(query) => (
              <SelectField<UserFormValues>
                valueKey="roleIds"
                loading={query.isLoading}
                disabled={query.isLoading}
                optionsList={query.data}
                readOnly={!permissions.update?.(entity)}
                mode="multiple"
                maxTagCount={1}
              />
            )}
          </QueryWrapper>
        ),
      },
      {
        dataIndex: 'service',
        key: 'service',
        render: () => {
          return (
            <InputField<UserFormValues>
              valueKey="service"
              readOnly
              showCount
              maxLength={100}
            />
          );
        },
      },
      {
        dataIndex: 'kelioId',
        key: 'kelioId',
        width: 80,
        render: () => {
          return <InputField<UserFormValues> valueKey="kelioId" readOnly />;
        },
      },
    ];
  },
  schema: yup.object({
    firstname: yup.string().trim().min(1).max(128).nullable(true),
    lastname: yup.string().trim().min(1).max(128).nullable(true),
    roleIds: yup.array().min(1).of(yup.number().min(1).max(4)),
    email: yup.string().trim().min(1).max(128).required(),
    service: yup.string().trim().min(1).max(100).nullable(),
    kelioId: yup.string().trim().min(1).nullable(),
  }),
  defaultValues: {
    firstname: null,
    lastname: null,
    roleIds: [],
    email: '',
    service: null,
    kelioId: null,
  },
  permissions: {
    add: () => false,
    delete: () => true,
    // update: (entity) => entity.email !== 'arts@matters.tech',
  },
};

export default config;
