import React from 'react';
import { Tabs } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import AuthenticatedHeader from 'business/user/components/authenticatedHeader';
import { adminOnly, AuthorizedAccessOnly } from 'business/user/services/policy';
import PageLayout from 'ui/PageLayout';
import NotAuthorized from 'ui/notAuthrorized';
import ReportManager from 'business/admin/components/reportManager';
import DataManager from '../../components/dataManager';
import styles from './index.module.scss';
import entities from './entities';

const DataManagementPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = location.pathname.split('/');
  const currentPage: string | undefined =
    paths.length === 4 ? paths[paths.length - 1] : entities[0]?.entity;

  // If the path is /admin/data-management, we redirect to the first tab
  const pathname = location.pathname + (paths.length === 4 ? '/..' : '');

  return (
    <PageLayout header={<AuthenticatedHeader />}>
      <AuthorizedAccessOnly policy={adminOnly} fallback={<NotAuthorized />}>
        <Tabs
          tabPosition="left"
          className={styles.dataManagementNavbar}
          destroyInactiveTabPane
          onTabClick={(key) =>
            navigate({ pathname: `${pathname}/${key}` }, { replace: true })
          }
          activeKey={currentPage}
        >
          <>
            {entities.map((config) => {
              return (
                <Tabs.TabPane
                  tab={t(`pages.dataManagement.entity.${config.entity}.tab`)}
                  key={config.entity}
                >
                  <DataManager<any, any, any> config={config} />
                </Tabs.TabPane>
              );
            })}
            <Tabs.TabPane
              tab={t(`pages.dataManagement.entity.report.tab`)}
              key="report"
            >
              <ReportManager />
            </Tabs.TabPane>
          </>
        </Tabs>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
};

export default DataManagementPage;
