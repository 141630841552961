import React from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button, Table as AntTable, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table/interface';
import Label from 'technical/form/formik/label';
import {
  SearchableSelectArtsReference,
  SearchableSelectProductionDesignation,
} from 'business/productDesignation/component/select';
import Flex from '../../../../ui/flex';
import { AdvancedFormValues } from '../../types';
import InputField from '../../../../technical/form/formik/InputField';
import Table from '../../../../ui/table';
import { SerieFormValues } from '../../../serie/types';
import { generateLocalId } from '../../../../technical/utils/generateLocalId';

const { Column } = AntTable;

export const emptyFormSerie = {
  productDesignationId: null,
  nominalCapacity: null,
  numberOfElement: null,
  description: null,
};

interface SeriesFieldArrayProps {
  readOnly?: boolean;
}

const SeriesFieldArray: React.FC<SeriesFieldArrayProps> = ({
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const {
    values: { series },
  } = useFormikContext<AdvancedFormValues>();

  const columns: ColumnType<SerieFormValues>[] = [
    {
      title: (
        <Label>{t('evaluationRequest.creation.advanced.serie.index')}</Label>
      ),
      dataIndex: 'serieNumber',
      key: 'serieNumber',
      width: 5,
      render: (_v: number | undefined, _r: SerieFormValues, index: number) => {
        return series[index].serieNumber;
      },
    },
    {
      title: (
        <Label required>
          {t('evaluationRequest.creation.advanced.serie.productDesignationId')}
        </Label>
      ),
      dataIndex: 'productDesignationId',
      key: 'productDesignationId',
      render: (_value: string, _row: SerieFormValues, index: number) => {
        return (
          <SearchableSelectProductionDesignation<AdvancedFormValues>
            valueKey={`series.${index}.productDesignationId`}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label required>
          {t('evaluationRequest.creation.advanced.serie.refArt')}
        </Label>
      ),
      dataIndex: 'productDesignationId',
      key: 'refArt',
      render: (_value: string, _row: SerieFormValues, index: number) => {
        return (
          <SearchableSelectArtsReference<AdvancedFormValues>
            valueKey={`series.${index}.productDesignationId`}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label required>
          {t('evaluationRequest.creation.advanced.serie.nominalCapacity')}
        </Label>
      ),
      dataIndex: 'nominalCapacity',
      key: 'nominalCapacity',
      render: (value: number, _row: SerieFormValues, index: number) => {
        return (
          <InputField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            type="number"
            min={0} // negative nominal capacity do not exist
            valueKey={`series.${index}.nominalCapacity`}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label required>
          {t('evaluationRequest.creation.advanced.serie.numberOfElement')}
        </Label>
      ),
      dataIndex: 'numberOfElement',
      key: 'numberOfElement',
      render: (value: number, _row: SerieFormValues, index: number) => {
        return (
          <InputField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            type="number"
            min={0}
            valueKey={`series.${index}.numberOfElement`}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label>
          {t('evaluationRequest.creation.advanced.serie.description')}
        </Label>
      ),
      dataIndex: 'description',
      key: 'description',
      render: (value: string, _row: SerieFormValues, index: number) => {
        return (
          <InputField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            valueKey={`series.${index}.description`}
            readOnly={readOnly}
          />
        );
      },
    },
  ];

  return (
    <FieldArray
      name="series"
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <>
          <Typography.Title level={4}>
            {t('evaluationRequest.creation.advanced.serie.title')}
          </Typography.Title>
          <Table
            pagination={false}
            rowKey={({ id }) => id}
            // antDesign table index is typed as possibly undefined, index is deprecated
            dataSource={series}
          >
            {columns.map((column) => (
              <Column {...column} />
            ))}
            {!readOnly && (
              <Column<SerieFormValues>
                dataIndex="index"
                title={t('evaluationRequest.creation.advanced.serie.actions')}
                key="actions"
                render={(
                  _value,
                  {
                    productDesignationId,
                    nominalCapacity,
                    numberOfElement,
                    description,
                  }: SerieFormValues,
                  index,
                ) => {
                  return (
                    <Flex>
                      <Button
                        type="link"
                        size="small"
                        onClick={() =>
                          arrayHelpers.push(
                            generateLocalId({
                              productDesignationId,
                              nominalCapacity,
                              numberOfElement,
                              description,
                            }),
                          )
                        }
                      >
                        {t('common.duplicate')}
                      </Button>
                      <Button
                        type="link"
                        size="small"
                        disabled={series.length <= 1}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        {t('common.delete')}
                      </Button>
                    </Flex>
                  );
                }}
              />
            )}
          </Table>
          {!readOnly && (
            <Flex>
              <Button
                icon={<PlusOutlined />}
                size="large"
                block
                onClick={() =>
                  arrayHelpers.push(generateLocalId(emptyFormSerie))
                }
              >
                {t('evaluationRequest.creation.advanced.addSerie')}
              </Button>
            </Flex>
          )}
        </>
      )}
    />
  );
};

export default SeriesFieldArray;
