import React from 'react';
import { Input, InputProps } from 'antd';
import { Field as FormikField, useFormikContext } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import { TextAreaProps } from 'antd/es/input';
import get from 'lodash.get';
import FieldError from '../fieldError';
import { RecursiveFormNames } from '../types';
import ReadonlyField from '../ReadonlyField';

type FieldProps<T extends object> = Omit<
  FieldAttributes<InputProps & TextAreaProps>,
  'name' | 'onChange' | 'value' | 'id'
> & {
  valueKey: RecursiveFormNames<T>;
  readOnly?: boolean;
};

/**
 * Ant design input formik controlled
 * avoid onChange duplication and stupid error like typo in id or onChange
 * @param valueKey
 * @param otherProps
 * @constructor
 */
function InputField<T extends object>({
  valueKey,
  readOnly,
  ...otherProps
}: FieldProps<T>) {
  const { values, setFieldValue, errors, touched } = useFormikContext<T>();

  return (
    <>
      {readOnly ? (
        <ReadonlyField value={get(values, valueKey) as any} />
      ) : (
        <>
          <FormikField
            component={Input}
            id={valueKey}
            name={valueKey}
            value={get(values, valueKey)}
            type="text"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(valueKey as string, value);
            }}
            {...otherProps}
            data-test-id={valueKey}
          />
          <FieldError<T>
            errors={errors}
            touched={touched}
            valueKey={valueKey}
          />
        </>
      )}
    </>
  );
}

export default InputField;
