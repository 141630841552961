import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { format } from 'date-fns';
import { Input } from 'antd';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import {
  ClientSupplier,
  ClientSupplierFormValues,
} from 'business/clientSupplier/types';
import { clientSupplierKeys } from 'business/clientSupplier/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import { removeUndefined } from 'technical/utils/formatters';
import ManagerContextReadOnly from 'business/admin/components/managerContext';

const { TextArea } = Input;

type ClientSupplierSearch = {
  name: string;
};

const config: DataManagementConfig<
  ClientSupplier,
  Pick<ClientSupplier, 'name' | 'description' | 'qadId'>,
  ClientSupplierSearch
> = {
  entity: 'client-supplier',
  hooks: createAdminHooks({
    keys: clientSupplierKeys,
    async fetch(page, search) {
      return (
        await axios.get('/v1/client-supplier', {
          params: {
            ...(page ? getAPITablePosition(page) : {}),
            ...removeUndefined(search || {}),
          },
        })
      ).data;
    },
    async create(entity) {
      return axios.post(`/v1/client-supplier`, {
        name: entity.name,
        description: `${format(new Date(), 'dd-MM-yyyy')}
${entity.description}`,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/client-supplier/${entity.id}`, {
        name: entity.name,
        description: entity.description,
      });
    },
    async del(id) {
      return axios.delete(`/v1/client-supplier/${id}`);
    },
  }),
  search: {
    guard: (params): params is ClientSupplierSearch => {
      if ('name' in params) {
        return typeof params.name === 'string';
      }
      return false;
    },
    fields: ['name'],
  },
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ClientSupplier>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ClientSupplierFormValues>
                valueKey="name"
                readOnly={isReadOnly}
                maxLength={50}
                showCount
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => (
          <ManagerContextReadOnly<ClientSupplier>
            entity={entity}
            permissions={permissions}
          >
            {(isReadOnly) => (
              <InputField<ClientSupplierFormValues>
                valueKey="description"
                readOnly={isReadOnly}
                component={TextArea}
                autoSize={{ minRows: 1, maxRows: 5 }}
                maxLength={100}
                showCount
              />
            )}
          </ManagerContextReadOnly>
        ),
      },
      {
        dataIndex: 'qadId',
        key: 'qadId',
        render: () => {
          return (
            <InputField<ClientSupplierFormValues> valueKey="qadId" readOnly />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(50).required(),
    description: yup.string().trim().min(1).max(100),
  }),
  defaultValues: {
    name: '',
    description: '',
    qadId: null,
  },
  permissions: {
    delete: (clientSupplier) => !clientSupplier.qadId && !clientSupplier.source,
    update: (clientSupplier) => !clientSupplier.qadId && !clientSupplier.source,
  },
};

export default config;
