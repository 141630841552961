import { Bench } from 'business/bench/types';
import { ProductDesignation } from 'business/productDesignation/types';
import { Paginated } from '../../technical/api/types';
import { Category } from '../category/types';
import { Serie, SerieFormValues } from '../serie/types';
import {
  EvaluationStep,
  EvaluationStepFormValues,
} from '../evaluationStep/types';
import { EvaluationRequestStatus } from '../evaluationRequestStatus/types';
import { AugmentedUser } from '../user/types';
import { Priority } from '../priority/types';

export interface EvaluationRequest {
  id: number;

  type: 'simple' | 'advanced';

  reference: string; // numDemandeEssai
  subject: string | null;
  description: string | null;
  requestDate: Date | null;
  priorityId: number | null;
  priority?: Priority;

  qualityPlanNumber: string | null;

  technicianComment: string | null;
  technicianId: number | null;
  technician?: {
    id: number;
    firstname: string;
    lastname: string;
  };
  startDate: Date | null; // dateDebutEssai
  endDate: Date | null;
  maxStartDate: Date | null;
  estimatedEndDate: Date | null;
  intermediateExitDate: Date | null;
  statusId: number;
  status: EvaluationRequestStatus;
  project: string | null;

  BRNumber: string | null;

  applicantId: number;
  applicant?: AugmentedUser; // todo add type
  applicantComment: string | null;

  productId: number | null;

  ovenId: number | null;

  orderedQuantity: number | null;
  toTestQuantity: number | null;

  lotNumber: string | null;

  technologyId: number | null;

  clientSupplierId?: number | null;

  purchaseOrder: string | null;

  nominalCapacity: number | null;

  productDesignationId: number | null;
  productDesignation: ProductDesignation | null;

  commandNumber?: string | null;

  categoryId?: number | null;
  category?: Category | null;

  series?: Serie[];
  steps?: EvaluationStep[];

  managerId: number | null;
  manager?: {
    id: number;
    firstname: string;
    lastname: string;
  };

  conclusionTechnician?: string | null;
  conclusionManager?: string | null;
  conclusionApplicant?: string | null;
}

export interface EvaluationRequestWithSteps extends EvaluationRequest {
  steps: EvaluationStep[];
}

export type PaginatedEvaluationRequests = Paginated<EvaluationRequest>;

// used in creation form :
export interface FormValues
  extends Partial<Omit<EvaluationRequest, 'reference' | 'series' | 'steps'>> {
  series: SerieFormValues[];
  steps: EvaluationStepFormValues[];
}

export type SimpleFormValues = Pick<
  FormValues,
  // status set on save
  | 'statusId'
  // fields :
  | 'applicantId'
  | 'requestDate'
  | 'priorityId'
  // maxStartDate is computed locally
  | 'estimatedEndDate'
  | 'intermediateExitDate'
  | 'categoryId'
  | 'clientSupplierId' // providerClientId in postman post example
  | 'description'
  | 'project'
  | 'technologyId'
  | 'productId'
  | 'subject'
  | 'toTestQuantity'
  | 'technician'
  | 'manager'
  | 'conclusionTechnician'
  | 'conclusionManager'
  | 'conclusionApplicant'

  //
  | 'productDesignationId'
  | 'nominalCapacity'
  // arts_ref
  | 'lotNumber'
  | 'commandNumber'
  | 'orderedQuantity'
  | 'BRNumber'
  | 'purchaseOrder'
  | 'qualityPlanNumber'
>;

export type AdvancedFormValues = Pick<
  FormValues,
  // status set on save
  | 'statusId'
  // fields :
  | 'subject'
  | 'applicantId'
  | 'requestDate'
  | 'priorityId'
  | 'project'
  // maxStartDate is computed locally using priority
  | 'estimatedEndDate'
  | 'intermediateExitDate'
  | 'categoryId'
  | 'clientSupplierId' // providerClientId in postman post example
  | 'technologyId'
  | 'productId'
  | 'description'
  | 'technician'
  | 'manager'
  | 'conclusionTechnician'
  | 'conclusionManager'
  | 'conclusionApplicant'
  //
  | 'lotNumber'
  | 'commandNumber'
  | 'orderedQuantity'
  | 'BRNumber'
  | 'purchaseOrder'
  | 'qualityPlanNumber'
  | 'nominalCapacity'
  //
  | 'series'
  | 'steps'
>;

export type ScheduleEvaluationRequestFormValues = {
  estimatedEndDate?: Date;
};

export type EvaluationStepCommentFormValues = {
  comment?: string;
};

export const preValidationEditableFields = [
  'subject',
  'priorityId',
  'estimatedEndDate',
  'intermediateExitDate',
  'technologyId',
  'productId',
  'categoryId',
  'clientSupplierId',
  'project',
  'lotNumber',
  'commandNumber',
  'orderedQuantity',
  'BRNumber',
  'purchaseOrder',
  'qualityPlanNumber',
  'productDesignationId',
  'nominalCapacity',
  'toTestQuantity',
  'description',
  'series',
  'steps',
];

export const postValidationEditableFields = [
  'estimatedEndDate',
  'intermediateExitDate',
];

export interface PositionItem {
  element: string;
  benchId: number;
  benchType: number;
  lane: number;
  stepId: number;
}

export interface PositionItemsByBench {
  benchId: number | null;
  benchType: number | null;
  nItems: number | null;
  benchProgram?: string;
  positionItems: PositionItem[];
}

export interface PositionItemsFormValues {
  itemPositions: Partial<PositionItemsByBench>[];
}

export interface CreatePositionsArgs {
  programs: Array<{
    benchId: number;
    program: string;
  }>;
  positions: Array<{
    benchId: number;
    element: string;
    lane: number;
  }>;
}

export interface FetchedPositionItem {
  id: number;
  bench: Bench;
  benchId: number;
  element: string;
  lane: number;
  stepId: number;
}

export type PaginatedFetchedPositionItems = Paginated<FetchedPositionItem>;
