import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchProductList } from '../api/fetchProductList';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { emptyPaginatedData } from '../../../technical/api/constants';

export const productKeys = {
  all: [{ scope: 'product' }] as const,
  lists: () => [{ ...productKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...productKeys.lists()[0], search, page }] as const,
};

export const useProductsList = ({ skip }: { skip?: boolean }) => {
  const productsQuery = useQuery(
    productKeys.lists(),
    () => fetchProductList(),
    {
      select: selectRecords,
      placeholderData: emptyPaginatedData,
      enabled: !skip,
    },
  );

  return productsQuery;
};
