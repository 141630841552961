import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { emptyPaginatedData } from 'technical/api/constants';
import { Pagination } from 'technical/hooks/usePagination';
import { fetchAnomaly, upsertAnomaly, fetchOneAnomaly } from '../api';
import { AnomalyFormValues } from '../types';

const anomalyKeys = {
  all: [{ scope: 'anomaly' }] as const,
  lists: () => [{ ...anomalyKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...anomalyKeys.lists()[0], search, page }] as const,
  details: () => [{ ...anomalyKeys.all[0], entity: 'detail' }] as const,
  detail: (id?: string) => [{ ...anomalyKeys.details()[0], id }] as const,
};

export const useUpsertAnomaly = (id?: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    (anomaly: AnomalyFormValues) => upsertAnomaly(anomaly, id),
    {
      onSuccess() {
        notification.success({ message: t('toast.creation.success') });
        queryClient.invalidateQueries(anomalyKeys.all);
      },
      onError() {
        notification.error({ message: t('toast.creation.error') });
      },
    },
  );
};

export const useFetchAnomaly = (page: Pagination) => {
  return useQuery(anomalyKeys.list(page, null), () => fetchAnomaly(page), {
    placeholderData: emptyPaginatedData,
  });
};

export const useFetchOneAnomaly = (id?: string) => {
  return useQuery(anomalyKeys.detail(id), () => fetchOneAnomaly(id), {
    enabled: !!id,
  });
};
