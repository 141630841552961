import React from 'react';
import { Modal } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { useUserAccessDeletion } from 'business/admin/hooks/actions';

export interface UserAccessDeletionProps {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
}
export function UserAccessDeletion({
  userId,
  firstName,
  lastName,
  email,
}: UserAccessDeletionProps) {
  const { t } = useTranslation();
  const { mutate } = useUserAccessDeletion();

  const handleOk = () => {
    mutate({ id: userId });
  };

  const warnModal = () =>
    Modal.confirm({
      onOk: handleOk,
      title: t('admin.user.anonymize.title'),
      cancelText: t('admin.user.anonymize.cancel'),
      okText: t('admin.user.anonymize.submit'),
      content: (
        <>
          <p>{t('admin.user.anonymize.message')}</p>
          <p>
            {t('admin.user.anonymize.detail', {
              name: `${firstName} ${lastName}`,
              email,
            })}
          </p>
        </>
      ),
    });

  return (
    <Button
      icon={<UserDeleteOutlined />}
      size="small"
      type="default"
      onClick={warnModal}
      style={{ marginRight: 6 }}
    />
  );
}
