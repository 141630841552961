import React, { useCallback } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button, Table as AntTable, Typography } from 'antd';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table/interface';
import Label from 'technical/form/formik/label';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DraggableBodyRow from 'ui/draggableBodyRow';
import { StatusEnum } from 'business/evaluationRequest/utils/evaluationRequestStatus';
import Flex from '../../../../ui/flex';
import { AdvancedFormValues, EvaluationRequest } from '../../types';
import InputField from '../../../../technical/form/formik/InputField';
import Table from '../../../../ui/table';
import SelectField from '../../../../technical/form/formik/selectField';
import { EvaluationStepFormValues } from '../../../evaluationStep/types';
import { useEvaluationsList } from '../../../evaluation/hooks/queries';
import { generateLocalId } from '../../../../technical/utils/generateLocalId';
import styles from './index.module.scss';

const { Column } = AntTable;

export const emptyFormEvaluationStep = {
  evaluationId: null,
  description: null,
  fileLink: null,
};
interface EvaluationStepsFieldArrayProps {
  readOnly?: boolean;
}

const EvaluationStepsFieldArray: React.FC<EvaluationStepsFieldArrayProps> = ({
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const { data: evaluations } = useEvaluationsList();
  const { values, setFieldValue } = useFormikContext<EvaluationRequest>();
  const allowDnD = values.statusId < StatusEnum.PENDING;

  const columns: ColumnType<EvaluationStepFormValues>[] = [
    ...(allowDnD
      ? [
          {
            key: 'drag',
            render: () => <MenuOutlined className={styles.draggable} />,
          },
        ]
      : []),
    {
      title: (
        <Label>{t('evaluationRequest.creation.evaluationStep.index')}</Label>
      ),
      dataIndex: 'index',
      key: 'index',
      width: 5,
      render: (
        _v: number | undefined,
        _r: EvaluationStepFormValues,
        index: number,
      ) => {
        return index + 1;
      },
    },
    {
      title: (
        <Label required>
          {t('evaluationRequest.creation.evaluationStep.evaluation')}
        </Label>
      ),
      dataIndex: 'evaluationId',
      key: 'evaluationId',
      render: (
        value: string,
        _row: EvaluationStepFormValues,
        index: number,
      ) => {
        return (
          <SelectField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            valueKey={`steps.${index}.evaluationId`}
            optionsList={evaluations?.map((evaluation) => {
              const { disabled, ...rest } = evaluation;
              return {
                ...rest,
                name: `${rest.identifier} / ${rest.name}`,
              };
            })}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label>
          {t('evaluationRequest.creation.evaluationStep.description')}
        </Label>
      ),
      dataIndex: 'description',
      key: 'description',
      render: (
        value: string,
        _row: EvaluationStepFormValues,
        index: number,
      ) => {
        return (
          <InputField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            valueKey={`steps.${index}.description`}
            readOnly={readOnly}
          />
        );
      },
    },
    {
      title: (
        <Label>{t('evaluationRequest.creation.evaluationStep.fileLink')}</Label>
      ),
      dataIndex: 'fileLink',
      key: 'fileLink',
      render: (
        value: string,
        _row: EvaluationStepFormValues,
        index: number,
      ) => {
        return (
          <InputField<AdvancedFormValues>
            defaultValue={value} // had to add defaultValue to make copy work
            valueKey={`steps.${index}.fileLink`}
            readOnly={readOnly}
          />
        );
      },
    },
  ];

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!values.steps) {
        return;
      }

      const dragRow = values.steps[dragIndex];
      // immutability-helper package is recommended by AntD for to update the array
      // Its use is simple for create a drag and drop. More: https://ant.design/components/table/#components-table-demo-drag-sorting
      const updated = update(values.steps, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setFieldValue(
        'steps',
        updated.map((step, index) => ({ ...step, order: index + 1 })),
      );
    },
    [values.steps],
  );

  return (
    <FieldArray
      name="steps"
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <>
          <Typography.Title level={4}>
            {t('evaluationRequest.creation.evaluationStep.title')}
          </Typography.Title>
          {/* The DndProvider component provides React-DnD capabilities for drag and drop.
            More here: https://react-dnd.github.io/react-dnd/docs/api/dnd-provider */}
          <DndProvider backend={HTML5Backend}>
            <Table
              pagination={false}
              rowKey={(record) => record.id}
              // index is typed as possibly undefined, index is deprecated
              // we generate random local id when creating a new row
              dataSource={arrayHelpers.form.values.steps}
              components={{
                body: {
                  row: allowDnD ? DraggableBodyRow : undefined,
                },
              }}
              onRow={(_, index) =>
                ({ index, moveRow }) as React.HTMLAttributes<any>
              }
            >
              {columns.map((column) => (
                <Column {...column} />
              ))}
              {!readOnly && (
                <Column<EvaluationStepFormValues>
                  dataIndex="index"
                  title={t('evaluationRequest.creation.evaluationStep.actions')}
                  key="actions"
                  render={(
                    _value,
                    {
                      evaluationId,
                      description,
                      fileLink,
                    }: EvaluationStepFormValues,
                    index,
                  ) => {
                    return (
                      <Flex>
                        <Button
                          type="link"
                          size="small"
                          onClick={() =>
                            arrayHelpers.push(
                              generateLocalId({
                                evaluationId,
                                description,
                                fileLink,
                              }),
                            )
                          }
                        >
                          {t('common.duplicate')}
                        </Button>
                        <Button
                          type="link"
                          size="small"
                          disabled={arrayHelpers.form.values.steps.length <= 1}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          {t('common.delete')}
                        </Button>
                      </Flex>
                    );
                  }}
                />
              )}
            </Table>
          </DndProvider>
          {!readOnly && (
            <Flex>
              <Button
                icon={<PlusOutlined />}
                size="large"
                block
                onClick={() =>
                  arrayHelpers.push(generateLocalId(emptyFormEvaluationStep))
                }
              >
                {t('evaluationRequest.creation.advanced.addEvaluationStep')}
              </Button>
            </Flex>
          )}
        </>
      )}
    />
  );
};

export default EvaluationStepsFieldArray;
