import React, { useState } from 'react';
import { Spin, TablePaginationConfig, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetchAllBenchPrograms } from 'business/benchProgram/hooks/queries';
import Table from 'ui/table';
import { ColumnsType } from 'antd/lib/table';
import Spacer from 'ui/spacer';
import { BenchProgramWithNItems } from '../../types';

interface BenchProgramsListProps {}

const BenchProgramsList: React.FC<BenchProgramsListProps> = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 10);
  };
  const { data, isLoading } = useFetchAllBenchPrograms({
    current: currentPage,
    pageSize,
  });
  const { records: programs, count } = data || {};

  const columns: ColumnsType<BenchProgramWithNItems> = [
    {
      title: t('pages.benchProgramsList.columns.evaluationRequestNumber'),
      dataIndex: 'evaluationRequestNumber',
      key: 'evaluationRequestNumber',
      render(_value, program) {
        return program.step.evaluationRequest.reference;
      },
    },
    {
      title: t('pages.benchProgramsList.columns.programNumber'),
      dataIndex: 'program',
      key: 'program',
    },
    {
      title: t('pages.benchProgramsList.columns.step'),
      dataIndex: 'step',
      key: 'step',
      render(_value, program) {
        return `Étape ${program.step.order} (${program.step.evaluation?.identifier} ${program.step.evaluation?.name})`;
      },
    },
    {
      title: t('pages.benchProgramsList.columns.bench'),
      dataIndex: 'bench',
      key: 'bench',
      render(_value, program) {
        return program.bench.name;
      },
    },
    {
      title: t('pages.benchProgramsList.columns.nItems'),
      dataIndex: 'nItems',
      key: 'nItems',
    },
  ];

  const dataSource = programs?.map((program) => ({
    key: program.id,
    ...program,
  }));

  return (
    <Spacer direction="vertical">
      <Typography.Title level={4}>
        {t('pages.benchProgramsList.title')}
      </Typography.Title>
      {isLoading && <Spin />}
      {!isLoading && (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ current: currentPage, pageSize, total: count }}
          onChange={onPaginationChange}
        />
      )}
    </Spacer>
  );
};

export default BenchProgramsList;
