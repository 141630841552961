import React, { ChangeEvent, createRef, useCallback } from 'react';
// import { Button as AntdButton } from 'antd';
import Button, { ButtonProps } from '../button';

export interface ImportFileButtonProps extends ButtonProps {
  onFileChange: (file: File | null) => void;
  accept?: string;
}

const ImportFileButton = ({
  onFileChange,
  accept,
  ...props
}: ImportFileButtonProps) => {
  const inputRef = createRef<HTMLInputElement>();

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFileChange(event.target.files ? event.target.files[0] : null);
    },
    [onFileChange],
  );

  return (
    <>
      <Button
        {...props}
        loading={false}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      />
      <input
        type="file"
        ref={inputRef}
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default ImportFileButton;
