import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { DataManagementConfig } from 'business/admin/types';
import { BenchType } from 'business/benchType/types';
import { createAdminHooks } from 'business/admin/services';
import { benchTypeKeys } from 'business/benchType/hooks/queries';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import InputField from 'technical/form/formik/InputField';

const config: DataManagementConfig<BenchType, Omit<BenchType, 'id'>> = {
  entity: 'bench-type',
  hooks: createAdminHooks({
    keys: benchTypeKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/bench-type', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post('/v1/bench-type', {
        name: entity.name,
        description: entity.description,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/bench-type/${entity.id}`, {
        name: entity.name,
        description: entity.description,
      });
    },
    async del(id) {
      return axios.delete(`/v1/bench-type/${id}`);
    },
  }),
  columns(permissions) {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<BenchType>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={20}
            />
          );
        },
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => {
          return (
            <InputField<BenchType>
              valueKey="description"
              readOnly={!permissions.update(entity)}
              showCount
              maxLength={100}
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(20).required(),
    description: yup.string().trim().min(1).max(100).required(),
  }),
  defaultValues: {
    name: '',
    description: '',
  },
  permissions: {},
};

export default config;
