import React, { useState } from 'react';
import { EvaluationRequest } from 'business/evaluationRequest/types';
import { Formik } from 'formik';
import { AnomalyFormValues, FetchedAnomaly } from 'business/anomaly/types';
import { anomalySchema } from 'business/anomaly/schema/anomalySchema';
import { useFetchBenchPrograms } from 'business/benchProgram/hooks/queries';
import { useUpsertAnomaly } from 'business/anomaly/hooks/queries';
import { Form as AntForm, Modal, Button } from 'antd';
import { sequenceTypes } from 'business/anomaly/utils/sequenceTypes';
import DatePickerField from 'technical/form/formik/datePickerField';
import InputField from 'technical/form/formik/InputField';
import SelectField from 'technical/form/formik/selectField';
import { useTranslation } from 'react-i18next';
import ReadonlyField from 'technical/form/formik/ReadonlyField';

interface CreateAnomalyProps {
  anomaly?: FetchedAnomaly;
  evaluationRequest: EvaluationRequest;
  version?: number;
  visible: boolean;
  cancel: () => void;
}

const CreateAnomaly: React.FC<CreateAnomalyProps> = ({
  evaluationRequest,
  version,
  visible,
  cancel,
  anomaly,
}) => {
  const { t } = useTranslation();
  const [stepId, setStepId] = useState<number | undefined>(anomaly?.stepId);
  const { data: programs } = useFetchBenchPrograms(stepId, version);
  const { mutate, isLoading } = useUpsertAnomaly(anomaly?.id);

  const initialValues: AnomalyFormValues = {
    evaluationRequestId: evaluationRequest.id,
    cycle: anomaly?.cycle,
    stepId: anomaly?.stepId,
    sequenceType: anomaly?.sequenceType,
    programNumber: anomaly?.programNumber,
    date: anomaly ? new Date(anomaly.date) : undefined,
  };

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        mutate(values, {
          onSuccess() {
            cancel();
          },
        });
        resetForm();
      }}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={anomalySchema}
    >
      {({ submitForm, resetForm }) => {
        const programsOptions =
          programs?.map((program) => ({
            name: `${program.program}`,
            id: program.id,
          })) ?? [];

        const stepsOptions =
          evaluationRequest.steps?.map((step) => ({
            name: `Etape ${step.order} - ${step.evaluation?.identifier} ${step.evaluation?.name}`,
            id: step.id,
          })) ?? [];

        const isUpdate = !!anomaly;

        return (
          <Modal
            destroyOnClose
            title={t('pages.anomaly.creation.title')}
            open={visible}
            onCancel={() => {
              cancel();
              resetForm();
            }}
            footer={[
              <Button key="cancel" onClick={cancel}>
                {t('common.cancel')}
              </Button>,
              <Button
                key="addOrUpdate"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={submitForm}
              >
                {t(`common.${isUpdate ? 'update' : 'add'}`)}
              </Button>,
            ]}
          >
            <AntForm layout="vertical" onFinish={submitForm}>
              <AntForm.Item
                label={t('pages.anomaly.creation.evaluationRequestNumber')}
              >
                <ReadonlyField
                  value={
                    evaluationRequest.reference ??
                    anomaly?.evaluationRequest.reference
                  }
                />
              </AntForm.Item>
              <AntForm.Item label={t('pages.anomaly.creation.step')} required>
                <SelectField<AnomalyFormValues>
                  valueKey="stepId"
                  optionsList={stepsOptions}
                  onSelect={(value: any) => setStepId(value)}
                />
              </AntForm.Item>
              <AntForm.Item
                label={t('pages.anomaly.creation.programNumber')}
                required
              >
                <SelectField<AnomalyFormValues>
                  valueKey="programNumber"
                  optionsList={programsOptions}
                />
              </AntForm.Item>
              <AntForm.Item label={t('pages.anomaly.creation.cycle')} required>
                <InputField<AnomalyFormValues> valueKey="cycle" />
              </AntForm.Item>
              <AntForm.Item label={t('pages.anomaly.creation.sequenceType')}>
                <SelectField<AnomalyFormValues>
                  valueKey="sequenceType"
                  optionsList={sequenceTypes}
                />
              </AntForm.Item>
              <AntForm.Item label={t('pages.anomaly.creation.date')} required>
                {/* TODO: make this work with time */}
                <DatePickerField<AnomalyFormValues> valueKey="date" />
              </AntForm.Item>
            </AntForm>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateAnomaly;
