import * as yup from 'yup';

export const reportSchema = yup
  .object({
    report: yup
      .object({
        date: yup.date().nullable(),
        evaluationIds: yup
          .array(yup.number().positive().required())
          .max(5)
          .required(),
      })
      .required(),
  })
  .required();

export type Report = yup.InferType<typeof reportSchema>['report'];
