import { Typography } from 'antd';
import { useFetchEvaluationRequest } from 'business/evaluationRequest/hooks/queries';
import Routes from 'business/router/routes';
import AuthenticatedHeader from 'business/user/components/authenticatedHeader';
import {
  AuthorizedAccessOnly,
  allExemptAdmin,
} from 'business/user/services/policy';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import PageLayout from 'ui/PageLayout';
import NotAuthorized from 'ui/notAuthrorized';
import Spacer from 'ui/spacer';

export default function Report() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <Navigate to={Routes.EvaluationRequests} />; // maybe change to 404
  }

  const { data: evaluationRequest } = useFetchEvaluationRequest(id);

  if (!(process.env.REACT_APP_REPORT_ACTIVATED === 'true')) {
    return <Navigate to={Routes.EvaluationRequests} />; // maybe change to 404
  }

  return (
    <PageLayout header={<AuthenticatedHeader />} breadcrumbLevelActions={null}>
      <AuthorizedAccessOnly
        policy={allExemptAdmin}
        fallback={<NotAuthorized />}
      >
        <Spacer size="small" direction="vertical" style={{ height: '100vh' }}>
          <Typography.Title style={{ fontSize: 20 }}>
            Rapport demande d&apos;essais {evaluationRequest?.reference}
          </Typography.Title>
          {evaluationRequest ? (
            <iframe
              title="ANALYSE_RAPPORT_LE"
              width="100%"
              height="100%"
              src={`${process.env.REACT_APP_REPORT_URL}&filter=LE_MESURE_VOIE/NUM_DEMANDE_ESSAI eq '${evaluationRequest.reference}'`}
              frameBorder="0"
              allowFullScreen
            />
          ) : null}
        </Spacer>
      </AuthorizedAccessOnly>
    </PageLayout>
  );
}
