const Routes = {
  Home: '/',
  NotFound: '/404',

  SignIn: '/sign-in',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',

  EvaluationRequests: '/evaluation-requests/mine',
  WaitingValidation: '/evaluation-requests/waiting-validation',
  ToDo: '/evaluation-requests/to-do',
  WeeklyBurden: '/evaluation-requests/weekly-burden',

  SearchEvaluationRequest: '/evaluation-requests/search',

  NewEvaluationRequest: '/evaluation-requests/new',

  EvaluationRequestDetail: '/evaluation-request/:id',
  EvaluationRequestReport: '/evaluation-request/:id/report',
  CopyEvaluationRequest: '/evaluation-request/:id/copy',

  DataManagement: '/admin/data-management',

  BenchPrograms: '/bench-programs',
  AnomalyList: '/anomaly',
} as const;

export default Routes;
