import product from './product';
import category from './category';
import technology from './technology';
import priority from './priority';
import clientSupplier from './clientSupplier';
import evaluationRequestStatus from './evaluationRequestStatus';
import evaluation from './evaluation';
import productDesignation from './productDesignation';
import user from './user';
import bench from './bench';
import benchType from './benchType';

// this array defines the tab order in the data management page
const entities = [
  user,
  priority,
  technology,
  category,
  product,
  evaluation,
  productDesignation,
  clientSupplier,
  evaluationRequestStatus,
  bench,
  benchType,
];

export default entities;
