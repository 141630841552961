import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from 'technical/hooks/usePagination';
import { emptyPaginatedData } from '../../../technical/api/constants';
import { selectRecords } from '../../../technical/selects/selectRecords';
import { fetchCategoriesList } from '../api/fetchCategoriesList';

export const categoryKeys = {
  all: [{ scope: 'category' }] as const,
  lists: () => [{ ...categoryKeys.all[0], entity: 'list' }] as const,
  list: (page: Pagination, search: null) =>
    [{ ...categoryKeys.lists()[0], search, page }] as const,
};

export const useCategoriesList = ({
  formType,
  skip = false,
}: {
  formType: 'simple' | 'advanced' | 'all';
  skip?: boolean;
}) => {
  const selectByFormType = useCallback(
    (data: Awaited<ReturnType<typeof fetchCategoriesList>>) => {
      return selectRecords(data).filter((el) => {
        switch (formType) {
          case 'simple':
            return [null, 1].includes(el.formType);
          case 'advanced':
            return [null, 2].includes(el.formType);
          default:
            return el.formType === 1 || el.formType === 2;
        }
      });
    },
    [formType],
  );

  const categoriesQuery = useQuery(categoryKeys.lists(), fetchCategoriesList, {
    select: selectByFormType,
    placeholderData: emptyPaginatedData,
    enabled: !skip,
  });

  return categoriesQuery;
};
