// todo add trad key for errors
import * as yup from 'yup';

const advancedSerieSchema = yup.object().shape({
  productDesignationId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  numberOfElement: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .required('common.form.validation.required')
    .nullable(true),
  nominalCapacity: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .required('common.form.validation.required')
    .nullable(true),
  description: yup.string().default('').nullable(true),
});
const advancedEvaluationStepSchema = yup.object({
  evaluationId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  description: yup.string().default('').nullable(true),
  fileLink: yup.string().nullable(true),
});

export const simpleSchema = yup.object({
  subject: yup
    .string()
    .max(500)
    .required('common.form.validation.required')
    .nullable(true),
  priorityId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  technologyId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  productId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  categoryId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  clientSupplierId: yup.number().nullable(true),
  toTestQuantity: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .required('common.form.validation.required')
    .nullable(true),
  description: yup.string().max(500).nullable(true),
  productDesignationId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  lotNumber: yup.string().max(100).nullable(true),
  project: yup.string().max(100).nullable(true),
  purchaseNumber: yup.string().max(100).nullable(true),
  orderedQuantity: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .nullable(true),
  nominalCapacity: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .required('common.form.validation.required')
    .nullable(true),
  steps: yup.array().of(advancedEvaluationStepSchema).nullable(true),
  estimatedEndDate: yup.date().nullable(true),
  intermediateExitDate: yup.date().nullable(true),
});

export const advancedSchema = yup.object({
  priorityId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  categoryId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  clientSupplierId: yup.number().nullable(true),
  technologyId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  productId: yup
    .number()
    .required('common.form.validation.required')
    .nullable(true),
  subject: yup
    .string()
    .max(500)
    .required('common.form.validation.required')
    .nullable(true),
  description: yup.string().max(500).nullable(true),
  nominalCapacity: yup
    .number()
    .positive('common.form.validation.shouldBePositive')
    .required('common.form.validation.required')
    .nullable(true),
  lotNumber: yup.string().max(100).nullable(true),
  project: yup.string().max(100).nullable(true),
  purchaseNumber: yup.string().max(100).nullable(true),

  series: yup
    .array()
    .of(advancedSerieSchema)
    .min(1)
    .required('common.form.validation.required'),
  steps: yup
    .array()
    .of(advancedEvaluationStepSchema)
    .min(1)
    .required('common.form.validation.required'),

  estimatedEndDate: yup.date().nullable(true),
  intermediateExitDate: yup.date().nullable(true),
});

export const scheduleEvaluationRequestSchema = yup.object({
  estimatedEndDate: yup.date().required(),
});

export const evaluationStepCommentSchema = yup.object({
  comment: yup.string().max(500).nullable(true),
});
