import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { postRemoveUserAccess, postUserInvitation } from '../api';
import { userKeys } from '../../user/services/queries';

export const useUserInvitation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(postUserInvitation, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.all);

      notification.success({
        message: t('admin.user.invitation.form.success'),
      });
    },
    onError: () => {
      notification.error({ message: t('admin.user.invitation.form.error') });
    },
  });
};

export const useUserAccessDeletion = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(postRemoveUserAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.all);

      notification.success({
        message: t('admin.user.anonymize.success'),
      });
    },
    onError: () => {
      notification.error({ message: t('admin.user.anonymize.error') });
    },
  });
};
