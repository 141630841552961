import axios from 'axios';
import groupBy from 'lodash.groupby';
import { FilterDefinitions } from './components/search/searchFilter/types';
import {
  CreatePositionsArgs,
  EvaluationRequest,
  FormValues,
  PaginatedEvaluationRequests,
  PaginatedFetchedPositionItems,
} from './types';
import { Pagination } from '../../technical/hooks/usePagination';
import { getAPITablePosition } from '../../technical/utils/getApiTablePosition';

function transformFiltersToQueryParams(search: Partial<FilterDefinitions>) {
  const grouped = groupBy(Object.entries(search), ([, values]) => {
    if (typeof values?.[0] === 'object' && values?.[0] !== null) {
      return 'object' as const;
    }
    return 'primitive' as const;
  });

  const transformed = {
    ...Object.fromEntries(grouped.primitive ?? []),
    ...Object.fromEntries(
      (grouped.object ?? []).flatMap(([filter, values]) =>
        values.flatMap((value, index) => {
          const key = `${filter}[${index}]`;
          return Object.entries(value ?? {}).map(([k, v]) => [
            `${key}[${k}]`,
            v,
          ]);
        }),
      ),
    ),
  };
  return transformed;
}

export const getEvaluationsRequests = async (
  search: Partial<FilterDefinitions>,
  page: Pagination,
): Promise<PaginatedEvaluationRequests> => {
  return (
    await axios.get(`/v1/evaluation-request`, {
      params: {
        ...getAPITablePosition(page),
        ...transformFiltersToQueryParams(search),
      },
    })
  ).data;
};

export const createSimpleEvaluationsRequest = async (
  params: FormValues,
): Promise<PaginatedEvaluationRequests> => {
  return (await axios.post(`/v1/evaluation-request/simple`, params)).data;
};

export const updateSimpleEvaluationsRequest = async (
  params: FormValues,
): Promise<PaginatedEvaluationRequests> => {
  return (await axios.put(`/v1/evaluation-request/simple/${params.id}`, params))
    .data;
};

export const createAdvancedEvaluationsRequest = async (
  params: FormValues,
): Promise<PaginatedEvaluationRequests> => {
  return (await axios.post(`/v1/evaluation-request/advanced`, params)).data;
};

export const updateAdvancedEvaluationsRequest = async (
  params: FormValues,
): Promise<PaginatedEvaluationRequests> => {
  return (
    await axios.put(`/v1/evaluation-request/advanced/${params.id}`, params)
  ).data;
};

export const deleteEvaluationRequest = async (id: number): Promise<void> => {
  return (await axios.delete(`/v1/evaluation-request/${id.toString()}`)).data;
};

export const updateEvaluationRequestStatus = async ({
  evaluationRequestId,
  ...params
}: {
  statusId?: number;
  technicianId?: number | null;
  evaluationRequestId: number;
}) => {
  return (
    await axios.post(
      `/v1/evaluation-request/${evaluationRequestId}/validate`,
      params,
    )
  ).data;
};

export const scheduleEvaluationRequest = async ({
  evaluationRequestId,
}: {
  evaluationRequestId: number;
}) => {
  return (
    await axios.post(`/v1/evaluation-request/${evaluationRequestId}/schedule`)
  ).data;
};

export const completeEvaluationRequest = async ({
  evaluationRequestId,
}: {
  evaluationRequestId: number;
}) => {
  return (
    await axios.post(`/v1/evaluation-request/${evaluationRequestId}/complete`)
  ).data;
};

export const fetchEvaluationsRequestById = async ({
  id,
}: {
  id?: number | string;
}): Promise<EvaluationRequest> => {
  return (await axios.get(`/v1/evaluation-request/${id}`)).data;
};

export const fetchEvaluationRequestPositions = async ({
  evaluationRequestId,
  stepId,
  version,
}: {
  evaluationRequestId: number;
  stepId: number;
  version?: number;
}): Promise<PaginatedFetchedPositionItems> => {
  const url = version
    ? `/v1/evaluation-request/${evaluationRequestId}/${stepId}/positions?version=${version}`
    : `/v1/evaluation-request/${evaluationRequestId}/${stepId}/positions`;
  return (await axios.get(url)).data;
};

export const insertEvaluationRequestPositions = async ({
  evaluationRequestId,
  stepId,
  values,
}: {
  evaluationRequestId: number;
  stepId: number;
  values: CreatePositionsArgs;
}) => {
  return (
    await axios.post(
      `/v1/evaluation-request/${evaluationRequestId}/${stepId}/positions`,
      values,
    )
  ).data;
};
