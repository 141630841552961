import { useFormikContext } from 'formik';
import get from 'lodash.get';
import React, { useState } from 'react';
import SelectField from 'technical/form/formik/selectField';
import { RecursiveFormNames } from 'technical/form/formik/types';
import useDebounce from 'technical/hooks/useDebounce';
import { useClientSuppliersOptions } from '../hooks/queries';

export interface SearchableSelectClientSupplierProps<FormValues> {
  readOnly: boolean;
  valueKey: RecursiveFormNames<FormValues>;
}
export function SearchableSelectClientSupplier<FormValues extends object>({
  readOnly,
  valueKey,
}: SearchableSelectClientSupplierProps<FormValues>) {
  const { values } = useFormikContext<FormValues>();
  const [search, setSearch] = useState<string>();
  const debounced = useDebounce(search, 200);

  const params = search
    ? { name: debounced }
    : {
        id: get(values, valueKey),
      };

  const { data: clientSuppliers, isLoading } = useClientSuppliersOptions(
    params as any,
  );

  return (
    <SelectField<FormValues>
      valueKey={valueKey}
      optionsList={clientSuppliers}
      onSearch={(term) => setSearch(term)}
      onSelect={() => setSearch(undefined)}
      filterOption={false}
      readOnly={readOnly}
      allowClear
      loading={isLoading}
      data-test-id={valueKey}
    />
  );
}
