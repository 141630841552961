import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { createAdminHooks } from 'business/admin/services';
import { DataManagementConfig } from 'business/admin/types';
import { Evaluation, EvaluationFormValues } from 'business/evaluation/types';
import { evaluationKeys } from 'business/evaluation/hooks/queries';
import InputField from 'technical/form/formik/InputField';
import { getAPITablePosition } from 'technical/utils/getApiTablePosition';
import Input from 'antd/es/input';

const { TextArea } = Input;

const config: DataManagementConfig<Evaluation, EvaluationFormValues> = {
  entity: 'evaluation',
  hooks: createAdminHooks({
    keys: evaluationKeys,
    async fetch(page) {
      return (
        await axios.get('/v1/evaluation', {
          params: page ? getAPITablePosition(page) : {},
        })
      ).data;
    },
    async create(entity) {
      return axios.post(`/v1/evaluation`, {
        name: entity.name,
        description: entity.description,
        output: entity.output,
        identifier: entity.identifier,
        numberOfEvaluationCycle: entity.numberOfEvaluationCycle,
        duration: entity.duration,
      });
    },
    async update(entity) {
      return axios.patch(`/v1/evaluation/${entity.id}`, {
        name: entity.name,
        description: entity.description,
        output: entity.output,
        identifier: entity.identifier,
        numberOfEvaluationCycle: entity.numberOfEvaluationCycle,
        duration: entity.duration,
        ...(entity.disabled === 1 && { disabled: 1 }),
      });
    },
    async del(id) {
      return axios.delete(`/v1/evaluation/${id}`);
    },
  }),
  columns(permissions) {
    return [
      {
        dataIndex: 'identifier',
        key: 'identifier',
        width: 100,
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="identifier"
              readOnly={!permissions.update(entity)}
            />
          );
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="name"
              readOnly={!permissions.update(entity)}
              component={TextArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              showCount
              maxLength={500}
            />
          );
        },
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="description"
              readOnly={!permissions.update(entity)}
              component={TextArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              showCount
              maxLength={100}
            />
          );
        },
      },
      {
        dataIndex: 'numberOfEvaluationCycle',
        key: 'numberOfEvaluationCycle',
        width: 100,
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="numberOfEvaluationCycle"
              readOnly={!permissions.update(entity)}
              type="number"
            />
          );
        },
      },
      {
        dataIndex: 'duration',
        key: 'duration',
        width: 100,
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="duration"
              readOnly={!permissions.update(entity)}
              type="number"
            />
          );
        },
      },
      {
        dataIndex: 'output',
        key: 'output',
        width: 100,
        render: (_value, entity) => {
          return (
            <InputField<EvaluationFormValues>
              valueKey="output"
              readOnly={!permissions.update(entity)}
              type="number"
            />
          );
        },
      },
    ];
  },
  schema: yup.object({
    name: yup.string().trim().min(1).max(500).required(),
    description: yup.string().max(500).required(),
    output: yup.number().positive().integer().nullable(true),
    identifier: yup.string().min(1).max(250).nullable(true),
    numberOfEvaluationCycle: yup.number().positive().integer().nullable(true),
    duration: yup.number().positive().integer().nullable(true),
  }),
  defaultValues: {
    name: '',
    description: '',
    output: null,
    identifier: null,
    numberOfEvaluationCycle: null,
    duration: null,
  },
  permissions: {
    add: () => true,
    delete: () => false,
    disable: () => true,
  },
};

export default config;
