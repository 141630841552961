/**
 * Remove keys with undefined values from any nested object. Keep empty objects.
 * (from https://stackoverflow.com/a/38340374)
 */
export const removeUndefined = (obj: Record<any, unknown>) => {
  const newObj: Record<any, unknown> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) {
      newObj[key] = removeUndefined(obj[key] as Record<any, unknown>);
    } else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
