import React from 'react';
import { Formik } from 'formik';
import { Form as AntForm, Modal, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { EvaluationStepCommentFormValues } from 'business/evaluationRequest/types';
import { evaluationStepCommentSchema } from 'business/evaluationRequest/schema/evaluationRequestSchema';
import InputField from 'technical/form/formik/InputField';

const { TextArea } = Input;

interface EvaluationStepCommentModalProps {
  isOpen: boolean;
  isStayOnPage: boolean;
  onSubmit: (comment: string) => void;
  onCancel: () => void;
  value?: string;
}

const EvaluationStepCommentModal: React.FC<EvaluationStepCommentModalProps> = ({
  isOpen,
  isStayOnPage,
  onSubmit,
  onCancel,
  value,
}) => {
  const { t } = useTranslation();

  const initialValues: EvaluationStepCommentFormValues = {
    comment: value,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={evaluationStepCommentSchema}
      onSubmit={(values, { resetForm }) => {
        if (values.comment) {
          onSubmit(values.comment);
        }
        resetForm();
      }}
    >
      {({ submitForm, resetForm }) => {
        return (
          <Modal
            destroyOnClose
            title={t('evaluationRequest.update.step.modal.comment.title')}
            open={isOpen}
            onCancel={() => {
              onCancel();
              resetForm();
            }}
            footer={[
              <Button key="cancel" onClick={onCancel}>
                {t('common.cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                onClick={submitForm}
              >
                {isStayOnPage
                  ? t(
                      'evaluationRequest.update.step.modal.comment.submitAndStay',
                    )
                  : t(
                      'evaluationRequest.update.step.modal.comment.submitAndNext',
                    )}
              </Button>,
            ]}
          >
            <AntForm layout="vertical" onFinish={submitForm}>
              <AntForm.Item
                label={t('evaluationRequest.update.step.modal.comment.label')}
                required
              >
                <InputField<EvaluationStepCommentFormValues>
                  valueKey="comment"
                  component={TextArea}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  maxLength={500}
                  showCount
                />
              </AntForm.Item>
            </AntForm>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EvaluationStepCommentModal;
