import React from 'react';
import { Formik } from 'formik';
import { Form as AntForm, Modal, Button } from 'antd';
import DatePickerField from 'technical/form/formik/datePickerField';
import { useTranslation } from 'react-i18next';
import { ScheduleEvaluationRequestFormValues } from 'business/evaluationRequest/types';
import { scheduleEvaluationRequestSchema } from 'business/evaluationRequest/schema/evaluationRequestSchema';

interface ScheduleEvaluationRequestModalProps {
  isOpen: boolean;
  onSubmit: (estimatedEndDate: Date) => void;
  onCancel: () => void;
}

const ScheduleEvaluationRequestModal: React.FC<
  ScheduleEvaluationRequestModalProps
> = ({ isOpen, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const initialValues: ScheduleEvaluationRequestFormValues = {
    estimatedEndDate: undefined,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={scheduleEvaluationRequestSchema}
      onSubmit={(values, { resetForm }) => {
        if (values.estimatedEndDate) {
          onSubmit(values.estimatedEndDate);
        }
        resetForm();
      }}
    >
      {({ submitForm, resetForm }) => {
        return (
          <Modal
            destroyOnClose
            title={t('evaluationRequest.update.dates.scheduling')}
            open={isOpen}
            onCancel={() => {
              onCancel();
              resetForm();
            }}
            footer={[
              <Button key="cancel" onClick={onCancel}>
                {t('common.cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                onClick={submitForm}
              >
                {t('evaluationRequest.update.dates.submit')}
              </Button>,
            ]}
          >
            <AntForm layout="vertical" onFinish={submitForm}>
              <AntForm.Item
                label={t('evaluationRequest.update.dates.estimatedEndDate')}
                required
              >
                <DatePickerField<ScheduleEvaluationRequestFormValues> valueKey="estimatedEndDate" />
              </AntForm.Item>
            </AntForm>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ScheduleEvaluationRequestModal;
